import { ModuleIds, parameterCode, permissionType } from "constant"
import { getCodesByParameterId } from "helpers/app-backend"
import { checkAllowRole, getI18nextLng } from "helpers/utilities"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import ResultQCReportModal from "../../ResultIQC/Modal/Report"
import moment from "moment"
import CustomBootstrapTableAsync from "components/Common/CustomTable/CustomBootstrapTableAsync"

const RESOURCE = ModuleIds.ChartiQC
const TabResult = ({
    dicResultBySeq,
    t,
    isPosNeg,
    userPermissions
}) => {
    let lang = getI18nextLng()
    const [data, setData] = useState([]);
    const [checkLevel, setCheckLevel] = useState({})
    const [dataColorRule, setDataColorRule] = useState([])
    const [checkRoleKPH, setCheckRoleKPH] = useState(false)
    const [checkRoleHDKP, setCheckRoleHDKP] = useState(false)
    const [modalReport, setModalReport] = useState(false)
    const [dataRuleSelected, setDataRuleSelected] = useState({})

    useEffect(() => {
        loadColor()
    }, [])

    useEffect(() => {
        let checkKPH = checkAllowRole({
            permission: permissionType.CreateReport,
            userPermissions,
            permissions: [],
            resource: ModuleIds.ReportKPH,
            exclusivePermissions: []
        })
        setCheckRoleKPH(checkKPH)
        let checkHDKP = checkAllowRole({
            permission: permissionType.CreateReport,
            userPermissions,
            permissions: [],
            resource: ModuleIds.ReportHDKP,
            exclusivePermissions: []
        })
        setCheckRoleHDKP(checkHDKP)
    }, [userPermissions])

    useEffect(() => {
        setData(dicResultBySeq?.slice(0, -1) || [])
        setCheckLevel(dicResultBySeq.length > 0 ? dicResultBySeq[dicResultBySeq.length - 1] : {})
    }, [dicResultBySeq])

    const getColorRule = (key) => {
        let color = dataColorRule.find(x => x.value == key)
        return color?.message || '#000'
    }
    const loadColor = async () => {
        const query = { lang }
        let colorRules = await getCodesByParameterId(parameterCode.QC_RESULT_RULE_RESULT_COLOR, query)
        colorRules?.map(_item => {
            _item.value = _item.code
            _item.label = _item.message
            return _item
        })
        setDataColorRule(colorRules || [])
    }

    const renderRules = (rules, item) => {
        return <>
            {rules && rules.length > 0 &&
                <div style={{
                    display: 'flex',
                    gap: '2px',
                    flexWrap: 'wrap'
                }}>
                    {rules?.map((row, index) => <div key={index}>
                        <label
                            style={{
                                color: `${getColorRule(row.color)}`,
                                marginRight: "5px",
                                cursor: 'pointer',
                                whiteSpace: 'nowrap',
                                backgroundColor: "#fff",
                                padding: "1px 5px",
                                borderRadius: "5px",
                                border: "1px solid #808080",
                                width: 'auto'
                            }}
                            onClick={() => {
                                if (checkRoleKPH || checkRoleHDKP) {
                                    setDataRuleSelected({ ...row})
                                    setModalReport(true)
                                }
                            }}
                        >
                            {row.rule}
                        </label>
                    </div>)}
                </div>
            }
        </>
    }


    const columns = [
        {
            dataField: "idx",
            text: "#",
            formatter: (cellContent, item, index) => {
                return index + 1
            }
        },
        {
            dataField: "runTime",
            headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
            text: t("Date"),
            align: "center",
            style: { color: "#808080" },
        },
        {
            dataField: "res1",
            text: t("Level") + ' 1',
            headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
            align: "center",
            hidden: 1 in checkLevel ? false : true
        },
        {
            dataField: "err1",
            text: t("Violated Error"),
            hidden: (1 in checkLevel && !isPosNeg) ? false : true,
            align: "center",
            headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
            formatter: (cellContent, item, index) => {
                return renderRules(item.rules1 || '', item)
            },
        },
        {
            dataField: "comment1",
            text: t("Note"),
            align: "center",
            headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
            hidden: 1 in checkLevel ? false : true
        },
        {
            dataField: "res2",
            text: t("Level") + ' 2',
            headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
            align: "center",
            hidden: 2 in checkLevel ? false : true
        },
        {
            dataField: "err2",
            text: t("Violated Error"),
            headerStyle: { whiteSpace: 'nowrap', textAlign: 'center'  },
            hidden: (2 in checkLevel && !isPosNeg) ? false : true,
            align: "center",
            formatter: (cellContent, item, index) => {
                return renderRules(item.rules2 || '', item)
            },
        },
        {
            dataField: "comment2",
            text: t("Note"),
            headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
            align: "center",
            hidden: (2 in checkLevel) ? false : true
        },
        {
            dataField: "res3",
            text: t("Level") + ' 3',
            headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
            align: "center",
            hidden: 3 in checkLevel ? false : true

        },
        {
            dataField: "err3",
            text: t("Violated Error"),
            headerStyle: { whiteSpace: 'nowrap', textAlign: 'center'  },
            hidden: (3 in checkLevel && !isPosNeg) ? false : true,
            align: "center",
            formatter: (cellContent, item, index) => {
                return renderRules(item.rules3 || '', item)
            },
        },
        {
            dataField: "comment3",
            text: t("Note"),
            align: "center",
            headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
            hidden: 3 in checkLevel ? false : true
        },
        {
            dataField: "res4",
            text: t("Level") + ' 4',
            align: "center",
            headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
            hidden: 4 in checkLevel ? false : true

        },
        {
            dataField: "err4",
            text: t("Violated Error"),
            align: "center",
            headerStyle: { whiteSpace: 'nowrap', textAlign: 'center'   },
            hidden: (4 in checkLevel && !isPosNeg) ? false : true,
            formatter: (cellContent, item, index) => {
                return renderRules(item.rules4 || '', item)
            },
        },
        {
            dataField: "comment4",
            text: t("Note"),
            align: "center",
            headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
            hidden: 4 in checkLevel ? false : true
        },
        {
            dataField: "res5",
            text: t("Level") + ' 5',
            align: "center",
            headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
            hidden: 5 in checkLevel ? false : true
        },
        {
            dataField: "err5",
            text: t("Violated Error"),
            align: "center",
            headerStyle: { whiteSpace: 'nowrap', textAlign: 'center'   },
            hidden: (5 in checkLevel && !isPosNeg) ? false : true,
            formatter: (cellContent, item, index) => {
                return renderRules(item.rules5 || '', item)
            },
        },
        {
            dataField: "comment5",
            text: t("Note"),
            align: "center",
            headerStyle: { whiteSpace: 'nowrap', textAlign: 'center' },
            hidden: 5 in checkLevel ? false : true
        },
    ]

    return (
        <div className="chart-table-right table-patient-visit table-expand-custom ">
            <CustomBootstrapTableAsync
                columns={columns}
                data={data}
                keyField="id"
                isEnableRefresh={false}
                resource={RESOURCE}
                showSelectRow={false}
                isHover={false}
                isEnableExport={false}
                paging={{ dataSize: data?.length, size: data?.length }}
            />
            {modalReport &&
                <ResultQCReportModal
                    data={dataRuleSelected || {}}
                    modal={modalReport}
                    checkRoleKPH={checkRoleKPH}
                    checkRoleHDKP={checkRoleHDKP}
                    toggle={() => {
                        setModalReport(prev => !prev)
                        setDataRuleSelected({})
                    }}
                    onRefreshData={() => {
                        if (data && data.length > 0) {
                            let el = document.getElementById(`left-table-chart-id-${data[0].testCode}`)
                            el && el.click()
                        }
                    }}
                />
            }
        </div>
    )
}

TabResult.displayName = 'TabResult';
export default withTranslation(["chartiQCPage", "common", "message"], { withRef: true })(connect(
    null,
    null
)(TabResult))
