import { ConfirmModal, showErrToast, showToast, TitleAndTable, WarningModal } from "components/Common"
import { ModuleIds } from "constant"
import HeaderButtons from "./HeaderButtons"
import ImportExcelModal from "./Modals/ImportExcelModal"

import {
  convertDateFormat,
  insertUrlParam,
  onDelete,
  onDeleteToggle,
  selectCheckboxHandler,
} from "helpers/utilities"

import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { exportQCLot, ImportLots } from "helpers/app-backend"
import moment from "moment"
import {
  addNewLotQC,
  addNewLotTestQC,
  deleteLotQC,
  getListLotQC,
  getLotQCDetail,
  updateLotQC,
} from "store/actions"
import ConfigLotQCTable from "./ConfigLotQCTable"
import AddLotTestModal from "./Modals/AddLotTestModal"
import ConfigLotQCModal from "./Modals/ConfigLotQCModal"
import { showWanringToast } from "components/Common/ShowWarningToastr"

const RESOURCE = ModuleIds.LotQC
const size = 20

const ConfigLotQC = ({
  paging,
  onGetListLotQC,
  onGetQCLotDetail,
  onAddNewQCLot,
  onUpdateLotQC,
  onDeleteLotQC,
  loadinglotQC,
  updatedTime,
  lotQCs,
  lotQC,
  t,
  onAddNewLotTestQC,
}) => {
  const [confirmModal, setConfirmModal] = useState(false)
  const [modal, setModal] = useState(false)
  const [isEdit, setIsEdit] = useState(true)
  const [isClone, setIsClone] = useState(false)
  const [isSelected, setIsSelected] = useState(false)
  const [row, setRow] = useState({})
  const [rowDelete, setRowDelete] = useState({})
  const [rows, setRows] = useState([])
  const [warningModal, setWarningModal] = useState(false)
  const [addLotTestModal, setAddLotTestModal] = useState(false)
  const [importExcelModal, setImportExcelModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const formEl = useRef(null)
  const [model, setModel] = useState({
    search: "",
    page: 1,
    size: size,
    start: null,
    end: null
  })

  useEffect(() => {
    fetchLotQC()
  }, [])

  const onCloneHandler = param => {
    const id = param.id || row?.id
    if (param) setRow(param)
    if (id) {
      setIsEdit(false)
      setIsClone(true)
      toggle()
    } else {
      setWarningModal(true)
    }
  }

  const onGetLotQCList = payload => {
    insertUrlParam(payload)
    onGetListLotQC(payload)
  }

  const toggle = () => {
    setModal(prev => !prev)
    setIsLoading(false)
  }

  const handleValidSubmit = (e, values) => {
    values.expireDate = moment(values.expireDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    values.maxOpenDate = moment(values.maxOpenDate, "DD-MM-YYYY").format("YYYY-MM-DD");
    setIsLoading(true);
    if (isEdit && !isClone) {
      onUpdateLotQC({ lotQC: values, callback: afterUpdate })
      setIsLoading(false)
    } else {
      onAddNewQCLot({
        lotQC: values,
        callback: () => {
          onRefreshHandler()
          setIsLoading(false)
        },
      })
    }
  }

  const afterUpdate = () => {
    onRefreshHandler()
    toggle()
  }

  const resetState = () => {
    setRows([])
    setRow({})
  }

  const addNewLotQC = () => {
    setIsClone(false)
    setIsEdit(false)
    toggle()
  }

  const onEditHandler = (e, Id, param) => {
    const id = Id || row?.id
    if (id) {
      onGetQCLotDetail(id)
      setRow(param)
      setIsEdit(true)
      toggle()
    } else {
      setWarningModal(true)
    }
  }

  const onDeleteToggleHandler = (e, lotQC) => {
    onDeleteToggle({
      rows,
      row: lotQC || rowDelete,
      setConfirmModal,
      setWarningModal,
      setRowDelete,
    })
    if (confirmModal) {
      setRowDelete({})
    }
  }

  const onDeleteMultipleRows = rowsState => {
    onDeleteLotQC({ lotQC: rowsState, callback: onRefreshHandler })
  }

  const onDeleteSingleRow = rowsState => {
    onDeleteLotQC({
      lotQC: rowsState,
      callback: () => {
        setRows(prev => prev.filter(x => x.id !== rowDelete.id))
        onRefreshHandler()
      },
    })
    setRowDelete({})
    setRow({})
  }

  const onDeleteTestHandler = () => {
    onDelete({
      rowDelete,
      rows,
      onDeleteSingleRow,
      onDeleteMultipleRows,
    })
    setConfirmModal(false)
  }

  const onSelectCheckbox = (row, isSelected) => {
    const { rowsState, currentRow } = selectCheckboxHandler(
      rows,
      row,
      isSelected
    )
    setRows(rowsState)
    setRow(currentRow)
  }

  const onSelectAllCheckbox = rows => {
    setRows(rows)
    if (rows.length < 1) setRow({})
    else setRow(rows[rows.length - 1])
  }

  const fetchLotQC = () => {
    onGetListLotQC({
      ...model,
      sort: "createdDate:desc",
    })
  }

  const onRefreshHandler = () => {
    resetState()
    fetchLotQC()
  }

  const onSearch = searchText => {
    const val = { search: searchText || "" }
    setModel({ ...model, ...val })
    onGetLotQCList({ ...model, ...val, page: 1, size: size })
  }

  const onSizePerPageChange = size => {
    setModel({ ...model })
    onGetLotQCList({ ...model, page: 1, size: size })
  }

  const onPageChange = page => {
    setModel({ ...model })
    onGetLotQCList({ ...model, page, size: size })
  }

  const onSubmitFilter = values => {
    onGetLotQCList({ ...values, page: 1 })
  }

  const onSortHandler = (field, order) => {
    const sortString = `${field}:${order}`
    onGetLotQCList({ page: 1, sort: sortString })
  }

  const onSubmitAddLotTest = values => {
    if (
      (!values.qcInfo || values.qcInfo.length === 0) &&
      (!values.qcTextInfo || values.qcTextInfo.length === 0)
    ) {
      showErrToast(t("Please input data test, level"))
      return
    }
    onAddNewLotTestQC({
      data: values,
      callback: () => {
        setAddLotTestModal(false)
      },
    })
  }

  useEffect(() => {
    onRefreshHandler()
  }, [])

  const onExportQCLotHandler = async () => {
    const payload = { page: 1, sort: "createdDate:desc" }
    const fileData = await exportQCLot({
      ...payload,
      ...model,
      page: payload.page,
      size: payload.size,
      start:
        model.start ||
        convertDateFormat(lotQCs?.[0]?.minCreatedDate, "yyyy/MM/dd"),
      end: model.end || convertDateFormat(new Date(), "yyyy/MM/dd"),
    })
    const blob = new Blob([fileData])
    saveAs(blob, "ExportQCLot.xlsx")
  }

  const onImportExcelToggle = () => {
    setImportExcelModal(prev => !prev);
  };

  const onSaveImportedData = async data => {
    setIsLoading(true);
    try {
      const payload = {
        lots: data.map(item => ({
          lotId: item.LotId,
          lotName: item.LotName,
          testCategory: item.TestCategory,
          lotGroup: item.LotGroup,
          manufactor: item.Manufactor,
          regDate: item.RegDate ? moment(item.RegDate, "DD-MM-YYYY").utc().toISOString() : null,
          expireDate: item.ExpireDate ? moment(new Date(item.ExpireDate), "DD-MM-YYYY").format("YYYY-MM-DD") :  moment(new Date(), "DD-MM-YYYY").format("YYYY-MM-DD"),
          maxOpenDate: item.MaxOpenDate ? moment(new Date(item.MaxOpenDate), "DD-MM-YYYY").format("YYYY-MM-DD") :  moment(new Date(), "DD-MM-YYYY").format("YYYY-MM-DD"),
          levels: item.Levels,
          partyName: item.PartyName,
          companyId: item.CompanyId,
          noOfRun: item.NoOfRun,
          noOfPointPI: item.NoOfPointPI,
          noOfPointCal: item.NoOfPointCal,
          maxDayOpen: item.MaxDayOpen,
          instrumentId: typeof item.InstrumentId === "string"
            ? item.InstrumentId.split(",").map(id => Number(id.trim()))
            : Array.isArray(item.InstrumentId)
            ? item.InstrumentId.map(Number)
            : item.InstrumentId
            ? [Number(item.InstrumentId)]
            : [],
        })),
      };

      const response = await ImportLots(payload);

      if (response) {
        const { lotFail, lotSuccess } = response;

        if (lotSuccess && lotSuccess.length > 0) {
          showToast(`Import thành công: ${lotSuccess}`);
        }

        if (lotFail && lotFail.length > 0) {
          showWanringToast(`Các lô đã tồn tại: ${lotFail}`);
        }

        onRefreshHandler();
        setImportExcelModal(false);
      }
    } catch (error) {
      showWanringToast(t("Import failed"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <React.Fragment>
      {isLoading && (
        <div
          className="position-absolute h-100 w-100"
          style={{ zIndex: 10, backgroundColor: "rgba(255, 255, 255, 0.5)" }}
        >
          <div className="w-100 h-100">
            <div className="text-center" style={{ marginTop: 50 }}>
              <div className="spinner-border text-primary" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </div>
        </div>
      )}
      <TitleAndTable
        table={() => (
          <ConfigLotQCTable
            lotQCs={lotQCs}
            onSearch={onSearch}
            onSort={onSortHandler}
            onRefresh={onRefreshHandler}
            onPageChange={onPageChange}
            paging={{ ...paging, size: size }}
            onSizePerPageChange={onSizePerPageChange}
            onSubmitFilter={onSubmitFilter}
            isSelected={isSelected}
            onEdit={onEditHandler}
            onDelete={onDeleteToggleHandler}
            onClone={onCloneHandler}
            loading={loadinglotQC}
            updatedTime={updatedTime}
            onChangeModel={val => setModel({ ...model, ...val })}
            resource={RESOURCE}
            onExportQCLot={onExportQCLotHandler}
          />
        )}
        resource={RESOURCE}
        buttons={() => (
          <HeaderButtons
            resource={RESOURCE}
            onAdd={addNewLotQC}
            onDelete={onDeleteToggleHandler}
            isButtonEnabled={rows?.length === 1}
            onAddLotTest={() => setAddLotTestModal(true)}
            onImportExcel={onImportExcelToggle}
          />
        )}
        external
        onEdit={onEditHandler}
        onDelete={onDeleteToggleHandler}
        isDisableClone
        title={t("Cấu hình QC")}
        subtitle={t("Thông tin lô QC")}
      />
      <WarningModal
        modal={warningModal}
        onToggle={() => setWarningModal(prev => !prev)}
        message={t("message:SelectRowWarning")}
      />
      <ConfirmModal
        modal={confirmModal}
        title={`${t("common:Delete")} ${t("Lot")}`}
        message={t("message:DeleteConfirm")}
        onToggle={onDeleteToggleHandler}
        onDelete={onDeleteTestHandler}
      />
      <ConfigLotQCModal
        formEl={formEl}
        modal={modal}
        isEdit={isEdit}
        isClone={isClone}
        onValidSubmit={handleValidSubmit}
        toggle={toggle}
        data={!isEdit ? (isClone ? row : {}) : lotQC}
      />
      <AddLotTestModal
        modal={addLotTestModal}
        toggle={() => setAddLotTestModal(prev => !prev)}
        data={row || {}}
        onValidSubmit={onSubmitAddLotTest}
      />
      <ImportExcelModal
        modal={importExcelModal}
        toggle={onImportExcelToggle}
        onSave={onSaveImportedData}
      />
      <style>
        {`
          .table-expand-custom .inner-custom-pagination .d-inline {
            display: none !important;
          }
        `}
      </style>
    </React.Fragment>
  )
}

ConfigLotQC.propTypes = {
  lotQCs: PropTypes.array,
  lotQC: PropTypes.object,
  paging: PropTypes.object,
  loadinglotQC: PropTypes.bool,
  updatingResult: PropTypes.bool,
  updateResultTime: PropTypes.any,
  t: PropTypes.any,
  onAddNewQCLot: PropTypes.func,
  onGetListLotQC: PropTypes.func,
  onGetQCLotDetail: PropTypes.func,
  onUpdateLotQC: PropTypes.func,
  onDeleteLotQC: PropTypes.func,
}

const mapStateToProps = ({ lotQCs }) => ({
  lotQCs: lotQCs.lotQCs,
  lotQC: lotQCs.lotQC,
  paging: lotQCs.paging,
  updatinglotQC: lotQCs.updatinglotQC,
  loadinglotQC: lotQCs.loadinglotQC,
  updatedTime: lotQCs.updatedTime,
})

const mapDispatchToProps = dispatch => ({
  onAddNewQCLot: (lotQC, callback) => dispatch(addNewLotQC(lotQC, callback)),
  onGetListLotQC: (payload, callback) =>
    dispatch(getListLotQC(payload, callback)),
  onGetQCLotDetail: payload => dispatch(getLotQCDetail(payload)),
  onUpdateLotQC: payload => dispatch(updateLotQC(payload)),
  onDeleteLotQC: payload => dispatch(deleteLotQC(payload)),
  onAddNewLotTestQC: payload => dispatch(addNewLotTestQC(payload)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["managementQCPage", "common"])(ConfigLotQC)))
