import { AvForm } from "availity-reactstrap-validation"
import {
  Accordion,
  AccordionWrapper,
  CustomAutoComplete,
  CustomAvField,
  CustomButton,
  CustomDatePicker,
  CustomModal,
  CustomSelect,
  CustomSelectAsync,
} from "components/Common"
import { showWanringToast } from "components/Common/ShowWarningToastr"
import { parameterCode } from "constant/utility"
import { convertDateFormatVN, getInvalidMessageI18n } from "helpers/utilities"
import { isEmpty } from "lodash"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { Col, Label, ModalBody, ModalFooter, Row } from "reactstrap"

const ConfigLotQCModal = ({
  modal,
  toggle,
  isEdit,
  isClone,
  onValidSubmit,
  data,
  formEl,
  t,
}) => {
  const title = (isEdit ? t("common:Edit") : isClone ? t("common:Copy") : t("common:Add")) + " " + t("Lot");
  const [maxOpenDate, setMaxOpenDate] = useState(
    data?.maxOpenDate
      ? convertDateFormatVN(data.maxOpenDate, "DD-MM-YYYY")
      : convertDateFormatVN(new Date(), "DD-MM-YYYY")
  )
  const [expireDate, setExpireDate] = useState(
    data?.expireDate
      ? convertDateFormatVN(data.expireDate, "DD-MM-YYYY")
      : convertDateFormatVN(new Date(), "DD-MM-YYYY")
  )
  const [instrumentIdsArray, setInstrumentIdsArray] = useState([])
  const [lotGroup, setLotGroup] = useState({})
  const [dataTable, setDataTable] = useState([])
  const [isDisabled, setIsDisabled] = useState(false)
  
  const onLotGroupChange = value => {
    data.lotGroup = value.code
    data.lotGroupName = value.label
    setDataTable({ ...data })
    setLotGroup(value)
  }

  useEffect(() => {
    if (isClone && data?.lotGroup) {
      setLotGroup(data.lotGroup);
    }
  }, [isClone, data?.lotGroup]);

  useEffect(() => {
    if (!(isEdit || isClone) && modal) {
      setInstrumentIdsArray([]);
      setExpireDate(convertDateFormatVN(new Date(), "DD-MM-YYYY"));
      setMaxOpenDate(convertDateFormatVN(new Date(), "DD-MM-YYYY"));
      setIsDisabled(false);
      setLotGroup({});
      return;
    }
    setExpireDate(
      data?.expireDate
        ? convertDateFormatVN(new Date(data.expireDate), "DD-MM-YYYY")
        : convertDateFormatVN(new Date(), "DD-MM-YYYY")
    );
    setMaxOpenDate(
      data?.maxOpenDate
        ? convertDateFormatVN(new Date(data.maxOpenDate), "DD-MM-YYYY")
        : convertDateFormatVN(new Date(), "DD-MM-YYYY")
    );
    setLotGroup({});
    if (isClone && data?.instrumentIds) {
      setInstrumentIdsArray(
        data.instrumentIds.split(",").map(id => parseInt(id, 10))
      );
    }
  }, [isEdit, modal, isClone, data?.instrumentIds]);

  useEffect(() => {
    if (!((isEdit || isClone) && data?.instrumentIds)) {
      setInstrumentIdsArray([]);
      setExpireDate(convertDateFormatVN(new Date(), "DD-MM-YYYY"));
      setMaxOpenDate(convertDateFormatVN(new Date(), "DD-MM-YYYY"));
      return;
    }
  
    setInstrumentIdsArray(
      data.instrumentIds.split(",").map(id => parseInt(id, 10))
    );
    setExpireDate(
      data?.expireDate
        ? convertDateFormatVN(new Date(data.expireDate), "DD-MM-YYYY")
        : ""
    );
    setMaxOpenDate(
      data?.maxOpenDate
        ? convertDateFormatVN(new Date(data.maxOpenDate), "DD-MM-YYYY")
        : ""
    );
  }, [isEdit, data?.instrumentIds, isClone]);  

  const handleValidSubmit = (event, values) => {
    const updatedData = {
      ...values,
      maxOpenDate: convertDateFormatVN(maxOpenDate, "YYYY-MM-DD"),
      expireDate: convertDateFormatVN(expireDate, "YYYY-MM-DD"),
      instrumentIds: instrumentIdsArray.join(","),
    }
    onValidSubmit(event, updatedData)
  }

  // Parse date string to Date object
  const parseDate = dateStr => {
    const [day, month, year] = dateStr.split("-").map(Number)
    return new Date(year, month - 1, day)
  }

  // Handle expired date change
  const handleExpiredDateChange = date => {
    const selectedDate = convertDateFormatVN(date, "DD-MM-YYYY")
    const selectedDateObj = parseDate(selectedDate)
    const maxOpenDateObj = parseDate(maxOpenDate)

    if (selectedDateObj < maxOpenDateObj) {
      showWanringToast(t("Expiration Date must be greater than Open Date"))
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
    setExpireDate(selectedDate)
  }

  // Handle open date change
  const handleOpenDateChange = date => {
    const selectedDate = convertDateFormatVN(date, "DD-MM-YYYY")
    const selectedDateObj = parseDate(selectedDate)
    const expireDateObj = parseDate(expireDate)
    if (selectedDateObj > expireDateObj) {
      showWanringToast(t("Open Date must be smaller than Expiration Date"))
      setIsDisabled(true)
    } else {
      setIsDisabled(false)
    }
    setMaxOpenDate(selectedDate)
  }

  if (isEdit && isEmpty(data)) return null

  return (
    <CustomModal modal={modal} title={title} onToggle={toggle}>
      <ModalBody>
        <AvForm
          ref={formEl}
          id="testForm"
          onValidSubmit={handleValidSubmit}
          model={dataTable}
        >
          <AccordionWrapper defaultTab="1">
            <Accordion tabId={"1"} title={t("LOT Information")}>
              <CustomAvField name="id" type="hidden" value={data?.id || ""} />
              <Row className="px-2">
                <Col className="col-4">
                  <div className="mb-3">
                    <CustomAvField
                      name="lotId"
                      type="text"
                      required
                      errorMessage={getInvalidMessageI18n(t, "Lot ID")}
                      validate={{
                        required: { value: true },
                        maxLength: { value: 25 },
                        pattern: { value: '^[0-9a-zA-Z]+$', errorMessage: getInvalidMessageI18n(t, "Lot ID") },
                      }}
                      value={data?.lotId || ""}
                      disabled={isEdit}
                      label={t("Lot ID")}
                      detected={isEdit}
                    />
                  </div>
                </Col>

                <Col className="col-4">
                  <div className="mb-3">
                    <CustomAvField
                      name="lotName"
                      type="text"
                      required
                      disabled={isEdit}
                      errorMessage={getInvalidMessageI18n(t, "Lot Name")}
                      validate={{
                        required: { value: true },
                        maxLength: { value: 50 },
                      }}
                      value={data?.lotName || ""}
                      label={t("Lot Name")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col className="col-4">
                  <div className="mb-3">
                    <CustomDatePicker
                      name="expireDate"
                      label={t("Expiration Date")}
                      dateFormat={"d-m-Y"}
                      value={expireDate}
                      required
                      errorMessage={getInvalidMessageI18n(t, "Expiration Date")}
                      closeOnSelect={true}
                      onChangeHandler={e => handleExpiredDateChange(e.time[0])}
                      onBlur={value => handleExpiredDateChange(value[0])}
                      onClose={value => handleExpiredDateChange(value[0])}
                      detected={isEdit}
                    />
                  </div>
                </Col>

                <Col className="col-4">
                  <div className="mb-3">
                    <CustomSelect
                      required
                      portal
                      errorMessage={getInvalidMessageI18n(t, "Test Category")}
                      name="testCategory"
                      value={data.testCategory || ""}
                      code={parameterCode.TESTPROFILE_SUB_CATEGORY_CODE}
                      label={t("Test Category")}
                      detected={isEdit}
                    />
                  </div>
                </Col>

                <Col className="col-4">
                  <div className="mb-3">
                    <CustomAvField
                      name="manufactor"
                      type="text"
                      value={data?.manufactor || ""}
                      errorMessage={getInvalidMessageI18n(t, "Manufactor")}
                      validate={{
                        required: { value: true },
                        maxLength: { value: 50 },
                      }}
                      label={t("Manufactor")}
                      detected={isEdit}
                    />
                  </div>
                </Col>

                <Col className="col-4">
                  <div className="mb-3">
                    <Label>{t("Lot Group")}</Label>
                    <Col sm={12}>
                      {" "}
                      <CustomAutoComplete
                        name="lotGroup"
                        emptySelect
                        portal
                        value={lotGroup?.code || data?.lotGroup}
                        errorMessage={""}
                        isClearable={false}
                        code={parameterCode.LOT_GROUP_QC}
                        label={t("")}
                        onChange={(e, value, item) => {
                          if (item?.length > 0) {
                            onLotGroupChange({
                              code: item[0].value,
                              label: item[0].label,
                            })
                          }
                        }}
                        detected={isEdit}
                      />
                    </Col>
                  </div>
                </Col>

                <Col className="col-12">
                  <div className="mb-3">
                    <CustomSelect
                      label={t("Machines")}
                      name="instrumentId"
                      portal
                      isMulti
                      required
                      showID
                      errorMessage={getInvalidMessageI18n(t, "Machines")}
                      value={instrumentIdsArray}
                      code={parameterCode.INSTRUMENT_QC}
                      onChange={(name, value, label, selectedOptions) => {
                        const selectedIds = selectedOptions.map(
                          option => option.value
                        )
                        setInstrumentIdsArray([...selectedIds])
                      }}
                      // detected={isEdit}
                    />
                  </div>
                </Col>
                <Col className="col-4">
                  <div className="mb-3">
                    <CustomSelectAsync
                      label={t("Levels")}
                      name="levels"
                      value={data?.levels}
                      required
                      errorMessage={getInvalidMessageI18n(t, "Levels")}
                      code={parameterCode.LEVEL_QC}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col className="col-4">
                  <div className="mb-3">
                    <CustomAvField
                      name="noOfPointPI"
                      type="text"
                      errorMessage={getInvalidMessageI18n(
                        t,
                        "Number of PI Points"
                      )}
                      value={data?.noOfPointPI || ""}
                      onKeyDown={e => {
                        const { value } = e.target

                        if (
                          !/[0-9]/.test(e.key) &&
                          ![
                            "Backspace",
                            "ArrowLeft",
                            "ArrowRight",
                            "Delete",
                          ].includes(e.key)
                        ) {
                          e.preventDefault()
                        }

                        if (e.key === "." && value.includes(".")) {
                          e.preventDefault()
                        }

                        if (value.length === 0 && e.key === "0") {
                          e.preventDefault()
                        }
                      }}
                      onInput={e => {
                        if (
                          e.target.value.startsWith("0") &&
                          e.target.value !== "0." &&
                          e.target.value.length > 1
                        ) {
                          e.target.value = e.target.value.replace(/^0+/, "")
                        }
                      }}
                      label={t("Number of PI Points")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col className="col-4">
                  <div className="mb-3">
                    <CustomAvField
                      name="noOfRun"
                      type="text"
                      errorMessage={getInvalidMessageI18n(t, "Number of Runs")}
                      value={data?.noOfRun || ""}
                      onKeyDown={e => {
                        const { value } = e.target

                        if (
                          !/[0-9]/.test(e.key) &&
                          ![
                            "Backspace",
                            "ArrowLeft",
                            "ArrowRight",
                            "Delete",
                          ].includes(e.key)
                        ) {
                          e.preventDefault()
                        }

                        if (e.key === "." && value.includes(".")) {
                          e.preventDefault()
                        }

                        if (value.length === 0 && e.key === "0") {
                          e.preventDefault()
                        }
                      }}
                      onInput={e => {
                        if (
                          e.target.value.startsWith("0") &&
                          e.target.value !== "0." &&
                          e.target.value.length > 1
                        ) {
                          e.target.value = e.target.value.replace(/^0+/, "")
                        }
                      }}
                      label={t("Number of Runs")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col className="col-4">
                  <div className="mb-3">
                    <CustomAvField
                      name="noOfPointCal"
                      type="text"
                      errorMessage={getInvalidMessageI18n(
                        t,
                        "Number of Cal Points"
                      )}
                      value={data?.noOfPointCal || ""}
                      onKeyDown={e => {
                        const { value } = e.target

                        if (
                          !/[0-9]/.test(e.key) &&
                          ![
                            "Backspace",
                            "ArrowLeft",
                            "ArrowRight",
                            "Delete",
                          ].includes(e.key)
                        ) {
                          e.preventDefault()
                        }

                        if (e.key === "." && value.includes(".")) {
                          e.preventDefault()
                        }

                        if (value.length === 0 && e.key === "0") {
                          e.preventDefault()
                        }
                      }}
                      onInput={e => {
                        if (
                          e.target.value.startsWith("0") &&
                          e.target.value !== "0." &&
                          e.target.value.length > 1
                        ) {
                          e.target.value = e.target.value.replace(/^0+/, "")
                        }
                      }}
                      label={t("Number of Calibration Points")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col className="col-4">
                  <div className="mb-3">
                    <CustomAvField
                      name="maxDayOpen"
                      type="text"
                      errorMessage={getInvalidMessageI18n(t, "Max Day Open")}
                      value={data?.maxDayOpen || ""}
                      onKeyDown={e => {
                        const { value } = e.target

                        if (
                          !/[0-9]/.test(e.key) &&
                          ![
                            "Backspace",
                            "ArrowLeft",
                            "ArrowRight",
                            "Delete",
                          ].includes(e.key)
                        ) {
                          e.preventDefault()
                        }

                        if (e.key === "." && value.includes(".")) {
                          e.preventDefault()
                        }

                        if (value.length === 0 && e.key === "0") {
                          e.preventDefault()
                        }
                      }}
                      onInput={e => {
                        if (
                          e.target.value.startsWith("0") &&
                          e.target.value !== "0." &&
                          e.target.value.length > 1
                        ) {
                          e.target.value = e.target.value.replace(/^0+/, "")
                        }
                      }}
                      label={t("Max Days Open")}
                      detected={isEdit}
                    />
                  </div>
                </Col>
                <Col className="col-4">
                  <Row className="d-flex">
                    <div className="mb-3">
                      <CustomDatePicker
                        name="maxOpenDate"
                        label={t("Open Date")}
                        dateFormat={"d-m-Y"}
                        value={maxOpenDate}
                        errorMessage={getInvalidMessageI18n(t, "Open Date")}
                        closeOnSelect={true}
                        onChangeHandler={e => handleOpenDateChange(e.time[0])}
                        onBlur={value => handleOpenDateChange(value[0])}
                        onClose={value => handleOpenDateChange(value[0])}
                        detected={isEdit}
                      />
                    </div>
                  </Row>
                </Col>
              </Row>
            </Accordion>
          </AccordionWrapper>
        </AvForm>
      </ModalBody>
      <ModalFooter>
        <CustomButton
          text={t("common:Cancel")}
          type="button"
          onClick={toggle}
          data-dismiss="modal"
          className="button-width"
        />
        <CustomButton
          text={t("common:Save")}
          type="submit"
          color="primary"
          onClick={() => formEl?.current?.submit()}
          className="save-user button-width"
          isEdit
          disabled={isDisabled}
          isModal
        />
      </ModalFooter>
    </CustomModal>
  )
}

export default withTranslation(["managementQCPage", "common", "message"])(
  ConfigLotQCModal
)