import { CustomButton, CustomNav, CustomNavLink } from "components/Common"
import { convertDateFormat_VN, convertDayMonthYear,convertToNumber } from "helpers/utilities"
import { ModuleIds } from "constant"
import { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"
import { Card, CardBody, TabContent, TabPane } from "reactstrap"
import TabResult from "./TabResult"
import TabOptional from "./TabOptional"
import PropTypes from "prop-types"
import { cloneDeep } from "lodash"

const RESOURCE = ModuleIds.ChartiQC

const BodyRight = ({
  t,
  filter,
  listData,
  level,
  isPosNeg,
  onChangeDataInfo,
  userPermissions,
}) => {
  const [currentTab, setCurrentTab] = useState("1")
  const [dicResultBySeq, setDicResultBySeq] = useState([])
  useEffect(() => {
    let arrline = []
    let mapDayRunSeq = new Map()
    let checkLevel = {}
    let commentCollector = {};
    if (level != "default") {
      listData = listData.filter(item => item?.levels == level)
    }

    if (listData.length > 0) {
      let dicByDate = {}
      for (let i = 0; i < listData.length; i++) {
        let data = listData[i]
        let dateKey = convertDayMonthYear(data?.runTime)
        let lotId = data?.lotId
        let runSeq = data?.runSeqDisplay
        let lv = data?.levels
        let result = convertToNumber(data?.result) ? convertToNumber(data?.result) : data?.result
        let resultText = data?.resultText
        let error = data?.singleRules
        let tempRules = data?.rules
        let rules = []
        let comment = data?.comment
        console.log("data",data)
        if (tempRules != null && tempRules != undefined && tempRules != []) {
          for (let i in tempRules) {
            rules.push(cloneDeep(data))
            rules[i]["rule"] = tempRules[i]["ruleName"]
            rules[i]["color"] = tempRules[i]["color"]
            rules[i]["machineName"] = data?.insName
            rules[i]["lotCode"] = lotId
          }
        }

        if (!(dateKey in dicByDate)) {
          dicByDate[dateKey] = {}
        }
        if (!(lotId in dicByDate[dateKey])) {
          dicByDate[dateKey][lotId] = {}
        }
        if (!(runSeq in dicByDate[dateKey][lotId])) {
          dicByDate[dateKey][lotId][runSeq] = {}
        }
        if (!(lv in dicByDate[dateKey][lotId][runSeq])) {
          let resultFinal = ""
          let resultCondition = (result != null && result != undefined && result != "")
          let resultTextCondition = (resultText != null && resultText != undefined && resultText != "")
          if(resultCondition && resultTextCondition){
            resultFinal = result+'/'+resultText
          }
          else if(!resultCondition && resultTextCondition){
            resultFinal = resultText
          }
          else if(resultCondition && !resultTextCondition){
            resultFinal = result
          }
          dicByDate[dateKey][lotId][runSeq][lv] = [
            resultFinal,
            error,
            rules,
            comment,
          ]
        }
      }

      for (let dateKey in dicByDate) {
        let dateData = dicByDate?.[dateKey]
        for (let lotId in dateData) {
          let lotData = dateData?.[lotId]
          for (let runSeq in lotData) {
            let dicKey = dateKey + lotId + runSeq
            let seqData = lotData?.[runSeq]
            if (!mapDayRunSeq.has(dicKey)) {
              mapDayRunSeq.set(dicKey, [
                { res: "", err: "", rules: [], comment: "" },
                { res: "", err: "", rules: [], comment: "" },
                { res: "", err: "", rules: [], comment: "" },
                { res: "", err: "", rules: [], comment: "" },
                { res: "", err: "", rules: [], comment: "" },
                { res: "", err: "", rules: [], comment: "" },
                "",
              ])
            }

            mapDayRunSeq.get(dicKey)[5] = dateKey
            for (let lv in seqData) {
              checkLevel[lv] = true;
              let item = seqData[lv];
              const currentComment = item[3] || "";

              // Gom comment vào commentCollector
              if (commentCollector[lv]) {
                commentCollector[lv] += ` | ${currentComment}`;
              } else {
                commentCollector[lv] = currentComment;
              }

              mapDayRunSeq.get(dicKey)[lv - 1].res = item[0];
              mapDayRunSeq.get(dicKey)[lv - 1].err = item[1] ?? "";
              mapDayRunSeq.get(dicKey)[lv - 1].rules = item[2] ?? "";
              mapDayRunSeq.get(dicKey)[lv - 1].comment = currentComment;
            }
          }
        }
      }
    }

    const combinedComment = Object.keys(commentCollector)
      .sort((a, b) => a - b)
      .map(lv => commentCollector[lv])
      .filter(c => c)
      .join(", ");
    checkLevel.comment = combinedComment;

    mapDayRunSeq.forEach(line => {
      let check = false
      const obj = {
        runTime: line[5],
      }

      if (line.length > 0) {
        obj.id = line[0]?.id
        obj.testCode = line[0]?.testCode
      }
      for (let i = 1; i <= 5; i++) {
        if (
          checkLevel.hasOwnProperty(i) &&
          (level == "default" || level == i)
        ) {
          check = true
          obj["res" + i] = line[i - 1]?.res
          obj["err" + i] = line[i - 1]?.err
          obj["rules" + i] = line[i - 1]?.rules
          obj["comment" + i] = line[i - 1]?.comment
        }
      }
      if (check == true) {
        arrline.push(obj)
      }
    })

    if (onChangeDataInfo) {
      onChangeDataInfo(arrline)
    }

    arrline.push(checkLevel)
    setDicResultBySeq(arrline)
  }, [listData, filter, level])

  return (
    <Card className="m-0">
      <CardBody className="p-0">
        <CustomNav
          onToggle={e => {
            setCurrentTab(e)
          }}
          defaultTab={"1"}
          tabs
          className="nav-tabs-custom p-0"
          tabContents={customActiveTab => (
            <TabContent activeTab={customActiveTab} className="text-muted p-0">
              <TabPane tabId="1">
                {currentTab == "1" && (
                  <TabResult
                    dicResultBySeq={dicResultBySeq}
                    isPosNeg={isPosNeg}
                    userPermissions={userPermissions}
                  />
                )}
              </TabPane>
              <TabPane tabId="2">
                {currentTab == "2" && <TabOptional />}
              </TabPane>
            </TabContent>
          )}
        >
          <CustomNavLink tabId="1">
            <span>{t("Result")}</span>
          </CustomNavLink>
          <CustomNavLink tabId="2">
            <span>{t("Optional")}</span>
          </CustomNavLink>
        </CustomNav>
      </CardBody>
    </Card>
  )
}

BodyRight.propTypes = {
  t: PropTypes.any,
  listData: PropTypes.array,
}
const mapStateToProps = ({ Authorization }) => ({
  userPermissions: Authorization.permissions,
})
const mapDispatchToProps = dispatch => ({})
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["chartiQCPage", "message", "common"])(BodyRight)))
