import React from "react"
import ReactECharts from "echarts-for-react"
import "echarts/theme/roma"
import {
  convertDateTimeFormat_VN,
  convertDayMonth,
  GetDataUrlReportConfig,
  convertToNumber
} from "helpers/utilities"
import { filter, get, isEmpty } from "lodash"
import PropTypes from "prop-types"
import { useEffect, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect, useSelector } from "react-redux"
import { withRouter } from "react-router-dom"
import { cloneDeep } from "lodash"
import { ModuleIds, permissionType, ReportResource } from "constant"
import {
  getFilePrintResult,
  pingToPrintService,
  sendDataPrint,
} from "helpers/app-backend"
import ExcelJS from "exceljs"
import ChartModal from "./ChartModal"
import { Check } from "components/Common"
import { Row } from "reactstrap"
import note_chart from "../../../../assets/images/note_chart.png"
import {
  createObjFixLine,
  updateChartTitle,
  getIcon,
} from "./ChartCalculation"
// import detailsChart from 'src\assets\images\details_chart.png'

const RESOURCE = ModuleIds.ChartiQC
const lengthRotate = 17
const lengthAutoscale = 40
const degRotateDefault = 0
const marginRotateDefault = 15
const degRotate = 60
const degRotateSacle = -90
const marginRotate = 7
const pointInGridDefault = -4.75
const pointInGridBDL = -0.6
const TabChart = ({
  showBothCharts,
  showUnvalidatedPoint,
  levelsChartOption,
  timeOption,
  levels,
  dateArr,
  dataInfo,
  isPosNeg,
  generalData,
  onChangeFilterChart,
  reportIndex,
  flg,
  selectedLots,
  t,
}) => {
  const [dataset, setDataset] = useState({})
  const [oneLevelDataset, setOneLeveDataset] = useState({})
  const [optionsNSX, setOptionsNSX] = useState({})
  const [options1, setOptions1] = useState({})
  const [options2, setOptions2] = useState({})
  const [options3, setOptions3] = useState({})
  const [options4, setOptions4] = useState({})
  const [options5, setOptions5] = useState({})
  const [optionsNSX1, setOptionsNSX1] = useState({})
  const [optionsNSX2, setOptionsNSX2] = useState({})
  const [optionsNSX3, setOptionsNSX3] = useState({})
  const [optionsNSX4, setOptionsNSX4] = useState({})
  const [optionsNSX5, setOptionsNSX5] = useState({})
  const [dataReport, setDataReport] = useState({})
  const item = localStorage.getItem("printResultConfig")
  const [printResultConfig, setPrintResultConfig] = useState({})
  const [modalChart, setModalChart] = useState(false)
  const [chartRef, setChartRef] = useState()
  const userInfo = localStorage.getItem("userInfo")
  const { family_name, given_name, name, sub } = JSON.parse(userInfo)
  const formEl = useRef(null) // Tham chiếu tới biểu đồ
  const [dataCheckRef, setDataCheckRef] = useState([])
  const [allLotExpireDate, setAllLotExpireDate] = useState()
  const [dataPointsChart, setDataPointsChart] = useState()
  const [allLotData, setAllLotData] = useState()

  const toggleChart = chartRef => {
    let checkRef = []
    const pxnRefs = [ref, ref1, ref2, ref3, ref4, ref5]
    const nsxRefs = [refNSX, refNSX1, refNSX2, refNSX3, refNSX4, refNSX5]

    pxnRefs.forEach(r => {
      if (r?.current) checkRef.push("PXN")
    })
    nsxRefs.forEach(r => {
      if (r?.current) checkRef.push("NSX")
    })

    setDataCheckRef(checkRef)
    setChartRef(chartRef)
    setModalChart(prev => !prev)
  }

  useEffect(() => {
    let lotDataArr = reportIndex?.allLot || [""]
    setAllLotData(lotDataArr.join(", "))
  }, [reportIndex])

  useEffect(() => {
    setDataPointsChart(filterDataPoint(reportIndex?.allDataPoint || {})?.length)
  }, [reportIndex, levels])

  useEffect(() => {
    let lotDataArr = reportIndex?.allLot || [""]
    let objLot = generalData?.allExpireMachineLot || {}
    let sortedExpires = lotDataArr.map(key => objLot[key]?.expire)
    setAllLotExpireDate(sortedExpires.join(", "))
  }, [generalData])

  const captureChartImage = (comment, chartType) => {
    let selectedRefs
    if (chartType === "PXN") {
      selectedRefs = [ref, ref1, ref2, ref3, ref4, ref5]
    } else {
      selectedRefs = [refNSX, refNSX1, refNSX2, refNSX3, refNSX4, refNSX5]
    }
    const validImages = selectedRefs
      .filter(r => r?.current)
      .map(r => r.current.getEchartsInstance().getDataURL())
    if (validImages.length > 0) {
      exportToExcel(validImages, comment)
    }
    setModalChart(prev => !prev)
  }

  const filterDataPoint = data => {
    let results = []

    if (![1, 2, 3, 4, 5].includes(levels)) {
      Object.values(data).forEach(arr => {
        arr.forEach(item => {
          if (item && typeof item === "object") {
            results.push(item)
          }
        })
      })
    } else {
      Object.values(data).forEach(arr => {
        arr.forEach(item => {
          if (item && item?.levels === levels) {
            results.push(item)
          }
        })
      })
    }
    return results
  }


  const exportToExcel = (chartImages, comment) => {

    const lowHighData = Object.keys(dataset)
      .filter(key => !isNaN(key))
      .map(key => {
        const firstInnerKey = Object.keys(dataset[key]).find(k => k !== 'data');
        if (firstInnerKey !== null && firstInnerKey !== undefined) {
          const { low, high } = dataset[key][firstInnerKey];
          return `${low}-${high}`;
        }
      })
      .join(', ');
    const evalData = Object.keys(dataset)
      .filter(key => !isNaN(key))
      .map(key => {
        const firstInnerKey = Object.keys(dataset[key]).find(k => k !== 'data');
        if (firstInnerKey !== null && firstInnerKey !== undefined) {
          const evalStr = dataset[key][firstInnerKey]?.eval;
          return evalStr;
        }
      })
      .filter(value => value)
      .join(', ');

    const meanStr = Object.values(generalData["index"]).map(item => `${item.mean}/${item.meanPI}`).join(', ');
    const sdStr = Object.values(generalData["index"]).map(item => `${item.sd}/${item.sdpi}`).join(', ');

    if (dataInfo && dataInfo.length > 1) {
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet("Sheet 1")

      let titleRowIndex = 1
      let titleStartCol = 1
      let titleEndCol = 16
      worksheet.mergeCells(
        titleRowIndex,
        titleStartCol,
        titleRowIndex,
        titleEndCol
      )
      let titleCell = worksheet.getRow(titleRowIndex).getCell(titleStartCol)
      titleCell.value = t("BIỂU ĐỒ LEVEY JENNINGS")
      titleCell.alignment = { vertical: "middle", horizontal: "center" }
      titleCell.font = { size: 11, bold: true }
      let dataChartInfo = cloneDeep(dataInfo)
      let objValidCol = dataChartInfo.pop()

      let columns = [t("Date")]
      for (let key in objValidCol) {
        if (key === "comment") {
          columns.push(t("chartiQCPage:Comment"))
        } else {
          columns.push(t("Level") + " " + key)
        }
      }

      const row3 = worksheet.getRow(3)
      row3.eachCell(cell => {
        cell.alignment = {
          vertical: "middle",
          horizontal: "left",
          wrapText: true,
        }
      })

      columns.push(null, null)
      worksheet.mergeCells(2, 7, 2, 12)
      worksheet.getRow(2).height = 40

      let testInfoCell = worksheet.getRow(2).getCell(7)
      testInfoCell.value = `${t("Test")}: ${reportIndex?.test} \n${t(
        "From"
      )}: ${reportIndex?.start} - ${t("To")}: ${reportIndex?.end}`

      testInfoCell.alignment = {
        vertical: "middle",
        horizontal: "center",
        wrapText: true,
      }
      worksheet.addRow(columns)
      let cnt = 0
      let machineRep = `${t("Machine")}: \n${generalData?.["expireAndMachine"]?.["insName"]
        }`
      let lotRep = `${t("Lot")}: \n${allLotData}`
      let expireRep = `${t("Expiration Date")}: \n${allLotExpireDate}`
      let total = t("Number of points") + ": " + dataPointsChart;
      let meanRange = Object.keys(generalData["index"]).length !== 0 ? `Mean ${t("Lab")}/ ${t("Manu")}: \n${meanStr}` : `Mean ${t("Lab")}/ ${t("Manu")}: \n`;
      let sdRange = Object.keys(generalData["index"]).length !== 0 ? `SD ${t("Lab")}/ ${t("Manu")}: \n${sdStr}` : `SD ${t("Lab")}/ ${t("Manu")}: \n`;
      let lowHigh = `${t("Low")}/ ${t("High")}: \n${lowHighData}`
      let evalRange = `${t("Eval Index")}: \n${evalData}`

      const filteredData = dataChartInfo.map(({ runTime, res1, res2, res3, res4, res5, comment1, comment2, comment3, comment4, comment5 }) => ({
        runTime,
        res1,
        res2,
        res3,
        res4,
        res5,
        comment1,
        comment2,
        comment3,
        comment4,
        comment5
      }));

      const result = filteredData.map(item => {
        const comment = Object.keys(item)
          .filter(key => key.startsWith("comment"))
          .map(key => item[key])
          .filter(value => value && value.trim() !== "")
          .join(" ");
        const newObj = {
          runTime: item.runTime,
        };
        for (const key in objValidCol) {
          if (key !== "comment") {
            const resKey = `res${key}`;
            if (item[resKey] !== undefined) {
              newObj[resKey] = item[resKey];
            }
          }
        }
        newObj.comment = comment;
        return newObj;
      });

      for (let col = 8; col <= 13; col++) {
        worksheet.getColumn(col).width = 22
      }
      let commentColumnIndex = worksheet.columns.findIndex(col => col.header === t("chartiQCPage:Comment")) + 1;
      // for (let col = 8; col <= 13; col++) {
      //   let column = worksheet.getColumn(col);
      //   column.width = 22;
      //   if (col === commentColumnIndex) { // commentColumnIndex là chỉ số cột ghi chú
      //     column.alignment = {
      //       vertical: "middle",
      //       horizontal: "left",
      //       wrapText: false,  // Không xuống dòng
      //       shrinkToFit: true // Thu nhỏ chữ để vừa ô
      //     };
      //   }
      // }

      // worksheet.getColumn(commentColumnIndex).alignment = {
      //   horizontal: "left",
      //   vertical: "middle",
      //   wrapText: false  // Không xuống dòng
      // };

      // worksheet.getColumn(commentColumnIndex).protection = {
      //   locked: true,
      //   hidden: true   // Ẩn phần tràn nội dung
      // };


      result.forEach(item => {
        let tempRow = []
        Object.keys(item).forEach(key => {
          tempRow.push(item[key])
        })

        if (cnt === 0) {
          let dataFillLowOrMean = isPosNeg ? lowHigh : meanRange
          let dataFillEvalOrSD = isPosNeg ? evalRange : sdRange
          let dataToFill = [machineRep, lotRep, expireRep, total, dataFillLowOrMean, dataFillEvalOrSD];
          let startCol = 8;
          let rowIndex = 3;

          dataToFill.forEach((data, index) => {
            let cell = worksheet.getRow(rowIndex).getCell(startCol + index)
            cell.value = data
            cell.alignment = {
              vertical: "middle",
              horizontal: "left",
              wrapText: true,
            }
          })
        }

        worksheet.addRow(tempRow)
        cnt += 1
      })

      const noteImgId = workbook.addImage({
        base64: note_chart,
        extension: "png",
      })

      reportIndex?.isPosNeg === false && worksheet.addImage(noteImgId, {
        tl: { col: 10, row: 4 },
        ext: { width: 180, height: 30 },
      })

      let imageColumnStart = 7
      let imageRowStart = 6
      let imageHeight = 400
      let rowHeight = 20
      let rowsNeededPerImage = Math.ceil(imageHeight / rowHeight)
      let lastRow = imageRowStart

      chartImages.forEach(chartImageBase64 => {
        const imageId = workbook.addImage({
          base64: chartImageBase64,
          extension: "png",
        })

        worksheet.addImage(imageId, {
          tl: { col: imageColumnStart, row: lastRow },
          ext: { width: 700, height: imageHeight },
        })

        lastRow += rowsNeededPerImage
      })

      while (worksheet.rowCount < lastRow) {
        worksheet.addRow([])
      }

      let commentRowIndex = lastRow + 1
      let commentStartCol = imageColumnStart + 1
      let commentEndCol = imageColumnStart + 15

      worksheet.mergeCells(
        commentRowIndex,
        commentStartCol,
        commentRowIndex,
        commentEndCol
      )
      let commentCell = worksheet
        .getRow(commentRowIndex)
        .getCell(commentStartCol)
      commentCell.value = "Đánh giá" + ": " + comment
      commentCell.alignment = {
        vertical: "middle",
        horizontal: "left",
        wrapText: true,
      }

      // Thêm "Người duyệt"
      let reviewerTitleRowIndex = commentRowIndex + 1
      let reviewerTitleStartCol = imageColumnStart + 3
      let reviewerTitleEndCol = imageColumnStart + 5

      worksheet.mergeCells(
        reviewerTitleRowIndex,
        reviewerTitleStartCol,
        reviewerTitleRowIndex,
        reviewerTitleEndCol
      )
      let reviewerTitleCell = worksheet
        .getRow(reviewerTitleRowIndex)
        .getCell(reviewerTitleStartCol)
      reviewerTitleCell.value = "Người duyệt"
      reviewerTitleCell.alignment = { vertical: "middle", horizontal: "center" }

      // Thêm tên bác sĩ dưới "Người duyệt"
      let reviewerNameRowIndex = reviewerTitleRowIndex + 1
      let reviewerNameStartCol = imageColumnStart + 3
      let reviewerNameEndCol = imageColumnStart + 5

      worksheet.mergeCells(
        reviewerNameRowIndex,
        reviewerNameStartCol,
        reviewerNameRowIndex,
        reviewerNameEndCol
      )
      let reviewerNameCell = worksheet
        .getRow(reviewerNameRowIndex)
        .getCell(reviewerNameStartCol)
      reviewerNameCell.value = family_name
      reviewerNameCell.alignment = { vertical: "middle", horizontal: "center" }

      workbook.xlsx.writeBuffer().then(buffer => {
        const file = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
        saveAs(file, "Biểu_đồ_iQC.xlsx")
      })
    }
  }

  const getPrintResultConfig = () => {
    if (item) {
      try {
        setPrintResultConfig(JSON.parse(item))
      } catch (error) { }
    }
  }
  const createFixElementObj = (value, type, flg, name, namePoint, lineVal) => {
    let obj = {
      name: name,
      type: "line",
      value: value,
      symbol: "none",
      symbolSize: 0,
      lineStyle: { color: "black", type: type, width: 1 },
    }
    obj["markPoint"] = {
      symbol: "rect",
      symbolSize: 1,
      data: [
        {
          name: namePoint,
          type: "max",
          label: {
            show: true,
            position: "left",
            formatter: () => {
              return flg ? `${namePoint} ${lineVal}` : `${namePoint}`
            },
            fontSize: 14,
            color: "black",
          },
        },
      ],
    }
    return obj
  }

  const createObjFixLineBDL = (grayRangeArr, flg, range) => {
    let objFixLine = {
      clone: {
        name: ``,
        type: "line",
        value: -0.5,
        symbol: "none",
        symbolSize: 0,
        lineStyle: { color: "black", width: 1 },
      },
    }
    let low = 0,
      high = 1
    let med = (low + high) / 2
    let IdxArr = []
    if (range != null) {
      for (let i = 1; i < range.length; i += 2) {
        IdxArr.push(range[i])
      }
      for (let i = 0; i < grayRangeArr.length; i++) {
        low += grayRangeArr[i]
        high += grayRangeArr[i]
        med = (low + high) / 2
        let lowI = IdxArr[i][0]
        let highI = IdxArr[i][1]
        let medVal = (lowI + highI) / 2
        let level = (levels == 'default' || levelsChartOption == 'multi') ? (i + 1) : levels
        if (i == 0) {
          objFixLine["low" + i] = createFixElementObj(
            low,
            null,
            flg,
            t("LowI") + i,
            t("LowI") + level,
            lowI
          )
          objFixLine["high" + i] = createFixElementObj(
            high,
            null,
            flg,
            t("HighI") + i,
            t("HighI") + level,
            highI
          )
          objFixLine["med" + i] = createFixElementObj(
            med,
            "dotted",
            false,
            "med" + i,
            "",
            medVal
          )
        } else {
          if (low != objFixLine["high" + (i - 1)].value) {
            objFixLine["low" + i] = createFixElementObj(
              low,
              null,
              flg,
              t("LowI") + i,
              t("LowI") + level,
              lowI
            )
          }
          objFixLine["high" + i] = createFixElementObj(
            high,
            null,
            flg,
            t("HighI") + i,
            t("HighI") + level,
            highI
          )
          objFixLine["med" + i] = createFixElementObj(
            med,
            "dotted",
            false,
            "med" + i,
            "",
            medVal
          )
        }
        low += 1
        high += 1
      }
    }
    return objFixLine
  }

  useEffect(() => {
    getPrintResultConfig()
    window.addEventListener("storage", getPrintResultConfig)
    return () => {
      window.removeEventListener("storage", getPrintResultConfig)
    }
  }, [item])

  const { reportInfos } = useSelector(state => ({
    reportInfos: state.Authorization.reportinfo || [],
  }))

  useEffect(() => {
    setDataReport([reportInfos[0]])
  }, [reportInfos])
  const [options, setOptions] = useState({
    title: {
      text: "",
      subtext: "",
      left: "center",
    },
    legend: {
      data: [],
      top: "35",
      // left: "9%",
      textStyle: {
        fontSize: 14,
        color: "#444",
      },
      itemStyle: {
        normal: {
          color: function (params) {
            // Chọn màu tùy thuộc vào từng mức
            var colorMap = {
              "Level 1": "blue", // Mức 1: xanh dương
              "Level 2": "green", // Mức 2: xanh lá
              "Level 3": "orange",
              "Mức 1": "blue", // Mức 1: xanh dương
              "Mức 2": "green", // Mức 2: xanh lá
              "Mức 3": "orange",
            }
            return colorMap[params.name]
          },
        },
      },
    },
    grid: {
      top: "20%",
      left: "10%",
      right: "10%",
      bottom: "10%",
    },
    xAxis: {
      axisLine: {
        show: false,
      },
      axisTick: {
        show: false,
      },
      axisLabel: {
        show: true,
        fontSize: 12,
        margin: 15,
        interval: 0,
        rotate: 0,
      },
      scale: true,
      boundaryGap: false,
      name: "",
      axisPointer: {
        show: false,
        type: "none",
        label: {
          show: false,
          backgroundColor: "transparent",
          color: "#fff",
          formatter: params => ``,
        },
      },
    },
    yAxis: {
      type: "value",
      name: "",
      interval: 1,
      axisLine: {
        show: false,
      },
      axisTick: {
        show: true,
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: "dashed",
          color: "#ddd",
        },
      },
      min: Math.min(dataset.mean - 3 * dataset.sd, 0),
      scale: true,
      boundaryGap: false,
    },

    tooltip: {
      trigger: "item",
      confine: true,
      // position: "auto",
      backgroundColor: "rgba(0,0,0,0.7)",
      textStyle: {
        color: "#FFF",
        fontSize: 13,
        fontFamily: "Google Sans",
      },
      appendToBody: true,
      formatter: param => {
        if (!param.data) {
          return ""
        }
        if (param.data.isPosNeg != true) {
          if (param.data.status !== "REJ") {
            return `
                - ${t("Test Name")}: ${param.data.testName}<br/>
                 - ${t("Machine")}: ${param.data.insName}<br/>
                 - ${t("Level")}: ${param.data.levels}<br/>
                 - ${t("Lot")}: ${param.data.lotName}<br/>
                 - ${t("Result")}: ${param.data.result || 0}<br/>
                 - ${t("Unit")}: ${param.data.unit || ""}<br/>
                 - ${t("Mean")}: ${param.data.mean || ""}<br/>
                 - ${t("SD")}: ${param.data.sd || 0}<br/>
                 - ${t("GHKS")}: ${(convertToNumber(param.data.mean) - 2 * convertToNumber(param.data.sd)).toFixed(2) +
              " - " +
              (convertToNumber(param.data.mean) + 2 * convertToNumber(param.data.sd)).toFixed(2) || ""
              }<br/>
                 - ${t("6Sigma")}: ${param.data._6Sigma || ""}<br/>
                 - ${t("Run Time")}: ${param.data.runTime
                ? convertDateTimeFormat_VN(param.data.runTime)
                : ""
              }<br/>
                 - ${t("Validated Time")}: ${param.data.validatedTime
                ? convertDateTimeFormat_VN(param.data.validatedTime)
                : ""
              }<br/>
                 - ${t("Validated By")}: ${param.data.validatedBy || ""}<br/>`
          } else {
            return `- ${t("Test Code")}: ${param.data.testCode}  -  ${param.data.testName
              }<br/>
                   - ${t("Lot")}: ${param.data.lotName}  -  ${t("Level")}: ${param.data.levels
              }<br/>
                   - ${t("Result")}: ${param.data.result || 0}<br/>
                   - ${t("Mean")}: ${param.data.mean || ""}  -  ${t("SD")}: ${param.data.sd || ""
              }
                 <br/> - ${t("Run Time")}: ${param.data.runTime
                ? convertDateTimeFormat_VN(param.data.runTime)
                : ""
              }<br/>
                   - ${t("Validated Time")}: ${param.data.validatedTime
                ? convertDateTimeFormat_VN(param.data.validatedTime)
                : ""
              }  -  ${t("Validated By")}: ${param.data.validatedBy || ""}<br/>
                   - ${t("Violated Rules")}: ${param.data.singleRules || ""
              }<br/>`
          }
        } else if (param.data.isPosNeg == true) {
          let resultFinal = ""
          let resultCondition =
            param.data.result != null &&
            param.data.result != undefined &&
            param.data.result != ""
          let resultTextCondition =
            param.data.resultText != null &&
            param.data.resultText != undefined &&
            param.data.resultText != ""
          if (resultCondition && resultTextCondition) {
            resultFinal = param.data.result + "/" + param.data.resultText
          } else if (!resultCondition && resultTextCondition) {
            resultFinal = param.data.resultText
          } else if (resultCondition && !resultTextCondition) {
            resultFinal = param.data.result
          }
          return ` - ${t("Test Name")}: ${param.data.testName}<br/>
                 - ${t("Machine")}: ${param.data.insName}<br/>
                 - ${t("Level")}: ${param.data.levels}<br/>
                 - ${t("Lot")}: ${param.data.lotName}<br/>
                 - ${t("Result")}: ${resultFinal}<br/>
                 - ${t("Unit")}: ${param.data.unit || ""}<br/>
                 - ${t("GHKS")}: ${param.data.low + " - " + param.data.high || ""
            }<br/>
                 - ${t("Eval Index")}: ${param.data.eval || ""}<br/>
                 - ${t("Run Time")}: ${param.data.runTime
              ? convertDateTimeFormat_VN(param.data.runTime)
              : ""
            }<br/>
                 - ${t("Validated Time")}: ${param.data.validatedTime
              ? convertDateTimeFormat_VN(param.data.validatedTime)
              : ""
            }<br/>
                 - ${t("Validated By")}: ${param.data.validatedBy || ""}<br/>`
        }
      },
    },
    series: [
      {
        name: "Data Series",
        type: "line",
        data: dataset,
        markLine: {
          symbol: "none",
          lineStyle: {
            color: "#333",
            width: 2,
            type: "solid",
          },
          label: {
            show: false,
            position: "end",
            fontSize: 12,
            color: "#333",
          },
        },
      },
    ],
  })

  const ref = useRef()
  const refNSX = useRef()
  const ref1 = useRef()
  const ref2 = useRef()
  const ref3 = useRef()
  const ref4 = useRef()
  const ref5 = useRef()
  const refNSX1 = useRef()
  const refNSX2 = useRef()
  const refNSX3 = useRef()
  const refNSX4 = useRef()
  const refNSX5 = useRef()

  useEffect(() => {
    let showType = showUnvalidatedPoint ? "total" : "validated"
    if (onChangeFilterChart) {
      onChangeFilterChart(showType)
    }
  }, [showUnvalidatedPoint])

  useEffect(() => {
    if (generalData == null || Object.keys(generalData).length <= 0) {
      setDataset({})
      return
    }
    let newDataset = {}
    if (isPosNeg != true) {
      if (levels != "default") {
        let details = generalData["data"][levels]
        let dicIndexByLevel = generalData["index"][levels]
        if (details) {
          newDataset[levels] = details
          newDataset["mean"] =
            flg == false ? dicIndexByLevel?.mean : dicIndexByLevel?.meanPI
          newDataset["cv"] =
            flg == false ? dicIndexByLevel?.cv : dicIndexByLevel?.cvpi
          newDataset["sd"] =
            flg == false ? dicIndexByLevel?.sd : dicIndexByLevel?.sdpi
          newDataset["meanPI"] = dicIndexByLevel?.meanPI
          newDataset["sdpi"] = dicIndexByLevel?.sdpi
          newDataset["cvpi"] = dicIndexByLevel?.cvpi
        }
      } else {
        let details = generalData["data"]
        let key = Object.entries(generalData["index"])[0]
        let dicIndexByLevel = generalData["index"][key[0]]
        newDataset = { ...details }
        newDataset["mean"] =
          flg == false ? dicIndexByLevel?.mean : dicIndexByLevel?.meanPI
        newDataset["cv"] =
          flg == false ? dicIndexByLevel?.cv : dicIndexByLevel?.cvpi
        newDataset["sd"] =
          flg == false ? dicIndexByLevel?.sd : dicIndexByLevel?.sdpi
        newDataset["meanPI"] = dicIndexByLevel?.meanPI
        newDataset["sdpi"] = dicIndexByLevel?.sdpi
        newDataset["cvpi"] = dicIndexByLevel?.cvpi
        if (levelsChartOption === "one") {
          let newOneLevelDataset = new Object()
          Object.keys(details).forEach(key => {
            newOneLevelDataset[key] = {}
            newOneLevelDataset[key][key] = details[key]
            let dicIndexByLevel = generalData["index"][key]
            newOneLevelDataset[key]["mean"] =
              flg == false ? dicIndexByLevel?.mean : dicIndexByLevel?.meanPI
            newOneLevelDataset[key]["cv"] =
              flg == false ? dicIndexByLevel?.cv : dicIndexByLevel?.cvpi
            newOneLevelDataset[key]["sd"] =
              flg == false ? dicIndexByLevel?.sd : dicIndexByLevel?.sdpi
            newOneLevelDataset[key]["meanPI"] = dicIndexByLevel?.meanPI
            newOneLevelDataset[key]["sdpi"] = dicIndexByLevel?.sdpi
            newOneLevelDataset[key]["cvpi"] = dicIndexByLevel?.cvpi
          })
          setOneLeveDataset(newOneLevelDataset)
        }
      }
    } else {
      if (levels != "default") {
        let details = generalData["data"][levels]
        let index = generalData["indexTotal"]
        if (details) {
          newDataset[levels] = {}
          newDataset[levels]["data"] = details
          Object.keys(index).forEach(lotId => {
            if (levels in index[lotId]) {
              let idxLot = index[lotId][levels]
              let newObj = {}
              newObj[levels] = 0
              newDataset[lotId] = {}
              newDataset[lotId]["sortLow"] = newObj
              newDataset[lotId]["grayVal"] = [0]
              newDataset[lotId]["range"] = [
                [-Infinity, idxLot.low, -Infinity],
                [idxLot.low, idxLot.high, 1],
                [idxLot.high, Infinity, Infinity],
              ]
            }
          })
        }
      } else {
        let objIndex = {}
        if (Object.keys(generalData["data"]).length > 0) {
          let details = generalData["data"]
          Object.keys(details).forEach(key => {
            newDataset[key] = {}
            newDataset[key]["data"] = details[key]
            Object.keys(generalData["indexTotal"]).forEach(lotId => {
              if (key in generalData["indexTotal"][lotId]) {
                let idxLot = generalData["indexTotal"][lotId][key]
                newDataset[key][lotId] = idxLot
                if (!(lotId in objIndex)) {
                  objIndex[lotId] = []
                }
                objIndex[lotId].push([idxLot.low, idxLot.high, key])
              }
            })
          })
          Object.keys(objIndex).forEach(lotId => {
            objIndex[lotId].sort((a, b) => a[0] - b[0])
            let sortLow = {}
            let grayVal = [0]
            let range = [[-Infinity, objIndex[lotId][0][0], -Infinity]]
            let lastHigh = 0
            for (let i = 0; i < objIndex[lotId].length; i++) {
              sortLow[objIndex[lotId][i][2]] = i
              lastHigh = objIndex[lotId][i][1]
              if (i >= 1) {
                let normalVal =
                  objIndex[lotId][i][0] - objIndex[lotId][i - 1][1] > 0 ? 0.4 : 0
                range.push([objIndex[lotId][i - 1][1], objIndex[lotId][i][0], 0.4])
                grayVal.push(normalVal)
              }
              range.push([objIndex[lotId][i][0], objIndex[lotId][i][1], 1])
            }
            range.sort((a, b) => a[0] - b[0])
            range.push([lastHigh, Infinity, Infinity])
            newDataset[lotId] = {}
            newDataset[lotId]["range"] = range
            newDataset[lotId]["sortLow"] = sortLow
            newDataset[lotId]["grayVal"] = grayVal
          })

          if (levelsChartOption === "one") {
            let newOneLevelDataset = new Object()
            let objIndexLv = {}
            Object.keys(details).forEach(key => {
              objIndexLv[key] = {}
              newOneLevelDataset[key] = {}
              newOneLevelDataset[key][key] = {}
              newOneLevelDataset[key][key]["data"] = details[key]
              Object.keys(generalData["indexTotal"]).forEach(lotId => {
                if (key in generalData["indexTotal"][lotId]) {
                  let idxLot = generalData["indexTotal"][lotId][key]
                  newOneLevelDataset[key][key][lotId] = idxLot
                  if (!(lotId in objIndexLv[key])) {
                    objIndexLv[key][lotId] = []
                  }
                  objIndexLv[key][lotId].push([idxLot.low, idxLot.high, key])
                }
              })
              Object.keys(objIndexLv[key]).forEach(lotId => {
                objIndexLv[key][lotId].sort((a, b) => a[0] - b[0])
                let sortLow = {}
                let grayVal = [0]
                let range = [[-Infinity, objIndexLv[key][lotId][0][0], -Infinity]]
                let lastHigh = 0
                for (let i = 0; i < objIndexLv[key][lotId].length; i++) {
                  sortLow[objIndexLv[key][lotId][i][2]] = i
                  lastHigh = objIndexLv[key][lotId][i][1]
                  if (i >= 1) {
                    let normalVal =
                      objIndexLv[key][lotId][i][0] - objIndexLv[key][lotId][i - 1][1] > 0
                        ? 0.4
                        : 0
                    range.push([
                      objIndexLv[key][lotId][i - 1][1],
                      objIndexLv[key][lotId][i][0],
                      0.4,
                    ])
                    grayVal.push(normalVal)
                  }
                  range.push([objIndexLv[key][lotId][i][0], objIndexLv[key][lotId][i][1], 1])
                }
                range.sort((a, b) => a[0] - b[0])
                range.push([lastHigh, Infinity, Infinity])
                newOneLevelDataset[key][lotId] = {}
                newOneLevelDataset[key][lotId]["range"] = range
                newOneLevelDataset[key][lotId]["sortLow"] = sortLow
                newOneLevelDataset[key][lotId]["grayVal"] = grayVal
              })
            })
            setOneLeveDataset(newOneLevelDataset)
          }
        }
      }
    }
    setDataset(newDataset)
  }, [levels, generalData, levelsChartOption])

  function getMaxValuesFromArrays(arrays) {
    const clonedArrays = arrays.map(arr => _.cloneDeep(arr))
    const maxLength = Math.max(...clonedArrays.map(arr => arr.length))
    const extendedArrays = clonedArrays.map(arr => {
      const extendedArr = arr.slice()
      while (extendedArr.length < maxLength) {
        extendedArr.push(-Infinity)
      }
      return extendedArr
    })

    return Array.from({ length: maxLength }, (_, index) => {
      return Math.max(...extendedArrays.map(arr => arr[index]))
    })
  }
  function getMaxValuesFromNestedArrays(arr) {
    if (!arr || arr.length === 0) {
      return null
    }

    let longestArr = arr[0]
    let maxLength = arr[0].length

    for (let i = 1; i < arr.length; i++) {
      if (arr[i].length > maxLength) {
        maxLength = arr[i].length
        longestArr = arr[i]
      } else if (arr[i].length === maxLength) {
        const lastElementLongest = longestArr[longestArr.length - 1][2]
        const lastElementCurrent = arr[i][arr[i].length - 1][2]

        if (lastElementCurrent > lastElementLongest) {
          longestArr = arr[i]
        }
      }
    }

    return longestArr
  }

  const createChartData = (dataset, type) => {
    let allDataPoints = []
    let allFixedLines = []
    let dynamicDatasets = []
    let fixedDatasets = []
    let datachart = []
    let labels = []
    if (isPosNeg != true) {
      let mean = dataset.mean
      let sd = dataset.sd
      let cv = dataset.cv
      if (type === "NSX") {
        mean = dataset.meanPI
        sd = dataset.sdpi
        cv = dataset.cvpi
      }

      let changeIdx = false
      let numpoint = 0
      let preMeanTotal = Infinity
      let preSDTotal = Infinity

      Object.keys(dataset).forEach(key => {
        if (["mean", "cv", "sd", "meanPI", "sdpi", "cvpi"].includes(key)) return
        let entry = dataset[key]
        if (!Array.isArray(entry)) {
          console.warn(`Skipping key "${key}" because it is not an array.`)
          return
        }
        let preMean = Infinity
        let preSD = Infinity

        datachart = entry.map((item, index) => {
          let calculatedValue = null
          let isChange = false
          let tempSD = item?.sd
          let tempMean = item?.mean
          if (
            item?.resultConvert != null &&
            item.resultConvert != undefined &&
            item.resultConvert != ""
          ) {
            numpoint += 1
            let result = convertToNumber(item?.resultConvert)
            let isNSX = item?.isNSX

            if (type != "NSX") {
              if (isNSX == true) {
                tempSD = item?.sdpi
                tempMean = item?.meanPI
              }
              if (preMeanTotal == Infinity) {
                preMeanTotal = tempMean
              }
              if (preMean == Infinity) {
                preMean = tempMean
              }
              if (preSDTotal == Infinity) {
                preSDTotal = tempSD
              }
              if (preSD == Infinity) {
                preSD = tempSD
              }
              if (preMeanTotal != tempMean || preSDTotal != tempSD) {
                changeIdx = true
              }
              if (preSD != tempSD || preMean != tempMean) {
                preSD = tempSD
                preMean = tempMean
                isChange = true
              }
              if (tempMean != null && tempMean != undefined) {
                if (tempSD > 0) {
                  calculatedValue = (result - tempMean) / tempSD
                }
                else {
                  if (result > tempMean) {
                    calculatedValue = Infinity
                  } else if (result < tempMean) {
                    calculatedValue = -Infinity
                  } else {
                    calculatedValue = 0
                  }
                }
              } else {
                calculatedValue = 0
              }
            }

            if (type == "NSX") {
              tempSD = item?.sdpi
              tempMean = item?.meanPI
              if (preMeanTotal == Infinity) {
                preMeanTotal = tempMean
              }
              if (preMean == Infinity) {
                preMean = tempMean
              }
              if (preSDTotal == Infinity) {
                preSDTotal = tempSD
              }
              if (preSD == Infinity) {
                preSD = tempSD
              }
              if (preMeanTotal != tempMean || preSDTotal != tempSD) {
                changeIdx = true
              }
              if (preSD != tempSD || preMean != tempMean) {
                preSD = tempSD
                preMean = tempMean
                isChange = true
              }
              if (tempMean != null && tempMean != undefined) {
                if (tempSD > 0) {
                  calculatedValue = (result - tempMean) / tempSD
                }
                else {
                  if (result > tempMean) {
                    calculatedValue = Infinity
                  } else if (result < tempMean) {
                    calculatedValue = -Infinity
                  } else {
                    calculatedValue = 0
                  }
                }
              } else {
                calculatedValue = 0
              }
            }
          }

          if (calculatedValue != null) {
            if (calculatedValue > 4) {
              calculatedValue = 4
            } else if (calculatedValue < -4) {
              calculatedValue = -4
            }
          }

          return {
            ...item,
            sd: type == "NSX" || item?.isNSX == true ? item?.sdpi : item?.sd,
            mean:
              type == "NSX" || item?.isNSX == true ? item?.meanPI : item?.mean,
            result: item?.result,
            resultConvert: convertToNumber(item?.resultConvert),
            status: item?.status,
            isChange: isChange,
            value: calculatedValue != null ? calculatedValue : null,
            isFirstLot: index === 0 || item.lotId !== entry[index - 1]?.lotId,
          }
        })

        datachart = datachart.map(item => {
          let symbolPoint = "circle"
          let colorPoint = "blue"
          if (item.status === "WAR") {
            colorPoint = "orange"
          } else if (item.status === "REJ") {
            colorPoint = "red"
          } else if (item.isFirstLot == true) {
            colorPoint = "#0C59CF"
          }
          symbolPoint = getIcon(item.levels)

          return {
            ...item,
            symbol: symbolPoint,
            itemStyle: {
              color: colorPoint,
              color: item.isChange ? "transparent" : colorPoint,
              borderColor: item.isChange ? colorPoint : "",
              borderWidth: item.isChange ? 2 : "",
            },
          }
        })
        allDataPoints.push({
          name: `${t("Level")} ${key}`,
          type: "line",
          data: datachart,
        })
      })
      let newDateArr = cloneDeep(dateArr)
      if (
        levels != "default" ||
        (levels == "default" && levelsChartOption != "multi")
      ) {
        newDateArr = []
        let newDataChart = []
        if (allDataPoints.length > 0) {
          newDataChart = allDataPoints[0].data.filter(
            item => item.resultConvert != undefined && !Number.isNaN(item.resultConvert)
          )
          allDataPoints[0].data = newDataChart
        }

        for (let i = 0; i < newDataChart.length; i++) {
          newDateArr.push(convertDayMonth(newDataChart[i]?.runTime))
        }
      }

      newDateArr = [null, ...newDateArr, null]
      allDataPoints.forEach(dataSet => {
        dataSet.data = [null, ...dataSet.data, null]
      })

      labels = Array.from(
        { length: newDateArr.length },
        (_, idx) => newDateArr[idx] || ""
      )
      let pointSize = 12
      let pointSizeChange = 16
      if (numpoint > 15) {
        pointSize = 8
        pointSizeChange = 12
      }
      if (numpoint > 30) {
        pointSize = 6
        pointSizeChange = 8
      }
      if (numpoint > 64) {
        pointSize = 3
        pointSizeChange = 4
      }
      if (numpoint > 100) {
        pointSize = 2
        pointSizeChange = 3
      }

      dynamicDatasets = allDataPoints.map(point => ({
        ...point,
        name: point.name,
        type: point.type,
        data: point.data,
        symbolSize: point.isNSX ? pointSizeChange : pointSize,
      }))

      let objFixLine = createObjFixLine(
        changeIdx,
        allDataPoints.length,
        mean,
        sd,
        levels,
        levelsChartOption
      )
      Object.values(objFixLine).forEach(value => allFixedLines.push(value))
      fixedDatasets = allFixedLines.map(line => ({
        ...line,
        name: line.name,
        type: line.type,
        data: Array(labels.length).fill(line.value),
      }))
    } else {
      let numpoint = 0
      let totalGrayVal = []
      let totalRange = []
      let composeGrayVal = []
      Object.keys(dataset).forEach(key => {
        let entry = dataset[key]["data"]
        let dicRangeLv = {}
        if (!Array.isArray(entry)) {
          console.warn(`Skipping key "${key}" because it is not an array.`)
          return
        }
        entry.map((item, index) => {
          let lotId = item?.lotId
          if (lotId == null || lotId == undefined) {
            return
          }
          let grayVal = dataset[lotId]["grayVal"]
          totalGrayVal.push(grayVal)
          composeGrayVal = getMaxValuesFromArrays(totalGrayVal)
        })
        datachart = entry.map((item, index) => {
          let value = null
          let lotId = item?.lotId
          if (lotId == null || lotId == undefined) {
            return {
              ...item,
              result: null,
              status: null,
              value: value,
            }
          }

          let sortLow = dataset[lotId]["sortLow"]
          let range = dataset[lotId]["range"]
          totalRange.push(range)
          if (!(lotId in dicRangeLv)) {
            dicRangeLv[lotId] = 0
            for (let i = 0; i <= sortLow[key]; i++) {
              dicRangeLv[lotId] += composeGrayVal[i]
            }
          }
          let rangeLv = dicRangeLv[lotId]
          numpoint += 1
          let result = item?.result
          let resultText = item?.resultText
          let evalI = item?.eval
          let resultCondition =
            result != null &&
            result != undefined &&
            result != "" &&
            !Number.isNaN(convertToNumber(result))
          let resultTextCondition =
            resultText != null && resultText != undefined
          let evalCondition = evalI != null && evalI != undefined
          let status = item.status
          let high = item.high
          let low = item.low
          if (resultCondition) {
            let resultNum = convertToNumber(result)
            value = (resultNum - low) / (high - low) + sortLow[key] + rangeLv
            if (!(status == "ACC")) {
              if (range.length == 3) {
                if (resultNum > high) {
                  value =
                    Math.min((resultNum - low) / (high - low), 1.25) +
                    sortLow[key] +
                    rangeLv
                } else {
                  value =
                    sortLow[key] +
                    rangeLv -
                    Math.max((resultNum - low) / (high - low), 0.25)
                }
              }
              else {
                if (resultNum > range[0][0] && resultNum < range[0][1]) {
                  value = -0.25
                }
                else if (resultNum > range[range.length - 1][0] && resultNum < range[range.length - 1][1]) {
                  let height = 0
                  for (let i = 1; i < range.length - 1; i++) {
                    height += range[i][2]
                  }
                  value = height + 0.25
                }
                else {
                  let currHigh = 0
                  for (let i = 1; i < range.length - 1; i++) {
                    if ((resultNum > range[i][0] && resultNum < range[i][1]) || (resultNum == range[i][0]) || (resultNum == range[i][1])) {
                      value = ((resultNum - range[i][0]) / (range[i][1] - range[i][0]) * range[i][2]) + currHigh
                      break;
                    }
                    currHigh += range[i][2]
                  }
                }
              }
            }
          } else {
            if (status == "ACC") {
              value = 0.5 + sortLow[key] + rangeLv
            } else {
              if (resultTextCondition && evalCondition) {
                if (resultText.toLowerCase() != evalI.toLowerCase()) {
                  if (evalI.toLowerCase() == t("Negative").toLowerCase()) {
                    value = 1.25 + sortLow[key] + rangeLv
                  } else {
                    value = sortLow[key] + rangeLv - 0.25
                  }
                }
              } else {
                if (result.includes(">")) {
                  value = 1.25 + sortLow[key] + rangeLv
                } else {
                  value = sortLow[key] + rangeLv - 0.25
                }
              }
            }
          }
          return {
            ...item,
            high: item.high,
            low: item.low,
            result: item.result,
            status: item.status,
            value: value,
            isFirstLot: index === 0 || item.lotId !== entry[index - 1]?.lotId,
          }
        })

        datachart = datachart.map(item => {
          let symbolPoint = "circle"
          let colorPoint = "blue"
          if (item.status === "REJ") {
            colorPoint = "red"
          }
          symbolPoint = getIcon(item.levels)

          return {
            ...item,
            symbol: symbolPoint,
            itemStyle: {
              color: colorPoint,
              color: item.isChange ? "transparent" : colorPoint,
              borderColor: item.isChange ? colorPoint : "",
              borderWidth: item.isChange ? 2 : "",
            },
          }
        })

        allDataPoints.push({
          name: `${t("Level")} ${key}`,
          type: "line",
          data: datachart,
        })
      })
      let newDateArr = cloneDeep(dateArr)
      if (
        levels != "default" ||
        (levels == "default" && levelsChartOption != "multi")
      ) {
        newDateArr = []
        let newDataChart = []
        if (allDataPoints.length > 0) {
          newDataChart = allDataPoints[0].data.filter(
            item => item.result != undefined && !Number.isNaN(item.result)
          )
          allDataPoints[0].data = newDataChart
        }

        for (let i = 0; i < newDataChart.length; i++) {
          newDateArr.push(convertDayMonth(newDataChart[i]?.runTime))
        }
      }

      newDateArr = [null, ...newDateArr, null]
      allDataPoints.forEach(dataSet => {
        dataSet.data = [null, ...dataSet.data, null]
      })

      labels = Array.from(
        { length: newDateArr.length },
        (_, idx) => newDateArr[idx] || ""
      )
      let pointSize = 12
      let pointSizeChange = 16
      if (numpoint > 15) {
        pointSize = 8
        pointSizeChange = 12
      }
      if (numpoint > 30) {
        pointSize = 6
        pointSizeChange = 8
      }
      if (numpoint > 64) {
        pointSize = 3
        pointSizeChange = 4
      }
      if (numpoint > 100) {
        pointSize = 1
        pointSizeChange = 2
      }

      dynamicDatasets = allDataPoints.map(point => ({
        ...point,
        name: point.name,
        type: point.type,
        data: point.data,
        symbolSize: point.isNSX ? pointSizeChange : pointSize,
      }))

      const composeTotalRange = getMaxValuesFromNestedArrays(totalRange)
      let objFixLine = createObjFixLineBDL(
        composeGrayVal,
        selectedLots.length == 1,
        composeTotalRange
      )
      Object.values(objFixLine).forEach(value => allFixedLines.push(value))

      fixedDatasets = allFixedLines.map(line => ({
        ...line,
        name: line.name,
        type: line.type,
        data: Array(labels.length).fill(line.value),
      }))
    }
    return {
      labels,
      datasets: [...fixedDatasets, ...dynamicDatasets],
    }
  }

  const filterUniqueDates = arr => {
    let seen = new Set()
    let result = []
    arr.forEach((value, index) => {
      if (value != null && value != "") {
        if (value && !seen.has(value)) {
          seen.add(value)
          result.push({ value, index })
        }
      }
    })
    return result
  }

  const formatter = (option, timeOption, chartData) => {
    if (option.xAxis.data?.length > lengthAutoscale) {
      option.xAxis.axisLabel.fontSize = 'auto'
      option.xAxis.axisLabel.rotate = degRotateSacle
      option.xAxis.axisLabel.margin = marginRotate
    }
    else if (option.xAxis.data?.length > lengthRotate) {
      option.xAxis.axisLabel.rotate = degRotate
      option.xAxis.axisLabel.margin = marginRotate
      option.xAxis.axisLabel.fontSize = 12
    } else {
      option.xAxis.axisLabel.rotate = degRotateDefault
      option.xAxis.axisLabel.margin = marginRotateDefault
      option.xAxis.axisLabel.fontSize = 12
    }
    if (timeOption === "ByRun") {
      //axisLabelCustom là tự thêm, ko có sẵn trong xAxis của thư viện
      option.xAxis.axisLabelCustom = filterUniqueDates(chartData?.labels)
      option.xAxis.axisLabel.formatter = function (value, index) {
        if (index === 1 || value !== chartData?.labels[index - 1]) {
          return value
        } else {
          return ""
        }
      }
    } else {
      option.xAxis.axisLabel.formatter = function (value, index) {
        return value
      }
    }
  }

  const mapOption = (option, chartData) => {
    let newChartData = cloneDeep(chartData)
    if (
      newChartData?.datasets != null &&
      Object.keys(newChartData?.datasets).length > 0
    ) {
      const setNames = [
        t("Level") + " 1",
        t("Level") + " 2",
        t("Level") + " 3",
        t("Level") + " 4",
        t("Level") + " 5",
      ]
      option.legend.data = []
      let newDataset = cloneDeep(newChartData?.datasets)
      option.series = newDataset || []
      option.xAxis.data = newChartData?.labels || []
      for (let i in option.series) {
        if (
          option.series[i].data.length > 0 &&
          setNames.includes(option.series[i].name)
        ) {
          let symbolPoint = "circle"
          switch (option.series[i].name) {
            case setNames[0]:
              symbolPoint = "circle"
              break
            case setNames[1]:
              symbolPoint = "triangle"
              break
            case setNames[2]:
              symbolPoint = "path://M -10 -10 L 10 -10 L 10 10 L -10 10 Z"
              break
            case setNames[3]:
              symbolPoint = "diamond"
              break
            case setNames[4]:
              symbolPoint = "path://M50,0 L100,38 L81,100 L19,100 L0,38 Z"
              break
            default:
          }

          option.series[i].connectNulls = true
          option.series[i].large = true
          option.legend.data.push({
            name: option.series[i].name,
            icon: symbolPoint,
          })
        }
      }
    }
    return option
  }

  const resetChart = (refer, option) => {
    if (refer.current !== null) {
      let chartInstance = refer?.current?.getEchartsInstance()
      if (chartInstance !== undefined) {
        chartInstance.clear()
        chartInstance.setOption(option)
      }
    }
  }

  const makeChart = (refer, newOptions, dataset, indexType) => {
    let chartData = createChartData(dataset, indexType)
    mapOption(newOptions, chartData)
    formatter(newOptions, timeOption, chartData)
    resetChart(refer, newOptions)
  }

  const updateChart = (ref, options, dataset, type = "") => {
    let newOptions = cloneDeep(options)
    makeChart(ref, newOptions, dataset, type)
    return newOptions
  }

  const resetEmptyOptions = setOptionsFn => {
    let emptyOptions = cloneDeep(options)
    emptyOptions.series = []
    emptyOptions.xAxis.data = []
    setOptionsFn(emptyOptions)
  }

  const checkDisplay = option => {
    let check = false
    for (let i = 0; i < option?.series?.length; i++) {
      if (option?.series[i]?.name?.includes(t("Level"))) {
        check = true
        break
      }
    }
    return check
  }

  useEffect(() => {
    if (
      (levelsChartOption !== "one" ||
        (levelsChartOption === "one" && levels != "default")) &&
      Object.keys(dataset).length > 0
    ) {
      if (showBothCharts === false) {
        setOptions(updateChart(ref, options, dataset))
      } else if (showBothCharts === true) {
        setOptions(updateChart(ref, options, dataset))
        setOptionsNSX(updateChart(refNSX, options, dataset, "NSX"))
      } else {
        resetEmptyOptions(setOptions)
        resetEmptyOptions(setOptionsNSX)
      }
    } else if (
      levelsChartOption === "one" &&
      Object.keys(oneLevelDataset).length > 0
    ) {
      let setLevel = new Set()
      const refMap = {
        1: [ref1, setOptions1, refNSX1, setOptionsNSX1],
        2: [ref2, setOptions2, refNSX2, setOptionsNSX2],
        3: [ref3, setOptions3, refNSX3, setOptionsNSX3],
        4: [ref4, setOptions4, refNSX4, setOptionsNSX4],
        5: [ref5, setOptions5, refNSX5, setOptionsNSX5],
      }
      if (levels === "default") {
        Object.keys(oneLevelDataset).forEach(key => {
          const oneLevelChartData = createChartData(cloneDeep(oneLevelDataset[key]))
          const newOptions = mapOption(cloneDeep(options), oneLevelChartData)
          formatter(newOptions, timeOption, oneLevelChartData)
          setLevel.add(key)

          if (refMap[key]) {
            resetChart(refMap[key][0], newOptions)
            refMap[key][1](newOptions)

            if (showBothCharts === true) {
              const oneLevelChartDataNSX = createChartData(
                oneLevelDataset[key],
                "NSX"
              )
              const newOptionsNSX = mapOption(
                cloneDeep(options),
                oneLevelChartDataNSX
              )
              formatter(newOptionsNSX, timeOption, oneLevelChartDataNSX)
              resetChart(refMap[key][2], newOptionsNSX)
              refMap[key][3](newOptionsNSX)
            }
          }
        })
      }

      ;["1", "2", "3", "4", "5"].forEach(level => {
        if (!setLevel.has(level)) {
          resetEmptyOptions(refMap[level][1])
          resetEmptyOptions(refMap[level][3])
        }
      })
    }
  }, [dataset, levelsChartOption, timeOption, showBothCharts])

  if (!generalData || Object.keys(dataset).length === 0) {
    return <div className="text-center">{t("Empty Chart")}</div>
  }

  const PrintConfig = async image => {
    if (dataReport.length > 0 && !isEmpty(dataReport[0].uri)) {
      let res = await getFilePrintResult({
        ...GetDataUrlReportConfig(dataReport, ReportResource.ResultIQC, ""),
        // ResultId: datapointId,
        ResultId: image,
        PrinterName: printResultConfig?.printerName,
      })
      try {
        const getPingPrintService = await pingToPrintService()
        if (getPingPrintService.pingTime) {
          const dataSendPrint = await sendDataPrint({
            ...GetDataUrlReportConfig(dataReport, ReportResource.ResultIQC, ""),
            filePath: `${res.reportUrl}`,
            printerNameBarcode: printResultConfig.printerName,
            // "quantity": rowState.numberOfLabels
          })
          if (dataSendPrint === "failed") {
            showErrToast("Vui lòng chọn máy in")
            setTimeout(() => {
              window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
            }, 1500)
          } else {
            await getFilePrintResult({
              ...GetDataUrlReportConfig(
                reportResult,
                ReportResource.ChartIQC,
                ""
              ),
              ResultId: image,
              PrinterName: printResultConfig.printerName,
            })
          }
        } else {
          window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
        }
      } catch {
        window.open(process.env.REACT_APP_BASE_ENDPOINT + res?.reportUrl)
      }
    } else {
      const print = localStorage.getItem("printConfig")
      if (isEmpty(print)) {
        setOpen(true)
      } else {
        setModalPreview(true)
      }
    }
  }

  const checkLotByTime = (chart, levelDisplay) => {
    let chartOption = chart.getOption()

    //check lại vị trí đổi màu, áp dụng cho nhiều mức
    let lengthData = 0
    let dataLevel = levelDisplay.map(x => {
      let l = options.series.find(z => z.name == x)?.data || []
      lengthData = l.length
      return {
        name: x,
        data: l,
      }
    })
    let indexChangeColor = []
    for (let i = 1; i < lengthData - 1; i++) {
      let isChange = false
      let lots = []
      levelDisplay.forEach(element => {
        let dt = dataLevel.find(z => z.name == element)?.data[i]
        if (dt && i > 1 && dt.isFirstLot == true && dt.lotName) {
          isChange = true
        }
        if (dt && dt.lotName) {
          if (lots.findIndex(z => z == dt.lotName) < 0) lots.push(dt.lotName)
        }
      })
      if (isChange == true || lots.length > 1) {
        indexChangeColor.push(i)
      }
    }

    if (chartOption.xAxis[0]?.data?.length > lengthAutoscale) {
      chartOption.xAxis[0].axisLabel.fontSize = 'auto'
      chartOption.xAxis[0].axisLabel.rotate = degRotateSacle
      chartOption.xAxis[0].axisLabel.margin = marginRotate
    }
    else if (chartOption.xAxis[0]?.data?.length > lengthRotate) {
      chartOption.xAxis[0].axisLabel.rotate = degRotate
      chartOption.xAxis[0].axisLabel.margin = marginRotate
      chartOption.xAxis[0].axisLabel.fontSize = 12
    } else {
      chartOption.xAxis[0].axisLabel.rotate = degRotateDefault
      chartOption.xAxis[0].axisLabel.margin = marginRotateDefault
      chartOption.xAxis[0].axisLabel.fontSize = 12
    }
    if (indexChangeColor.length > 0) {
      chartOption.xAxis[0].axisLabel.formatter = (value, index) => {
        if (indexChangeColor.includes(index)) return `{blue|${value}}`
        else return `{default|${value}}`
      }
      chartOption.xAxis[0].axisLabel.rich = {
        blue: {
          color: "#0C59CF", // Đổi màu chữ cho vị trí 2
        },
        default: {
          color: "black", // Giữ nguyên màu chữ mặc định
        },
      }
      chart.setOption(chartOption, false)
    }
  }

  const checkLotByRun = (chart, levelDisplay) => {
    let chartOption = chart.getOption()
    let lengthData = 0
    let dataLevel = levelDisplay.map(x => {
      let l = options.series.find(z => z.name == x)?.data || []
      lengthData = l.length
      return {
        name: x,
        data: l,
      }
    })
    let axisLabelCustom = chartOption.xAxis[0].axisLabelCustom || [] //giá trị axisLabelCustom đê chỉnh màu
    axisLabelCustom.forEach(element => {
      if (element.index == 1) {
        // index 1 chỉ hiển thị khi có nhiều lô
        let indexCheck = chartOption.xAxis[0].data.map((x, index) => {
          if (element.value == x) {
            return index
          }
          return -99
        })
        indexCheck = indexCheck.filter(x => x != -99) || []
        //đã có được các vị trí để check lô trong data
        if (indexCheck.length > 0) {
          let dataTMP = []
          indexCheck.forEach(row => {
            levelDisplay.forEach(element => {
              let tmp = dataLevel.find(z => z.name == element)?.data || []
              if (
                dataTMP.findIndex(z => z.lotId == tmp[row]?.lotId) < 0 &&
                tmp[row]?.lotId != undefined &&
                tmp[row]?.lotId != null &&
                tmp[row]?.lotId != ""
              ) {
                dataTMP.push(tmp[row])
              }
            })
          })
          if (dataTMP.length > 1) {
            //lớn hơn 1 vì ko cho set isFistLot ở vị trí 1
            element.data = dataTMP
          }
        }
      } else {
        let indexCheck = chartOption.xAxis[0].data.map((x, index) => {
          if (element.value == x) {
            return index
          }
          return -99
        })
        indexCheck = indexCheck.filter(x => x != -99) || []
        //đã có được các vị trí để check lô torng data
        if (indexCheck.length > 0) {
          let dataTMP = []
          indexCheck.forEach(row => {
            levelDisplay.forEach(element => {
              let tmp = dataLevel.find(z => z.name == element)?.data || []
              if (
                dataTMP.findIndex(z => z.lotId == tmp[row]?.lotId) < 0 &&
                tmp[row]?.lotId != undefined &&
                tmp[row]?.lotId != null &&
                tmp[row]?.lotId != ""
              ) {
                dataTMP.push(tmp[row])
              }
            })
          })
          if (
            dataTMP.length > 1 ||
            dataTMP.findIndex(x => x.isFirstLot == true) >= 0
          ) {
            element.data = dataTMP
          }
        }
      }
    })

    if (chartOption.xAxis[0]?.data?.length > lengthAutoscale) {
      chartOption.xAxis[0].axisLabel.fontSize = 'auto'
      chartOption.xAxis[0].axisLabel.rotate = degRotateSacle
      chartOption.xAxis[0].axisLabel.margin = marginRotate
    }
    else if (chartOption.xAxis[0]?.data?.length > lengthRotate) {
      chartOption.xAxis[0].axisLabel.rotate = degRotate
      chartOption.xAxis[0].axisLabel.margin = marginRotate
      chartOption.xAxis[0].axisLabel.fontSize = 12
    } else {
      chartOption.xAxis[0].axisLabel.rotate = degRotateDefault
      chartOption.xAxis[0].axisLabel.margin = marginRotateDefault
      chartOption.xAxis[0].axisLabel.fontSize = 12
    }
    if (
      axisLabelCustom &&
      axisLabelCustom.length > 0 &&
      axisLabelCustom.findIndex(z => z.data && z.data.length > 0) > -1
    ) {
      chartOption.xAxis[0].axisLabel.formatter = (value, index) => {
        if (index === 0 || value !== chartOption.xAxis[0].data[index - 1]) {
          let arrIndex = axisLabelCustom
            .filter(z => z.data && z.data.length > 0)
            .map(x => x.index)
          if (arrIndex.includes(index)) return `{blue|${value}}`
          else return `{default|${value}}`
        } else {
          return ""
        }
      }
      chartOption.xAxis[0].axisLabel.rich = {
        blue: {
          color: "#0C59CF", // Đổi màu chữ cho vị trí 2
        },
        default: {
          color: "black", // Giữ nguyên màu chữ mặc định
        },
      }
    }
    chart.setOption(chartOption, false)
  }

  const resetChartByTime = (chart, chartOption) => {
    chartOption.tooltip[0].trigger = "item"
    chartOption.xAxis[0].axisPointer.show = false
    chartOption.tooltip[0].formatter = options.tooltip.formatter
    chart.setOption(chartOption, false)
  }

  const onMousemoveByTime = (chart, event, levelDisplay) => {
    let pointCheck = isPosNeg == true ? pointInGridBDL : pointInGridDefault
    const pointInGrid = chart.convertFromPixel({ seriesIndex: 0 }, [
      event.offsetX,
      event.offsetY,
    ])
    let chartOption = chart.getOption()
    if (
      pointInGrid &&
      pointInGrid[0] >= 1 &&
      pointInGrid[1] <= pointCheck &&
      // && levelDisplay.length == 1
      options.xAxis.data
    ) {
      const dataIndex = Math.round(pointInGrid[0]) // Lấy index gần nhất
      const xAxisData = options.xAxis.data[dataIndex] // Lấy giá trị label
      //check lại vị trí đổi màu, áp dụng cho nhiều mức
      let lengthData = 0
      let dataLevel = levelDisplay.map(x => {
        let l = options.series.find(z => z.name == x)?.data || []
        lengthData = l.length
        return {
          name: x,
          data: l,
        }
      })
      let isChange = false
      let lots = []
      levelDisplay.forEach(element => {
        let dt = dataLevel.find(z => z.name == element)?.data[dataIndex]
        if (dt && dataIndex > 1 && dt.isFirstLot == true && dt.lotName) {
          isChange = true
        }
        if (dt && dt.lotName) {
          if (lots.findIndex(z => z == dt.lotName) < 0) lots.push(dt.lotName)
        }
      })
      if (isChange == true || lots.length > 1) {
        chartOption.tooltip[0].trigger = "axis"
        chartOption.xAxis[0].axisPointer.show = true
        let lotName = ""
        lots.forEach(element => {
          lotName += t("Lot") + ": " + element + "</br>"
        })
        chartOption.tooltip[0].formatter = () => {
          return lotName
        }
        chart.setOption(chartOption, false)
        chart.dispatchAction({
          type: "showTip",
          seriesIndex: 1,
          position: [event.offsetX, event.offsetY],
          dataIndex: dataIndex,
        })
      } else {
        resetChartByTime(chart, chartOption)
      }
    } else {
      if (chartOption.tooltip[0].trigger != "item") {
        resetChartByTime(chart, chartOption)
      }
    }
  }

  const onMousemoveByRun = (chart, event, levelDisplay) => {
    let pointCheck = isPosNeg == true ? pointInGridBDL : pointInGridDefault
    const pointInGrid = chart.convertFromPixel({ seriesIndex: 0 }, [
      event.offsetX,
      event.offsetY,
    ])
    let chartOption = chart.getOption()
    if (
      pointInGrid &&
      pointInGrid[0] >= 1 &&
      pointInGrid[1] <= pointCheck &&
      // && levelDisplay.length == 1
      options.xAxis.data
    ) {
      const dataIndex = Math.round(pointInGrid[0]) // Lấy index gần nhất
      const xAxisData = options.xAxis.data[dataIndex] // Lấy giá trị label
      const axisLabelCustom = chartOption.xAxis[0].axisLabelCustom || [] //giá trị axisLabelCustom đê chỉnh màu

      let labelCustom = axisLabelCustom.find(x => x.value == xAxisData) || {}
      if (
        labelCustom.index == pointInGrid[0] &&
        labelCustom.data &&
        labelCustom.data.length > 0
      ) {
        chartOption.tooltip[0].trigger = "axis"
        chartOption.xAxis[0].axisPointer.show = true

        let lotName = ""
        labelCustom.data.forEach(element => {
          lotName += t("Lot") + ": " + element.lotName + "</br>"
        })
        chartOption.tooltip[0].formatter = () => {
          return lotName
        }
        chart.setOption(chartOption, false)
        chart.dispatchAction({
          type: "showTip",
          seriesIndex: 1,
          position: [event.offsetX, event.offsetY],
          dataIndex: dataIndex,
        })

        // }
        // else {
        //   resetChartByTime(chart, chartOption)
        // }
      } else {
        resetChartByTime(chart, chartOption)
      }
    } else {
      if (chartOption.tooltip[0].trigger != "item") {
        resetChartByTime(chart, chartOption)
      }
    }
  }

  const onMouseoutByTime = chart => {
    let chartOption = chart.getOption()
    resetChartByTime(chart, chartOption)
  }

  const onLegendselectchangedByTime = (params, levelDisplay) => {
    let chart = ref.current.getEchartsInstance()
    const selectedSeries = Object.keys(params.selected).filter(
      key => params.selected[key]
    )
    let chartOption = chart.getOption()
    levelDisplay = selectedSeries
    //check lại vị trí đổi màu, áp dụng cho nhiều mức
    let lengthData = 0
    let dataLevel = levelDisplay.map(x => {
      let l = options.series.find(z => z.name == x)?.data || []
      lengthData = l.length
      return {
        name: x,
        data: l,
      }
    })
    let indexChangeColor = []
    for (let i = 1; i < lengthData - 1; i++) {
      let isChange = false
      let lots = []
      levelDisplay.forEach(element => {
        let dt = dataLevel.find(z => z.name == element)?.data[i]
        if (dt && i > 1 && dt.isFirstLot == true && dt.lotName) {
          isChange = true
        }
        if (dt && dt.lotName) {
          if (lots.findIndex(z => z == dt.lotName) < 0) lots.push(dt.lotName)
        }
      })
      if (isChange == true || lots.length > 1) {
        indexChangeColor.push(i)
      }
    }
    if (indexChangeColor.length > 0) {
      chartOption.xAxis[0].axisLabel.formatter = (value, index) => {
        if (indexChangeColor.includes(index)) return `{blue|${value}}`
        else return `{default|${value}}`
      }
      chartOption.xAxis[0].axisLabel.rich = {
        blue: {
          color: "#0C59CF",
        },
        default: {
          color: "black",
        },
      }
      chart.setOption(chartOption, false)
    }
  }

  const onLegendselectchangedByRun = (params, levelDisplay) => {
    let chart = ref.current.getEchartsInstance()
    const selectedSeries = Object.keys(params.selected).filter(
      key => params.selected[key]
    )
    let chartOption = chart.getOption()
    levelDisplay = selectedSeries

    let lengthData = 0
    let dataLevel = levelDisplay.map(x => {
      let l = options.series.find(z => z.name == x)?.data || []
      lengthData = l.length
      return {
        name: x,
        data: l,
      }
    })
    let indexChangeColor = []
    // let tmp = options.series.find(x => x.name == levelDisplay[0])?.data || []
    let axisLabelCustom = chartOption.xAxis[0].axisLabelCustom || [] //giá trị axisLabelCustom đê chỉnh màu
    axisLabelCustom.forEach(element => {
      if (element.index == 1) {
        // index 1 chỉ hiển thị khi có nhiều lô
        let indexCheck = chartOption.xAxis[0].data.map((x, index) => {
          if (element.value == x) {
            return index
          }
          return -99
        })
        indexCheck = indexCheck.filter(x => x != -99) || []
        //đã có được các vị trí để check lô trong data
        if (indexCheck.length > 0) {
          let dataTMP = []
          indexCheck.forEach(row => {
            levelDisplay.forEach(element => {
              let tmp = dataLevel.find(z => z.name == element)?.data || []
              if (
                dataTMP.findIndex(z => z.lotId == tmp[row].lotId) < 0 &&
                tmp[row].lotId != undefined &&
                tmp[row].lotId != null &&
                tmp[row].lotId != ""
              ) {
                dataTMP.push(tmp[row])
              }
            })
          })
          if (dataTMP.length > 1) {
            //lớn hơn 1 vì ko cho set isFistLot ở vị trí 1
            indexChangeColor.push(element)
            element.data =
              dataTMP.filter(
                x => x.lotId != undefined && x.lotId != null && x.lotId != ""
              ) || []
          }
        }
      } else {
        let indexCheck = chartOption.xAxis[0].data.map((x, index) => {
          if (element.value == x) {
            return index
          }
          return -99
        })
        indexCheck = indexCheck.filter(x => x != -99) || []
        //đã có được các vị trí để check lô torng data
        if (indexCheck.length > 0) {
          let dataTMP = []
          indexCheck.forEach(row => {
            levelDisplay.forEach(element => {
              let tmp = dataLevel.find(z => z.name == element)?.data || []
              if (
                dataTMP.findIndex(z => z.lotId == tmp[row].lotId) < 0 &&
                tmp[row].lotId != undefined &&
                tmp[row].lotId != null &&
                tmp[row].lotId != ""
              ) {
                dataTMP.push(tmp[row])
              }
            })
          })
          if (
            dataTMP.length > 1 ||
            dataTMP.findIndex(x => x.isFirstLot == true) >= 0
          ) {
            indexChangeColor.push(element)
            element.data =
              dataTMP.filter(
                x => x.lotId != undefined && x.lotId != null && x.lotId != ""
              ) || []
          }
        }
      }
    })

    // tmp.forEach((dt, index) => {
    //   if (dt && index > 1 && dt.isFirstLot == true && dt.lotName) {
    //     indexChangeColor.push(index)
    //   }
    // });
    if (indexChangeColor.length > 0) {
      chartOption.xAxis[0].axisLabel.formatter = (value, index) => {
        if (index === 0 || value !== chartOption.xAxis[0].data[index - 1]) {
          let arrIndex = axisLabelCustom
            .filter(z => z.data && z.data.length > 0)
            .map(x => x.index)
          if (arrIndex.includes(index)) return `{blue|${value}}`
          else return `{default|${value}}`
        } else {
          return ""
        }
      }
      chartOption.xAxis[0].axisLabel.rich = {
        blue: {
          color: "#0C59CF",
        },
        default: {
          color: "black",
        },
      }
      chart.setOption(chartOption, false)
    }
  }

  const renderChart = (title, options, ref) => {
    let levelDisplay = options.legend.data.map(x => x.name)
    return (
      <div className="mb-3">
        <ReactECharts
          option={updateChartTitle(title, options)} // Cập nhật tiêu đề vào option
          ref={ref}
          style={{
            width: "100%",
            height: "400px",
            padding: "0.5px",
            border: "0.5px solid #e8e8e8",
            zoom: window.devicePixelRatio,
          }}
          theme="roma"
          onChartReady={chart => {
            setTimeout(() => {
              if (timeOption == "ByTime") {
                checkLotByTime(chart, levelDisplay)
              } else {
                checkLotByRun(chart, levelDisplay)
              }
            }, 100)
            chart.getZr().on("mousemove", event => {
              if (timeOption == "ByTime") {
                onMousemoveByTime(chart, event, levelDisplay)
              } else {
                onMousemoveByRun(chart, event, levelDisplay)
              }
            })
            chart.getZr().on("mouseout", event => {
              if (timeOption == "ByTime") {
                onMouseoutByTime(chart)
              } else {
                onMouseoutByTime(chart)
              }
            })
          }}
          onEvents={{
            mouseover: params => {
              // Gọi hàm dispatchAction khi hover vào item
              ref.current.getEchartsInstance().dispatchAction({
                type: "showTip",
                seriesIndex: params.seriesIndex,
                dataIndex: params.dataIndex,
                position: "mouse",
              })
            },
            legendselectchanged: params => {
              let chart = ref.current.getEchartsInstance()
              const selectedSeries = Object.keys(params.selected).filter(
                key => params.selected[key]
              )
              let chartOption = chart.getOption()
              levelDisplay = selectedSeries
              if (levelDisplay.length == 0) {
                chartOption.xAxis[0].axisLabel.formatter = (value, index) => {
                  return value
                }

                chart.setOption(chartOption, false)
              } else {
                if (timeOption == "ByTime") {
                  onLegendselectchangedByTime(params, levelDisplay)
                } else {
                  onLegendselectchangedByRun(params, levelDisplay)
                }
              }
            },
          }}
        />
      </div>
    )
  }
  return (
    <React.Fragment>
      <div style={{ overflowY: "auto" }}>
        <div
          style={{
            textAlign: "right",
            marginBottom: "10px",
            paddingTop: "10px",
          }}
        >
          <Check permission={permissionType.Print} resource={RESOURCE}>
            <button
              onClick={toggleChart}
              style={{
                padding: "5px 10px",
                cursor: "pointer",
                border: "2px solid #ffd444",
                borderRadius: "8px",
                backgroundColor: "#ffffff",
              }}
            >
              <i className="fa fa-print" style={{ color: "#d69f27" }}></i>{" "}
              <span
                style={{
                  color: "#d69f27",
                  fontWeight: "bold",
                  marginLeft: "5px",
                }}
              >
                {t("chartiQCPage:Print")}
              </span>
            </button>
          </Check>
        </div>
        <div className="mt-2" style={{ width: "100%" }}>
          {showBothCharts &&
            (levelsChartOption !== "one" ||
              (levelsChartOption === "one" && levels != "default")) &&
            checkDisplay(options) &&
            renderChart(`${t("Chart")} PXN`, options, ref)}
          {showBothCharts &&
            (levelsChartOption !== "one" ||
              (levelsChartOption === "one" && levels != "default")) &&
            checkDisplay(optionsNSX) &&
            renderChart(`${t("Chart")} NSX`, optionsNSX, refNSX)}
          {!showBothCharts &&
            (levelsChartOption !== "one" ||
              (levelsChartOption === "one" && levels != "default")) &&
            checkDisplay(options) &&
            renderChart(`${t("Chart")} PXN`, options, ref)}

          {!showBothCharts &&
            levelsChartOption === "one" &&
            Object.keys(oneLevelDataset).length > 0 &&
            (levels === "default" || levels === null) && (
              <>
                {checkDisplay(options1) &&
                  renderChart(
                    `${t("Chart")} PXN: ${t("Level")} 1`,
                    options1,
                    ref1
                  )}
                {checkDisplay(options2) &&
                  renderChart(
                    `${t("Chart")} PXN: ${t("Level")} 2`,
                    options2,
                    ref2
                  )}
                {checkDisplay(options3) &&
                  renderChart(
                    `${t("Chart")} PXN: ${t("Level")} 3`,
                    options3,
                    ref3
                  )}
                {checkDisplay(options4) &&
                  renderChart(
                    `${t("Chart")} PXN: ${t("Level")} 4`,
                    options4,
                    ref4
                  )}
                {checkDisplay(options5) &&
                  renderChart(
                    `${t("Chart")} PXN: ${t("Level")} 5`,
                    options5,
                    ref5
                  )}
              </>
            )}
          {showBothCharts &&
            levelsChartOption === "one" &&
            Object.keys(oneLevelDataset).length > 0 &&
            (levels === "default" || levels === null) && (
              <>
                {checkDisplay(options1) &&
                  renderChart(
                    `${t("Chart")} PXN: ${t("Level")} 1`,
                    options1,
                    ref1
                  )}
                {checkDisplay(optionsNSX1) &&
                  renderChart(
                    `${t("Chart")} NSX: ${t("Level")} 1`,
                    optionsNSX1,
                    refNSX1
                  )}
                {checkDisplay(options2) &&
                  renderChart(
                    `${t("Chart")} PXN: ${t("Level")} 2`,
                    options2,
                    ref2
                  )}
                {checkDisplay(optionsNSX2) &&
                  renderChart(
                    `${t("Chart")} NSX: ${t("Level")} 2`,
                    optionsNSX2,
                    refNSX2
                  )}
                {checkDisplay(options3) &&
                  renderChart(
                    `${t("Chart")} PXN: ${t("Level")} 3`,
                    options3,
                    ref3
                  )}
                {checkDisplay(optionsNSX3) &&
                  renderChart(
                    `${t("Chart")} NSX: ${t("Level")} 3`,
                    optionsNSX3,
                    refNSX3
                  )}
                {checkDisplay(options4) &&
                  renderChart(
                    `${t("Chart")} PXN: ${t("Level")} 4`,
                    options4,
                    ref4
                  )}
                {checkDisplay(optionsNSX4) &&
                  renderChart(
                    `${t("Chart")} NSX: ${t("Level")} 4`,
                    optionsNSX4,
                    refNSX4
                  )}
                {checkDisplay(options5) &&
                  renderChart(
                    `${t("Chart")} PXN: ${t("Level")} 5`,
                    options5,
                    ref5
                  )}
                {checkDisplay(optionsNSX5) &&
                  renderChart(
                    `${t("Chart")} NSX: ${t("Level")} 5`,
                    optionsNSX5,
                    refNSX5
                  )}
              </>
            )}
        </div>
      </div>
      <ChartModal
        formEl={formEl}
        modal={modalChart}
        isEdit={false}
        onValidSubmit={captureChartImage}
        toggle={toggleChart}
        data={chartRef}
        dataCheckRef={dataCheckRef}
      />
    </React.Fragment>
  )
}

TabChart.propTypes = {
  showBothCharts: PropTypes.bool,
  showUnvalidatedPoint: PropTypes.bool,
  levelsChartOption: PropTypes.string,
  levels: PropTypes.string,
  generalData: PropTypes.object,
  loadingDatapoints: PropTypes.bool,
  error: PropTypes.string,
}

const mapStateToProps = ({ qcDatapoints }) => ({
  loadingDatapoints: qcDatapoints.loadingDatapoints,
  error: qcDatapoints.error,
  datapoints: qcDatapoints.datapoints,
})

const mapDispatchToProps = dispatch => ({})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withTranslation(["chartiQCPage", "common"])(TabChart)))
