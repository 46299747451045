import { AvForm } from "availity-reactstrap-validation"

import { ModalBody, ModalFooter } from "reactstrap"

import {
    CustomButton,
    CustomModal,
    CustomSelect,
    CustomSelectAsync,
    showToast
} from "components/Common"

//i18n
import CustomErrorModal from "components/Common/Modals/CustomErrorModal"
import { parameterCode } from "constant"
import { getQCLotListOrigin, getTestIQCList } from "helpers/app-backend"
import { copyQCLotTest, getListInstrumentIdByLotId, GetQCLotTestList } from "helpers/app-backend/IQC/QCLotTest_backend_helper"
import { getInstrumentListByIns } from "helpers/app-backend/machines_backend_helper"
import { getInvalidMessageI18n } from "helpers/utilities"
import React, { useEffect, useReducer, useRef, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import AddLotTestMachineTable from "./AddLotTestMachineTable"

let oldLevelFrom = null
const AddLotTestMachine = ({
    modal,
    toggle,
    data,
    t,
    onValidSubmit,
    onUpdateDone
}) => {
    const ref = useRef();
    const refData = useRef()
    const [config, setConfig] = useState(data || {})
    const [disaleSubmit, setDisableSubmit] = useState(true)
    const [dataLevelFrom, setDataLevelFrom] = useState([])
    const [dataTestFrom, setDataTestFrom] = useState([])
    const [dataTestTo, setDataTestTo] = useState([])
    const [dataLevelTo, setDataLevelTo] = useState([])
    const [configHeader, setConfigHeader] = useState({})
    const [modalError, setModalError] = useState(false)
    const [loadingLotFrom, setLoadingLotFrom] = useState(false)
    const [loadingLotTo, setLoadingLotTo] = useState(false)
    const [dataLotFrom, setDataLotFrom] = useState([])
    const [dataLotTo, setDataLotTo] = useState([])
    const [msgErr, setMsgErr] = useState('')
    const [model, setModel] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            insId: '',
            lotId: '',
            levels: '',
            testCode: '',
        }
    )
    useEffect(() => {
        if (modal == false) {
            setConfig(data)
            setModel({
                insId: '',
                lotId: '',
                levels: '',
                testCode: '',
            })
            ref?.current?.ClearData()
        }
    }, [modal])

    useEffect(() => {
        if (config.lotId) {
            setDataLotTo([{ value: config.lotId, label: config.lotName }])
        }
    }, [])


    useEffect(() => {
        setConfig({ ...data, levels: '' })
        setConfigHeader({ ...data })
    }, [data])

    const getLevels = async (lotId, isFrom = false) => {
        let res = await getListInstrumentIdByLotId(lotId || '')
        if (res && res.levels.length > 0) {
            isFrom ? setDataLevelFrom(res.levels.map(x => ({ value: x, label: x }))) : setDataLevelTo((res.levels.map(x => ({ value: x, label: x }))))
        }
        else {
            isFrom ? setDataLevelFrom([]) : setDataLevelTo([])
        }
    }

    useEffect(() => {
        getLevels(config.lotId)
        if (config.lotId && config.lotId != '' && config.lotId != null) {
            fetchTestIQCList(config.lotId)
        }
    }, [config.lotId])
    //model.lotId
    useEffect(() => {
        getLevels(model.lotId, true)
        if (model.lotId && model.lotId != '' && model.lotId != null) {
            fetchTestIQCList(model.lotId, true)
        }
    }, [model.lotId])

    const fetchTestIQCList = async (lotId, isFrom = false) => {
        let query = { size: 0, lotId }
        const res = await getTestIQCList(query)
        let data = []
        if (res.data)
            data = res.data.map(_item => {
                _item.value = _item.testCode
                _item.label = _item.testCode + " - " + _item.testName
                return _item
            })
        isFrom ? setDataTestFrom(data || []) : setDataTestTo(data || [])
    }

    const onInSertData = async () => {
        let tmp = {
            insId: model.insId,
            lotId: model.lotId,
        }
        if (model.levels && model.levels != '') {
            tmp.levels = model.levels
        }

        if (model.testCode && model.testCode != '') {
            tmp.testCode = model.testCode
        }
        let res = await GetQCLotTestList(tmp)

        let newData = res || []
        let i = 0;
        if (newData.length > 0) {
            // newData.forEach(element => {
            //     element.machineName = config.machineName,
            //         element.insId = config.instrumentId,
            //         element.lotName = config.lotName,
            //         element.lotId = config.lotId,
            //         element.id =  `${new Date().getTime()}${Math.floor(Math.random() * 1000)}${config.instrumentId}${config.lotId}${element.testCode}`
            // });
            let ins = newData.filter(x => x.insId != null && x.insId != '').map(x => x.insId)
            if (ins.length > 0) {
                let resIns = await getInstrumentListByIns({ instrumentId: ins })
                if (resIns.data && resIns.data.length > 0) {
                    newData.forEach(element => {
                        element.machineName = resIns.data.find(x => x.insID == element.insId)?.name || ''
                    });
                }
            }
        }
        res = newData
        // if (config.levels && config.levels != '') {
        //     res = res.filter(x => x.levels == config.levels)
        // }
        if (config.testCode && config.testCode != '') {
            res = res.filter(x => x.testCode == config.testCode)
        }
        let isClearOldData = false
        if (oldLevelFrom == null || oldLevelFrom != model.levels) {
            isClearOldData = true
        }
        oldLevelFrom = model.levels
        if (res.length == 0) {
            // showErrToast(
            //     `${t("No records found")}`
            // )
            ref.current?.UpdateData([], isClearOldData)
        }
        else {
            ref.current?.UpdateData(res, isClearOldData)
        }
    }

    const handleValidSubmit = async () => {
        let res = ref.current?.onGetData()
        res.data = res.data.map(x => ({
            ...x, insId: config.instrumentId, lotId: config.lotId
        }))
        let response = await copyQCLotTest(res)

        if (response) {
            ref.current?.onCopySucceeded(response.listSuccess || [])
            ref.current?.ResetCheckBox()
            let title = "";
            if (response.succeedMsg && response.succeedMsg.length > 0) {
                // title += response.succeedMsg + "</br>"
                showToast(response.succeedMsg)
                onUpdateDone && onUpdateDone()
            }
            if (response.errMsg && response.errMsg.length > 0) {
                title += response.errMsg + "</br>";
                setMsgErr(title)
                setModalError(true)
            }
            else {
                // showToast(
                //     `${t("message:Succeeded", {
                //         field: `${t("Copy")}`,
                //     })}`
                // )
            }
        }
    }
    const fetchQCLotLisFromLotTest = async (isFrom, keySearch, insID) => {
        let query = { size: 15 }
        if (insID == undefined || insID == null || insID == '') {
            isFrom ? setDataLotFrom([]) : setDataLotTo([])
            return
        }
        isFrom ? setLoadingLotFrom(true) : setLoadingLotTo(true)
        query = { ...query, search: keySearch || '', machine: insID }
        const res = await getQCLotListOrigin(query)
        let data = []
        if (res.data) {
            data = res.data.map(_item => {
                _item.value = _item.lotId
                _item.label = _item.lotName
                return _item
            })
        }
        isFrom ? setDataLotFrom(data || []) : setDataLotTo(data || [])
        isFrom ? setLoadingLotFrom(false) : setLoadingLotTo(false)
    }

    return (
        <React.Fragment>
            <CustomModal modal={modal} title={t("Add Lot Test Machine")} onToggle={() => {
                toggle()
                ref?.current?.ClearData()
            }} size="xl" customClass={"modal-90vw management-qc-right management-qc-right-modal"} centered={false}>
                <ModalBody>
                    <div className="copy-lot-test-modal">
                        <div className="row">
                            <div className="col-auto mb-3 modal-add-lot-test-title align-self-center">
                                {t("Lot")}:
                                <span>{configHeader.lotId} - {configHeader.lotName} - {t("Levels")}: {configHeader.levels}</span>
                                <br />
                                {t("Machine")}: {configHeader.machineName}
                            </div>
                            <div className="col">
                                <AvForm
                                    onValidSubmit={(e, v) => {
                                        // if (config.lotId != '' && config.lot != '') {
                                        //     onInSertData()
                                        // }
                                        onInSertData()
                                    }}
                                >
                                    <div className="d-flex justify-content-end align-items-center gap-1 mb-1">
                                        <div className="d-flex gap-1 text-center" style={{ width: '100px' }}>
                                            <i className="fas fa-exclamation-circle text-primary align-self-center"></i>
                                            <div>{t("Import form Lot")}</div>
                                        </div>
                                        <div style={{ width: '150px' }}>
                                            <CustomSelect
                                                name="insIdFrom"
                                                label={""}
                                                placeholder={t("Machine")}
                                                value={model.insId || ''}
                                                showID
                                                code={parameterCode.INSTRUMENT_QC}
                                                onChange={(e, vals, a, items) => {
                                                    setModel({ insId: vals[0] || '' })
                                                    fetchQCLotLisFromLotTest(true, '', vals[0] || '')
                                                }}
                                                required
                                                errorMessage={getInvalidMessageI18n(t, "Machine")}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                            />
                                        </div>
                                        <div style={{ width: '250px' }}>
                                            <CustomSelect
                                                name="lotIdFrom"
                                                label={""}
                                                placeholder={t("Lot")}
                                                value={model.lotId || ''}
                                                options={dataLotFrom || []}
                                                isLoading={loadingLotFrom}
                                                onChange={(e, vals, a, items) => {
                                                    setModel({ lotId: vals[0] || '', lotName: items[0]?.label || '' })
                                                    if (vals[0] == undefined) {
                                                        fetchQCLotLisFromLotTest(true, '', model.insId)
                                                    }
                                                }}
                                                onDebounce={(val) => {
                                                    if (val != '') {
                                                        fetchQCLotLisFromLotTest(true, val, model.insId)
                                                    }
                                                }}
                                                required={true}
                                                errorMessage={getInvalidMessageI18n(t, "Lot")}
                                            />
                                        </div>
                                        <div style={{ width: '100px' }}>
                                            <CustomSelectAsync
                                                name="levelsFrom"
                                                label={""}
                                                placeholder={t("Level")}
                                                value={model.levels || ''}
                                                options={dataLevelFrom || []}
                                                // code={parameterCode.LEVEL_QC}
                                                onChange={(e, vals, a, items) => {
                                                    setModel({ levels: vals[0] || '' })
                                                }}
                                            />
                                        </div>
                                        <div style={{ width: '250px' }}>
                                            <CustomSelect
                                                name="testCodeFrom"
                                                placeholder={t("Search Test Code, Name")}
                                                value={""}
                                                valueName={""}
                                                // code={parameterCode.TEST_CODE_QC}
                                                options={dataTestFrom}
                                                label={""}
                                                onChange={(e, vals, a, items) => {
                                                    setModel({ testCode: vals[0] || '' })
                                                }}
                                            />
                                        </div>
                                        <CustomButton
                                            text={t("Import Data")}
                                            type="submit"
                                            // onClick={() => {
                                            //     onInSertData()
                                            // }}
                                            color="primary"
                                            outline
                                            style={{ width: '120px', whiteSpace: 'nowrap' }}
                                        />
                                    </div>
                                </AvForm>
                                <AvForm
                                    ref={refData}
                                    onValidSubmit={(e, v) => {
                                        handleValidSubmit()
                                    }}
                                >
                                    <div className="d-flex justify-content-end align-items-center gap-1">
                                        <div className="text-center" style={{ width: '100px' }}>
                                            <div>{t("To Lot")}</div>
                                        </div>
                                        <div style={{ width: '150px' }}>
                                            <CustomSelect
                                                name="insIdTo"
                                                label={""}
                                                placeholder={t("Machine")}
                                                value={config.instrumentId || ''}
                                                code={parameterCode.INSTRUMENT_QC}
                                                onChange={(e, vals, a, items) => {
                                                    setConfig({
                                                        ...config, instrumentId: vals[0] || '',
                                                        machineName: items[0]?.name || '',
                                                        lotId: '', lotName: ''
                                                    })
                                                    fetchQCLotLisFromLotTest(false, '', vals[0] || '')
                                                }}
                                                showID
                                                required
                                                errorMessage={getInvalidMessageI18n(t, "Machine")}
                                                validate={{
                                                    required: { value: true },
                                                }}
                                            />
                                        </div>
                                        <div style={{ width: '250px' }}>
                                            <CustomSelect
                                                name="lot"
                                                placeholder={t("Lot")}
                                                label={""}
                                                value={config.lotId || ""}
                                                options={dataLotTo || []}
                                                isLoading={loadingLotTo}
                                                required={true}
                                                errorMessage={getInvalidMessageI18n(t, "Lot")}
                                                onChange={(e, vals, a, items) => {
                                                    setConfig({ ...config, lotId: vals[0] || '', lotName: items[0]?.label || '' })
                                                    if (vals[0] == undefined) {
                                                        fetchQCLotLisFromLotTest(false, '', config.instrumentId)
                                                    }
                                                }}
                                                onDebounce={(val) => {
                                                    if (val != '') {
                                                        fetchQCLotLisFromLotTest(false, val, config.instrumentId)
                                                    }
                                                }}
                                            />
                                        </div>
                                        <div style={{ width: '100px' }}>
                                            <CustomSelectAsync
                                                name="levelsFrom"
                                                label={""}
                                                placeholder={t("Level")}
                                                value={config.levels || ''}
                                                // code={parameterCode.LEVEL_QC}
                                                options={dataLevelTo || []}
                                                onChange={(e, vals) => {
                                                    setConfig({ ...config, levels: vals[0] || '' })
                                                }}
                                            />
                                        </div>
                                        <div style={{ width: '250px' }}>
                                            <CustomSelect
                                                name="testCodeTo"
                                                placeholder={t("Search Test Code, Name")}
                                                value={""}
                                                valueName={""}
                                                // code={parameterCode.TEST_CODE_QC}
                                                options={dataTestTo}
                                                label={""}
                                                onChange={(e, vals) => {
                                                    setConfig({ ...config, testCode: vals[0] || '' })
                                                }}
                                            />
                                        </div>
                                        <div style={{ width: '120px', textAlign: 'right' }}>
                                            <CustomButton
                                                color="secondary"
                                                outline
                                                onClick={() => {
                                                    ref?.current?.ResetData()
                                                }}

                                            >
                                                <i style={{ fontSize: 16 }} className={`fas fa-sync-alt `}></i>
                                            </CustomButton>
                                        </div>
                                    </div>
                                </AvForm>
                            </div>
                        </div>
                        <div className="w-100 copy-lot-test-modal-table">
                            <AddLotTestMachineTable
                                config={config}
                                instrumentId={config.instrumentId}
                                model={{}}
                                data={[]}
                                ref={ref}
                                onChangeRow={(val) => {
                                    setDisableSubmit(val)
                                }}
                            />
                        </div>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <CustomButton
                        text={t("common:Cancel")}
                        type="button"
                        onClick={() => {
                            toggle()
                            ref?.current?.ClearData()
                        }}
                        data-dismiss="modal"
                        className="button-width"
                    />

                    <CustomButton
                        text={t("common:Save")}
                        type="submit"
                        color="primary"
                        disabled={disaleSubmit}
                        onClick={() => { refData?.current?.submit() }}
                        className="save-user button-width"
                        isEdit
                    />
                </ModalFooter>
            </CustomModal>
            <CustomErrorModal onToggle={() => { setModalError(x => !x) }}
                msg={msgErr}
                modal={modalError}
                titleFooter={t("common:Close")}
                title={t("message:Error details")}
            />
        </React.Fragment >
    )
}

AddLotTestMachine.defaultProps = {}

const mapStateToProps = ({ testResult }) => ({
})

const mapDispatchToProps = dispatch => ({
    // onGetNotifications: payload => dispatch(getNotifications(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withTranslation(["managementQCPage", "common"])(AddLotTestMachine))
