import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { AvForm } from "availity-reactstrap-validation"
import { CustomBootstrapTable, CustomDatePicker } from "components/Common"
import { ModuleIds } from "constant"
import { convertDateFormat, indexCalculator } from "helpers/utilities"
import { withTranslation } from "react-i18next"
import ReactTooltip from "react-tooltip"
import FilterForm from "./FilterForm"

const RESOURCE = ModuleIds.AnalyticalProcess

const QCResultTable = ({
  onSelect,
  onSelectAll,
  tests,
  onSearch,
  onSort,
  onRefresh,
  onPageChange,
  paging,
  onSizePerPageChange,
  onSubmitFilter,
  loading,
  isSelected,
  t,
  updatedTime,
  onChangeModel
}) => {
  const [data, setData] = useState([])
  const [model, setModel] = useState({
    search: "",
    testCode: "",
    instrument: "",
    fromDate: convertDateFormat(new Date(), "YYYY-MM-DD 00:00"),
    toDate: convertDateFormat(new Date(), "YYYY-MM-DD HH:mm"),
  })
  const searchQuery = useSelector(state => state.qcResult.searchQuery)
  const defaultSortValue = { dataField: "requestDate", order: "desc" }
  const onResetHandler = () => {
    const initModel = {
      search: "",
      testCode: "",
      instrument: "",
      fromDate: convertDateFormat(new Date(), "YYYY-MM-DD 00:00"),
      toDate: convertDateFormat(new Date(), "YYYY-MM-DD HH:mm"),
    }
    setModel(initModel)
  }

  useEffect(() => {
    setModel(searchQuery)
  }, [searchQuery])

  useEffect(() => {
    onChangeModel && onChangeModel(model)
  }, [model])

  useEffect(() => {
    setData(tests.map(x => ({
      ...x, inRequestId: x.id, id: `${x.id}${x.instrumentTestCode}`
    })))
  }, [tests])


  const columns = [
    {
      dataField: "id",
      text: "",
      hidden: true,
      formatter: (cellContent, test, index) => {
        return cellContent
      },
    },
    {
      dataField: "SEQ",
      text: "#",
      headerStyle: { width: "50px" },
      formatter: (cellContent, test, index) => {
        return indexCalculator(paging.page, paging.size, index + 1)
      },
    },
    {
      dataField: "requestDate",
      text: t("Date"),
      sort: true,
      style: { color: "#556ee6" },
      formatter: (cellContent, test, index) => {
        return (
          convertDateFormat(cellContent, "DD-MM-YYYY")
        )
      },
    },
    {
      dataField: "qcsid",
      text: t("SID"),
      sort: false,
      style: { color: "#556ee6", width: "250px" },
      formatter: (cellContent, test, index) => {
        const formattedContent = /^[0-9]/.test(cellContent) // Kiểm tra nếu bắt đầu bằng số
          ? cellContent.toString().padStart(10, '0') // Thêm '0' vào đầu cho đủ 10 ký tự
          : cellContent; // Giữ nguyên nếu không bắt đầu bằng số
        return (
          <span className="sid-custom">{formattedContent}</span>
        )
      },
    },
    {
      dataField: "sidqcChg",
      text: t("SID Change"),
      sort: false,
      style: { color: "#556ee6", width: "250px" },
      formatter: (cellContent, test, index) => {
        return (
          <span className="sid-custom">{cellContent}</span>
        )
      },
    },
    {
      dataField: "testCode",
      text: t("Test Code"),
      sort: false,
    },
    {
      dataField: "testName",
      text: t("Test Name"),
      sort: false,
      formatter: (cellContent, test, index) => {
        return (
          <span>{test.testCode === '???' ? '--' : cellContent}</span>
        )
      },
    },
    {
      dataField: "instrumentTestCode",
      text: t("Ins Code"),
      sort: false,
      formatter: (cellContent, test, index) => {
        return (
          <span>{cellContent}</span>
        )
      },
    },
    {
      dataField: "result",
      text: t("Result"),
      formatter: (cellContent, item, index) => {
        const resultData = item.result && item.resultText
          ? `${item.result}/${item.resultText}`
          : item.result || item.resultText || cellContent;

        return <b>{resultData}</b>
      },
    },
    {
      dataField: "unit",
      text: t("Unit"),
      formatter: (cellContent, item, index) => {
        return <b>{item?.unit ? cellContent : "--"}</b>
      },
    },
    {
      dataField: "instrumentId",
      text: t("Ins Id"),
      sort: true,
      style: { fontWeight: 'bold' },
      formatter: (cellContent, test, index) => {
        return <>
          {test.instrumentTestCode ? (
            <span data-tip data-for={test.instrumentTestCode}>
              {cellContent}
            </span>
          ) : "---"}
          {test.instrumentTestCode && (
            <ReactTooltip id={test.instrumentTestCode} place="top" effect="solid">
              <span>{test.instrumentName}</span>
            </ReactTooltip>
          )}
        </>
      },
    },
    {
      dataField: "createdDate",
      text: t("Result Time"),
      formatter: (cellContent, item, index) => convertDateFormat(cellContent, "HH:mm DD-MM-YYYY"),
    },
  ]

  return (
    <div className="table-connector-log">
      <CustomBootstrapTable
        columns={columns}
        search
        placeholder={t('Search QC')}
        data={data}
        paging={paging}
        onSelect={onSelect}
        onSelectAll={onSelectAll}
        onSearch={onSearch}
        onSort={onSort}
        isSelected={isSelected}
        onRefresh={onRefresh}
        filterForm={() => <FilterForm model={model} />}
        onReset={onResetHandler}
        onPageChange={onPageChange}
        onSizePerPageChange={onSizePerPageChange}
        onSubmitFilter={onSubmitFilter}
        searchText={model.search}
        loading={loading}
        updatedTime={updatedTime}
        resource={RESOURCE}
        keyField="id"
        defaultSortValue={defaultSortValue}
        showSelectRow={true}
        customButtonTable={() => (
          <AvForm>
            <div className="d-flex">
              <div style={{ marginLeft: "5px" }}>
                <CustomDatePicker
                  name="fromDate"
                  placeholder={"YYYY-MM-DD HH:mm"}
                  value={
                    convertDateFormat(model?.fromDate || new Date(), "00:00 DD-MM-YYYY")
                  }
                  enableTime={true}
                  dateFormat={"H:i d-m-Y"}
                  onChangeHandler={e => {
                    if (e.time.length > 0) {
                      onChangeModel({
                        fromDate: convertDateFormat(e.time[0], "YYYY-MM-DD HH:mm")
                      })
                      setTimeout(() => {
                        setModel({
                          ...model,
                          fromDate: convertDateFormat(e.time[0], "YYYY-MM-DD HH:mm")
                        })
                      }, 100)
                    } else {
                      onChangeModel({
                        fromDate: convertDateFormat(new Date(), "00:00 DD-MM-YYYY")
                      })
                    }
                  }
                  }
                  onKeyDown={e => {
                    if (e.keyCode == 13) {
                      onSearch(model.search)
                    }
                  }}
                  maxDate={new Date()}
                />
              </div>
              <div style={{ marginLeft: "5px" }}>
                <CustomDatePicker
                  name="toDate"
                  placeholder={"YYYY-MM-DD HH:mm"}
                  value={convertDateFormat(model?.toDate || new Date(), "HH:mm DD-MM-YYYY")}
                  enableTime={true}
                  dateFormat={"H:i d-m-Y"}
                  onChangeHandler={e => {
                    if (e.time.length > 0) {
                      onChangeModel({
                        toDate: convertDateFormat(e.time[0], "YYYY-MM-DD HH:mm")
                      })
                      setTimeout(() => {
                        setModel({
                          ...model,
                          toDate: convertDateFormat(e.time[0], "YYYY-MM-DD HH:mm")
                        })
                      }, 100)
                    } else {
                      onChangeModel({
                        toDate: convertDateFormat(new Date(), "HH:mm DD-MM-YYYY")
                      })
                    }
                  }
                  }
                  onKeyDown={e => {
                    if (e.keyCode == 13) {
                      onSearch(model.search)
                    }
                  }}
                  maxDate={new Date()}
                />
              </div>
            </div>
          </AvForm>
        )}
      />
    </div>
  )
}

export default withTranslation(["qcResultPage"])(QCResultTable)
