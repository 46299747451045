const textFieldTypes = {
  TEXT: "TEXT",
  TEXTAREA: "TEXTAREA",
  CHECKBOX: "CHECKBOX",
  SINGLE: "SINGLE",
  MULTIPLE: "MULTIPLE",
  DATE: "DATE",
  NUMBER: "NUMBER",
  USER: "USER",
  DEPARTMENT: "DEPARTMENT",
  COMPANY: "COMPANY",
  PROFILE: "PROFILE",
  GROUP: "GROUP",
  TAGS: "TAGS",
  LABEL: "LABEL",
  RADIO: "RADIO",
  INTEGRATEDAPPROVAL: "INTEGRATEDAPPROVAL",
  SWITCH: "SWITCH",
  UPLOAD: "UPLOAD",
  WORKTIME: "WORKTIME",
  WORKHOLIDAY: "WORKHOLIDAY",
  INPUTNUMBER: "INPUTNUMBER",
  RESULTABNORMAL: "RESULTABNORMAL",
  BUILDVERSION: "BUILDVERSION",
  BUTTONGROUP: "BUTTONGROUP",
  AREA: "AREA",
  PRINTERSELECT: "PRINTERSELECT",
  SELECTBOX: "SELECTBOX",
  FILETYPE: "FILETYPE",
  HISCONNECT: "HISCONNECT",
  CUSTOMSELECT: "CUSTOMSELECT",
  EMAIL: "EMAIL",
}

const statusTypes = {
  ACTIVE: "Active",
  INACTIVE: "Inactive",
}

const chartTypes = {
  PXN: "PXN",
  NSX: "NSX",
}

const parameterCode = {
  PARAMETER_TYPE: "L001",
  LANGUAGE_CODE: "L002",
  COUNTRY_CODE: "L003",
  PROVINCE_CODE: "L004",
  DISTRICT_CODE: "L005",
  WARD_CODE: "L006",
  DEPARTMENT_TYPE_CODE: "L007",
  USER_STATUS_CODE: "L008",
  TITLE_CODE: "L009",
  PARTY_TYPE_CODE: "L013",
  FIELD_CODE: "L014",
  FIELD_TYPE: "L015",
  CONTACT_ROLE_CODE: "L016",
  ADDRESS_TYPE: "L017",
  COMPANIES: "COMPANIES",
  DEPARTMENTS: "DEPARTMENTS",
  ROLES: "ROLES",
  USERS: "USERS",
  USERS_ROLE_PHYSICIAN: "USERS_ROLE_PHYSICIAN",
  USERS_Without_Error: "USERS_Without_Error",
  PATIENTS: "PATIENTS",
  PROFILE: "PROFILE", // PARTIES
  PROFILES: "PROFILES", // PROFILE Management Organization
  PROFILES_Individual: "PROFILES_Individual",
  PROFILES_Organization: "PROFILES_Organization",
  PARAMETER: "PARAMETER",
  ORGANIZATIONS: "ORGANIZATIONS",
  ORGANIZATIONS_CODE: "ORGANIZATIONS_CODE",
  ORGANIZATIONS_CODE_SEND: "ORGANIZATIONS_CODE_SEND",
  PHYSICIANS: "PHYSICIANS",
  LAB01: "Lab01",
  COMMON_PHYSICIANS: "COMMON_PHYSICIANS",
  INDIVIDUALS: "INDIVIDUALS",
  TEST_TYPES: "L101",
  TEST_CATEGORIES: "L103",
  SAMPLE_TYPES: "L102",
  TESTPROFILE_TYPE_CODE: "L105",
  TESTPROFILE_CATEGORY_CODE: "L103",
  TESTPROFILE_SAMPLE_TYPE_CODE: "L102",
  TESTPROFILE: "TESTPROFILE",
  TESTPROFILE_CODE: "TESTPROFILE_CODE",
  TESTPROFILE_CODE_CUSTOM: "TESTPROFILE_CODE_CUSTOM",
  TEST: "TEST",
  TEST_CODE: "TEST_CODE",
  TEST_CODE_QC: "TEST_CODE_QC",
  TESTPROFILE_TEST: "TESTPROFILE_TEST",
  TESTPROFILE_SUB_CATEGORY_CODE: "L104",
  INSTRUMENT_TYPES: "L109",
  HISCONNECTOR: "HISCONNECTOR",
  HISCONNECTOR_LIST: "HISCONNECTOR_LIST",
  HISCONNECTOR_TRUE_LIST: "HISCONNECTOR_TRUE_LIST",
  PROTOCOL: "L109",
  CONNECTION_TYPE: "L110",
  HIS_CODE: "H",
  MAPPING_CODE: "M",
  HIS_MAPPING_KEY: "HIS_MAPPING_KEY",
  HIS_MAPPING_KEY_WITH_LANG: "HIS_MAPPING_KEY_WITH_LANG",
  HIS_MAPPING_KEY_WITH_LANG_PAGE: "HIS_MAPPING_KEY_WITH_LANG_PAGE",
  LIS_MAPPING_KEY: "LIS_MAPPING_KEY",
  LIS_PROFILE_MAPPING_KEY: "LIS_PROFILE_MAPPING_KEY",
  INSURANCE_KEY: "INSURANCE_KEY",
  INSTRUMENT: "INSTRUMENT",
  INSTRUMENT_QC: "INSTRUMENT_QC",
  INSTRUMENT_TRANSLATOR: "INSTRUMENT_TRANSLATOR",
  DELIVERY: "DELIVERY",
  GENDER: "L018",
  AGE_TYPE: "L108",
  ADDITIONAL_CONFIG: "L106",
  RESULT_TIME_EXTRA_CODE: "L107",
  ALL_PARAMETER: "ALL_PARAMETER",
  ALL_GROUP: "ALL_GROUP",
  REPORT_TYPE: "L201",
  REPORT_CATEGORY: "L202",
  REPORT_TEMPLATE: "REPORT_TEMPLATE",
  TESTREQUEST_SAMPLE_QUALITY: "L124",
  BILLING_TYPE: "L125",
  TESTREQUEST_STATE_KEY: "L123",
  TESTRESULT_STATE_KEY: "L128",
  ACCESSION_NUMBER: "L111",
  ACCESSION_NUMBER_TYPE: "L112",
  DELIVERY_TYPE_CODE: "L121",
  SAMPLE_QUALITY: "L124",
  INSTRUMENT_RESULT_STATUS: "L126",
  DIAGNOSIS_KEY: "L122",
  RESULT_TEXT: "L132",
  MACHINE_NAME: "L133",
  TEST_RESULT_FIELD_DISPLAY_KEY: "L203",
  SAMPLES_FOR_DELIVERY: "SAMPLES_FOR_DELIVERY",
  SAMPLES_SELECT_FOR_DELIVERY: "SAMPLES_SELECT_FOR_DELIVERY",
  TESTPROFILE_TEST_NOT_IN_LIST: "TESTPROFILE_TEST_NOT_IN_LIST",
  SAMPLES_FOR_TEST_DELIVERY: "SAMPLES_FOR_TEST_DELIVERY",
  DELIVERY_MODAL: "DELIVERY_MODAL",
  REASON_KEY: "L127",
  PRINTER: "PRINTER",
  BARCODE: "BARCODE",
  SERVICE_TYPE: "L125",
  DELIVERY_TYPE_CODE_ABD: "DELIVERY_TYPE_CODE_ABD",
  PATIENTS_DELIVERY: "PATIENTS_DELIVERY",
  DATE_TYPE_RESULT_INPUT_LIST: "DATE_TYPE_RESULT_INPUT_LIST",
  TEST_REQUEST_LIST: "TEST_REQUEST_LIST",
  PATIENTS_BY_CONDITION: "PATIENTS_BY_CONDITION",
  NOTIFY_TYPE: "L130",
  NOTIFY_STATUS: "L129",
  RULE_ACTION: "RULE_ACTION",
  RULE_TAT_CONDITION: "L162",
  PROFILEANDTEST_CODE: "PROFILEANDTEST_CODE",
  INSURANCE_LANGUAGE_MAPPING: "ins",
  AREA: "L400",
  INSURANCE_TYPE: "L125",
  INSURANCE_MAPPING_COMMON: "COMMON_INS",
  BUSSINESS_TYPE: "L403",
  TIME_TYPE: "L164",
  METHOD: "L420",
  QUALITY: "L421",
  COMMENT: "L422",
  RECOMMENT: "L423",
  METHOD_TEST: "L501",
  INSTRUMENT_TRANSLATOR: "L424",
  PHYSICIANS_PATHOLOGY: "L425",
  INSTRUMENT_EXPRESSION: "L426",
  PREFIX_ACCESS_NUMBER: "L428",
  INSTRUMENT_TRANSLATOR_TESTCODE: "INSTRUMENT_TRANSLATOR_TESTCODE",
  INSTRUMENT_LA_TESTCODE: "INSTRUMENT_LA_TESTCODE",
  GROUP_PATIENT_ORGANIZATION: "GROUP_PATIENT_ORGANIZATION",
  PARTNER_ORGANIZATION: "PARTNER_ORGANIZATION",
  METHOD_DELIVERY: "S431",
  INSTRUMENT_ID: "INSTRUMENT_ID",
  TEST_SEND_STATE: "L433",
  REASON_CANCEL: "L429",
  DISSECTOR: "L444",
  LOT_GROUP_QC: "L443",
  LEVEL_QC: "L445",
  QC_LOT_LIST_FROM_LOT_TEST: "QC_LOT_LIST_FROM_LOT_TEST",
  QC_LOT_LIST_FROM_LOT_TEST_FULL_LABEL: "QC_LOT_LIST_FROM_LOT_TEST_FULL_LABEL",
  QC_LOT_LIST_FROM_LOT_TEST_NOT_EXPIRED: "QC_LOT_LIST_FROM_LOT_TEST_NOT_EXPIRED",
  SIGMA_RULES: "L446",
  EXPRESSION_FORMULA_IQC: "L447",
  LOT_QC: "LOT_QC",
  LOT_IN_LOT_TEST_QC: "LOT_IN_LOT_TEST_QC",
  WESTGARD_SIGMA: "WESTGARD_SIGMA",
  EXPRESSION_IQC: "EXPRESSION_IQC",
  NUMBER_OF_RUNS_IQC: "L448",
  ERROR_TYPE: "L451",
  QC_APPLICABLE: "L452",
  SOURCE_KPH: "L453",
  QC_LOCATION: "QC_LOCATION",
  QC_RESULT_RULE_CAUSES: "QC_RESULT_RULE_CAUSES",
  QC_RESULT_RULE_ACTIONS: "QC_RESULT_RULE_ACTIONS",
  QC_RESULT_RULE_CHECK_RESULT: "L454",
  QC_RESULT_RULE_RESULT_COLOR: "L457",
  QC_RESULT_STATE_RESULT_COLOR: "L456",
  QC_RESULT_STATUS_RESULT_COLOR: "L455",
  QC_LOT_TEST_LIS_SIGMA: "QC_LOT_TEST_LIS_SIGMA",
  INSTRUMENT_QC: "INSTRUMENT_QC",
  AUTO_VALID_CALCULATION: "L458",
  USERS_QC_RESULT_VALID: "USERS_QC_RESULT_VALID",
  TEST_CODE_QC_CALCULATION: "TEST_CODE_QC_CALCULATION",
  LOT_QC_CALCULATION: "LOT_QC_CALCULATION",
  INSTRUMENT_QC_CHANGESID: "INSTRUMENT_QC_CHANGESID",
  RESULTIQC_STATE: "L450"
}

const resourceType = {
  M2: "M2",
  M3: "M3",
  main: "main",
  Page: "Page",
  Tab: "Tab",
  BasicSetup: "BasicSetup",
  Other: "Other",
  ReportSetup: "Report",
}

const InlineEditType = {
  TESTREQUEST_SAMPLE_COLLECTOR: "TESTREQUEST_SAMPLE_COLLECTOR",
  TESTREQUEST_SAMPLE_TIME: "TESTREQUEST_SAMPLE_TIME",
  CONNECTOR_PROFILE_LIS_CODE: "CONNECTOR_PROFILE_LIS_CODE",
  QC_LOT_TEST_LIS_SIGMA: "QC_LOT_TEST_LIS_SIGMA",
}

const permissionType = {
  C: "Create",
  R: "Read",
  U: "Update",
  D: "Delete",
  E: "Export",
  Confirm: "Confirm",
  Validate: "Validate",
  Signature: "Signature",
  Preview: "Preview",
  Print: "Print",
  CollectedTime: "CollectedTime",
  DeliveryTime: "DeliveryTime",
  ProfileSendUpdate: "ProfileSendUpdate",
  ChangeSID: "ChangeSID",
  ChangeSIDQC: "ChangeSIDQC",
  REV: "REV",
  UpdatePatient: "UpdatePatient",
  UpdateSampleType: "UpdateSampleType",
  OldResult: "OldResult",
  ValidAndSend: "ValidAndSend",
  UpdateResult: "UpdateResult",
  UpdateCollectedTime: "UpdateCollectedTime",
  RunRule: "RunRule",
  ReRun: "ReRun",
  AddRule: "AddRule",
  ApplyRule: "ApplyRule",
  AddLotTest: "AddLotTest",
  ImportExcel: "ImportExcel",
  ViewResult: "ViewResult",
  AddResult: "AddResult",
  ValidResult: "ValidResult",
  CancelValid: "CancelValid",
  CancelResult: "CancelResult",
  DeleteResult: "DeleteResult",
  NormalRange: "NormalRange",
  CancelCollectSample: "CancelCollectSample",
  CopyLotQC: "CopyLotQC",
  Calculate: "Calculate",
  CalculationSetting: "CalculationSetting",
  Apply: "Apply",
  History: "History",
  CreateReport: "CreateReport",
  AutoSIDChange:"AutoSIDChange"
}

const ModuleIds = {
  Delivery: "Delivery",
  Parameter: "Parameter",
  Company: "Company",
  Department: "Department",
  Role: "Role",
  RoleResource: "RoleResource",
  RolePemisssion: "RolePemisssion",
  User: "User",
  ParameterDetail: "ParameterDetail",
  ParameterCode: "ParameterCode",
  Profile: "Profile",
  Individual: "Individual",
  Organization: "Organization",
  Physician: "Physician",
  FastReport: "Designer",
  Test: "Test",
  TestProfile: "TestProfile",
  Interface: "Interface",
  Instrument: "Instrument",
  TestConfig: "TestConfig",
  Connector: "Connector",
  TestRuleBasedConfig: "TestRuleBasedConfig",
  Report: "Report",
  ReportStatistic: "ReportStatistic",
  ReportStatisticIQC: "ReportStatisticIQC",
  AccessionNumber: "Accession",
  SampleDelivery: "SampleDelivery",
  ValidResult: "ValidResult",
  UpdateResult: "UpdateResult",
  WorkOrder: "WorkOrder",
  QCResult: "QCResult",
  Process: "SampleLocation",
  TestRequest: "TestRequest",
  TestResult: "TestResult",
  Patient: "Patient",
  CorrectionRequest: "CorrectionRequest",
  CancelationRequest: "CancelationRequest",
  WorkList: "WorkList",
  PatientVisit: "PatientVisit",
  ManualRequest: "ManualRequest",
  GeneralDashboard: "GeneralDashboard",
  SampleTracking: "SampleTracking",
  RuleManagement: "RuleManagement",
  TATSetting: "TATSetting",
  BarcodeManagement: "BarcodeManagement",
  Statistic: "Statistic",
  GeneralSetting: "GeneralSetting",
  PersonalSetting: "PersonalSetting",
  LoginHistory: "LoginHistory",
  AnalyticalProcess: "AnalyticalProcess",
  Insurance: "Insurance",
  DeliveryReceive: "DeliveryReceive",
  Logs: "Logs",
  DeliveryManagement: "DeliveryManagement",
  PatientGroup: "PatientGroup",
  TestSend: "TestSend",
  Correction: "Correction",
  Cancelation: "Cancelation",
  Receipt: "Receipt",
  PrintConfig: "PrintConfig",
  PathologyCode: "PathologyCode",
  ManagementQC: "ManagementQC",
  LotQC: "LotQC",
  LotTestQC: "LotTestQC",
  ExpressionIQC: "ExpressionsiQC",
  ResultIQC: "ResultiQC",
  ChangeSIDQC: "ChangeSIDQC",
  WestgardSigmaQC: "WestgardSigmaiQC",
  WestgardiQC: "WestgardiQC",
  CalculationiQC: "CalculationiQC",
  CausalActioniQC: "CausalActioniQC",
  ChartiQC: "ChartiQC",
  MachineTestiQC: "MachineTestiQC",
  ReportKPH: "Report:138",
  ReportHDKP: "Report:139",
}

const DefaultMenuDisplayInfo = {
  icon: "bx bx-border-inner",
  color: "#ffc107",
}

const PartyType = {
  1: "Individual",
  2: "Organization",
}

const PartyTypeText = {
  Individual: 1,
  Organization: 2,
}

const InternalList = {
  0: "External",
  1: "Internal",
}

const ProfileId = {
  INDIVIDUAL: 1,
  ORGANIZATION: 2,
  PATIENT: 3,
  COMPANY: 4,
  PHYSICIAN: 7,
  LABORATORYTEAM: 14,
  GROUP: 6,
  PARTNER: 5,
}

const TestProfileType = {
  1: "Profile",
  2: "Group",
  0: "Test",
}

const TestProfileTypeText = {
  TEST: 0,
  PROFILE: 1,
  GROUP: 2,
}

const TestProfileStatus = {
  ACTIVE: "Active",
  INACTIVE: "InActive",
}

const STATUS_REQUIRE = {
  YES: "Yes",
  NO: "No",
}

const WESTGARD_TYPE = {
  WESTGARD_SIGMA: 1,
  WESTGARD_RULES: 0,
}

const CAUSAL_ACTION = {
  HDKP: 2,
  NN: 1,
}

const DefaultValue = {
  COUNTRY: "VN",
  TEST_CATEGORY: "1HS",
  TEST_TYPE: "0",
  SAMPLE_TYPES_DEFAULT: "--0--",
  REASON_DEFAULT: "B35-B49",
  WEB_APP_TITLE: "IOLIS Solution",
}

const MappingStatus = {
  UNMAPED: "Unmaped",
  MAPPED: "Mapped",
}

const ReportTypes = {
  Template: "Template",
  Report: "Report",
}

const DeliveryState = {
  0: "Close",
  1: "Open",
  9: "Confirmed",
  99: "Reported",
}

const DeliveryStateText = {
  CLOSE: 0,
  OPEN: 1,
  CONFIRMED: 9,
  REPORTED: 99,
}

const SampleQuality = {
  Bad: "B",
  Good: "G",
}

const DeliveryType = {
  A: "Delivery",
  B: "Receipt",
  D: "Delivery & Receipt",
  C: "Correction",
  U: "Cancelation",
}

const UpdateType = {
  CONFIRM: "CONFIRM",
}

const TestResultStatus = {
  REV: "REV",
  UPL: "UPL",
  VAL: "VAL",
  DEL: "DEL",
  E00: "E00",
  E01: "E01",
  E02: "E02",
  E03: "E03",
  E04: "E04",
  E05: "E05",
  EXP: "EXP",
  E: "E*",
}

const ColorMapper = {
  REV: "text-warning",
  UPL: "text-primary",
  VAL: "text-success",
  DEL: "text-red",
  E00: "text-red",
  E01: "text-red",
  E02: "text-red",
  E03: "text-red",
  E04: "text-red",
  E05: "text-red",
  EXP: "text-primary",
}

const TestRequest_Test_Type = {
  TEST: 0,
  PROFILE: 1,
  PROFILE_GROUP: 2,
}

const TestResult_Submit_Type = {
  Confirm: "Confirm",
  UnConfirm: "UnConfirm",
  Valid: "Valid",
  InValid: "InValid",
  Delete: "Delete",
  ReRun: "ReRun",
  RunRule: "RunRule",
  WarningRule: "WarningRule",
  Sign: "Sign",
  Unsign: "Unsign",
}

const TestRequest_State = {
  Draft: 0,
  Submitted: 1,
  Collected: 3,
  Received: 5,
  Onhold: 61,
}

const PROFILE_TYPE = {
  PATIENT: 3,
  Physician: 7,
}

const SAMPLES_DELIVERY_MODAL_TYPE = {
  CREATE: 1,
  EDIT: 2,
  MASS_EDIT: 3,
}

const SAMPLES_DELIVERY_TYPE = {
  Delivery: "A",
  Receipt: "B",
  Correction: "C",
  DeliveryAndReceipt: "D",
  Cancelation: "U",
}

const DELIVERY_TYPE_NAME = {
  Delivery: "A",
  Receipt: "B",
  Correction: "C",
  DeliveryAndReceipt: "D",
  Cancelation: "U",
}

const BADGE_TYPE_TEXT = {
  Draft: "draft",
  Submitted: "submitted",
  Canceled: "canceled",
  Collected: "collected",
  Delivered: "delivered",
  Received: "received",
  OnHold: "onhold",
  InProcess: "inprocess",
  Completed: "completed",
  Validated: "validated",
  Released: "released",
  Approved: "approved",
  Transfrerred: "transfrerred",
  Confirmed: "confirmed",
  Sent: "sent",
}

const RESULT_STATE = {
  0: "Draft",
  1: "Submitted",
  2: "Canceled",
  3: "Collected",
  4: "Delivered",
  5: "Received",
  6: "OnHold",
  7: "InProcess",
  8: "Completed",
  61: "OnHold",
  90: "Validated",
  99: "Released",
  9: "Confirmed",
  95: "Signed",
}

const RESULT_STATE_Text = {
  Draft: 0,
  Submitted: 1,
  Canceled: 2,
  Collected: 3,
  Delivered: 4,
  Received: 5,
  OnHold: 6,
  RDS: 61,
  InProcess: 7,
  Completed: 8,
  Validated: 90,
  Released: 99,
  Confirmed: 9,
  Signed: 95,
  Releasing: 93,
}

const ActiveText = {
  active: "active",
}

const ReportAction = {
  Create: "Create",
  Print: "Print",
  Export: "Export",
  Preview: "Preview",
}

const ReportResource = {
  DeliveryId: "DeliveryId",
  TestResult: "ResultId",
  RequestId: "RequestId",
  PatientVisit: "PatientVisit",
  ResultIQC: "ResultIQC",
  ChartIQC: "ChartIQC",
}

const ExportExtension = {
  pdf: "pdf",
}
const MachineCodeDownload = {
  NOT_ALLOW: false,
  ALLOW: true,
}

const PartyProfile = {
  DEFAULT_PATIENT: 3,
}

const StatusNote = {
  "00": "Not Download",
  10: "Re - Download",
  "01": "Suspended",
  11: "Dowloaded",
}

const WorkOrderAction = {
  RELOAD: 0,
  SUSPEND: 1,
}

const ResultCommentType = {
  RESULT: 0,
  CATEGORY_GROUP: 1,
  CATEGORY: 2,
  NOTE: 3,
}

const ReportStatisticType = {
  DEPARTMENT: "DEPARTMENT",
  COMPANY: "COMPANY",
  "FROM-TO": "FROM-TO",
}

const ReportStatisticLabelName = {
  DEPARTMENT: "Department",
  COMPANY: "Company",
  "FROM-TO": "FROM-TO",
}

const RequestPatientCondition = {
  PatientId: "PatientId",
  PIN: "PIN",
  PatientName: "PatientName",
  Phone: "Phone",
}

const columnsRule = [
  "id",
  "selected",
  "testCode",
  "testName",
  "unit",
  "normalRange",
  // "expression",

  "lowerLimit",
  "upperLimit",
  "lowerAlert",
  "upperAlert",
  "enable",
]

const columnsRuleChild = [
  // "id",
  "selected",
  "testCode",
  "testName",
  "unit",
  "normalRange",
  "lowerLimit",
  "upperLimit",
  "lowerAlert",
  "upperAlert",
  "age",
  "ageType",
  "gender",
  "times",
  "enable",
]

const columnsRuleAll = [
  "id",
  "selected",
  "testCode",
  "testName",
  "unit",
  "normalRange",
  "lowerLimit",
  "upperLimit",
  "lowerAlert",
  "upperAlert",
  "age",
  "ageType",
  "gender",
  "protocol",
  "instrument",
  "enable",
]
const columnsRuleCode = {
  // "id": "id",
  selected: "selected",
  testCode: "testCode",
  testName: "testName",
  unit: "unit",
  normalRange: "normalRange",
  lowerLimit: "lowerLimit",
  upperLimit: "upperLimit",
  lowerAlert: "lowerAlert",
  upperAlert: "upperAlert",
  age: "age",
  ageType: "ageType",
  gender: "gender",
  times: "times",
  protocol: "protocol",
  instrument: "instrument",
  enable: "enable",
}
const columnsRuleResultTimeCode = {
  // "id": "id",
  selected: "selected",
  testCode: "testCode",
  subCategory: "subCategory",
  subCategoryName: "subCategoryName",
  timeCollection: "timeCollection",
  profileCode: "profileCode",
  serviceType: "serviceType",
  inPatient: "inPatient",
  resultTime: "resultTime",
  enable: "enable",
}

const columnsRuleAdditionalInfoCode = {
  // "id": "id",
  selected: "selected",
  sampleType: "sampleType",
  sampleTypeName: "sampleTypeName",
  subCategory: "subCategory",
  testCode: "testCode",
  subId: "subId",
  color: "color",
  labels: "labels",
  enable: "enable",
}
const columnsRuleCodeText = {
  id: "id",
  selected: "selected",
  testCode: "testCode",
  testName: "testName",
  unit: "unit",
  normalRange: "normalRange",
  lowerLimit: "lowerLimit",
  upperLimit: "upperLimit",
  lowerAlert: "lowerAlert",
  upperAlert: "upperAlert",
  age: "age",
  ageType: "ageType",
  gender: "gender",
  times: "times",
  protocol: "protocol",
  instrument: "instrument",
  enable: "enable",
}
const columnsRuleChildInstrument = [
  // "id",
  "selected",
  "testCode",
  "testName",
  "unit",
  "normalRange",
  "lowerLimit",
  "upperLimit",
  "lowerAlert",
  "upperAlert",
  "age",
  "ageType",
  "gender",
  "times",
  "enable",
]

const columnsRuleChildAlert = [
  "id",
  "selected",
  "testCode",
  "testName",
  "unit",
  "age",
  "gender",
  "ruleType",
  "value",
  "operatorType",
  "ruleAction",
  "color",
  "enable",
]

const AuditLogIdentifier = {
  LA_ResultTest: "LA:ResultTest:",
  LA_Test: "LA:Test:",
  LA_RequestPatient: "LA:RequestPatient:",
  LA_TestRequest: "LA:Request:",
  LA_TestProfile: "LA:TestProfile:",
  MS_Department: "MS:Department:",
  MS_Company: "MS:Company:",
  MS_Parameter: "MS:Parameter:",
  PT_Individual: "PT:Individual:",
  PT_Profile: "PT:Profile:",
  LA_Delivery: "LA:Delivery:",
  MS_Role: "MS:Role:",
}

const AuditLogAction = {
  Update: "Update",
  Create: "Create",
  Delete: "Delete",
  Updated: "Updated",
  Created: "Created",
  Deleted: "Deleted",
  RunRule: "RunRule",
  ReRun: "ReRun",
  InValidate: "InValidate",
  UnConfirm: "UnConfirm",
  Confirm: "Confirm",
  Validate: "Validate",
  Print: "Print",
  Preview: "Preview",
  Export: "Export",
  SendResult: "SendResult",
  Released: "Released",
  InstrumentResult: "InstrumentResult",
}

const GroupTestRequestOption = {
  UploadFile: "UploadFile",
  Company: "Company",
}

const RuleManagementType = {
  normal: "normal",
  patient: "patient",
  instrument: "instrument",
  resultTime: "resultTime",
  additionalInfo: "additionalInfo",
  instrumentAlert: "instrument alert",
  rulerBase: "ruler base",
}

const BadgeParameterType = [
  { key: "L", classname: "me-1", color: "primary" },
  { key: "H", classname: "me-1", color: "success" },
  { key: "I", classname: "me-1", color: "info" },
  { key: "M", classname: "me-1", color: "warning" },
  { key: "S", classname: "me-1", color: "danger" },
]

const BadgeReportCategoryType = [
  { key: "P", type: "Operation", classname: "me-1", color: "primary" },
  { key: "L", type: "Labels", classname: "me-1", color: "success" },
  { key: "Q", type: "QA", classname: "me-1", color: "info" },
  { key: "S", type: "Statistical", classname: "me-1", color: "warning" },
  { key: "O", type: "Other", classname: "me-1", color: "danger" },
]

const BadgeProfileType = [
  {
    key: "Patient",
    type: "Individual",
    classname: "badge-gender",
    color: "#cccecf",
    textColor: "#4d5057",
  },
  {
    key: "Bệnh nhân",
    type: "Individual",
    classname: "badge-gender",
    color: "#cccecf",
    textColor: "#4d5057",
  },
  {
    key: "Physician",
    type: "Individual",
    classname: "badge-gender",
    color: "#d4dbf9",
    textColor: "#556ee6",
  },
  {
    key: "Bác sĩ",
    type: "Individual",
    classname: "badge-gender",
    color: "#d4dbf9",
    textColor: "#556ee6",
  },
]

const BadgeDeliveryType = [
  { key: "A", type: "Delivery", classname: "badge-gender", color: "", textColor: "#000", border: "1px solid" },
  { key: "A", type: "Delivery", classname: "badge-gender", color: "", textColor: "#000", border: "1px solid" },
  { key: "B", type: "Receipt", classname: "badge-gender", color: "#50A5F1", textColor: "#fff", border: "1px solid #50A5F1" },
  { key: "B", type: "Receipt", classname: "badge-gender", color: "#50A5F1", textColor: "#fff", border: "1px solid #50A5F1" }
]

const RuleTestType = {
  NORMAL_RANGE: "NORMAL_RANGE",
  PATIENT_RANGE: "PATIENT_RANGE",
  INSTRUMENT_RANGE: "INSTRUMENT_RANGE",
  CUSTOM_WF: "CUSTOM_WF",
  ADDITIONAL: "ADDITIONAL",
  RESULT_TIME: "RESULT_TIME",
}

const NornalRuleType = {
  INSTRUMENT: 100,
  PATIENT: 200,
  NORMAL: 300,
}

const MS_Setting_Type = {
  MSDashboard: 1,
  DefaultDashboard: 2,
  IQC: 3,
}

const initDayField = {
  monday: {
    morning: {
      startTime: "00:00",
      endTime: "00:00",
    },
    afternoon: {
      startTime: "00:00",
      endTime: "00:00",
    },
  },
  tuesday: {
    morning: {
      startTime: "00:00",
      endTime: "00:00",
    },
    afternoon: {
      startTime: "00:00",
      endTime: "00:00",
    },
  },
  wednesday: {
    morning: {
      startTime: "00:00",
      endTime: "00:00",
    },
    afternoon: {
      startTime: "00:00",
      endTime: "00:00",
    },
  },
  thursday: {
    morning: {
      startTime: "00:00",
      endTime: "00:00",
    },
    afternoon: {
      startTime: "00:00",
      endTime: "00:00",
    },
  },
  friday: {
    morning: {
      startTime: "00:00",
      endTime: "00:00",
    },
    afternoon: {
      startTime: "00:00",
      endTime: "00:00",
    },
  },
  saturday: {
    morning: {
      startTime: "00:00",
      endTime: "00:00",
    },
    afternoon: {
      startTime: "00:00",
      endTime: "00:00",
    },
  },
  sunday: {
    morning: {
      startTime: "00:00",
      endTime: "00:00",
    },
    afternoon: {
      startTime: "00:00",
      endTime: "00:00",
    },
  },
}

// const columnsRule = [
//   "id",
//   "type",
//   "testName",
//   "unit",
//   "normalRange",
//   "expression",
//
//   "lowerLimit",
//   "upperLimit",
//   "lowerAlert",
//   "upperAlert",
// ]

const columnsRuleTAT = [
  "id",
  "selected",
  "testCode",
  "testName",
  "unit",
  "emergency",
  "receiverTime",
  "collectedTime",
  "tat",
  "condition",
  "exception",
  "enable",
]

const columnsRuleTATChild = [
  "id",
  "selected",
  "testCode",
  "testName",
  "unit",
  "emergency",
  "receiverTime",
  "collectedTime",
  "tat",
  "condition",
  "exception",
  "enable",
]

const columnsRuleInsuranceChild = [
  "serviceCode",
  "serviceName",
  "insuranceCode",
  "insuranceType",
  "priceService",
  "priceInsurance",
  "payPercent",
  "discount",
]

const columnsRuleInsuranceCode = {
  // "id": "id",
  // "selected": "selected",
  serviceCode: "serviceCode",
  serviceName: "serviceName",
  insuranceCode: "insuranceCode",
  insuranceType: "insuranceType",
  priceService: "priceService",
  priceInsurance: "priceInsurance",
  payPercent: "payPercent",
  discount: "discount",
  // "enable": "enable",
}

const columnsRuleInsuranceCodeText = {
  serviceCode: "serviceCode",
  serviceName: "serviceName",
  insuranceCode: "insuranceCode",
  insuranceType: "insuranceType",
  priceService: "priceService",
  priceInsurance: "priceInsurance",
  payPercent: "payPercent",
  discount: "discount",
}

const columnsRuleTATCode = {
  id: "id",
  selected: "selected",
  testCode: "testCode",
  testName: "testName",
  unit: "unit",
  emergency: "emergency",
  receiverTime: "receiverTime",
  collectedTime: "collectedTime",
  tat: "tat",
  condition: "condition",
  exception: "exception",
  enable: "enable",
}

const columnsRuleTATCodeText = {
  id: "id",
  selected: "selected",
  testCode: "testCode",
  testName: "testName",
  unit: "unit",
  emergency: "emergency",
  receiverTime: "receiverTime",
  collectedTime: "collectedTime",
  tat: "tat",
  condition: "condition",
  exception: "exception",
  enable: "enable",
}

const ResultStateBookmark = {
  Green: 0,
  Yellow: 1,
  Red: 2,
  Gray: 3,
  LightBlue: 4,
}

const ResultStateBookmarkColor = {
  Green: "#62B257",
  Yellow: "#FFC000",
  Red: "#FF0000",
  Gray: "#A6A6A6",
  LightBlue: "#74B8F7",
}

const DateAgeType = {
  "Year(Y)": "YYYY",
  "Month(M)": "MM",
  "Day(D)": "DD",
  "Năm(Y)": "YYYY",
  "Tháng(M)": "MM",
  "Ngày(D)": "DD",
}
const DateAgeText = {
  YYYY: "Year(Y)",
  MM: "Month(M)",
  DD: "Day(D)",
}

const GenderSelectType = {
  "Male(M)": "M",
  "Female(F)": "F",
  "Nam(M)": "M",
  "Nữ(F)": "F",
}
const GenderSelectText = {
  M: "Male(M)",
  F: "Female(F)",
}

const columnsDepartmentMapping = ["managedCode", "name", "departmentHis"]

const columnsDepartmentMappingCode = {
  managedCode: "managedCode",
  name: "name",
  departmentHis: "departmentHis",
}

const columnsPhysicianMapping = ["managedCode", "name", "physicianHis"]

const columnsPhysicianMappingCode = {
  managedCode: "managedCode",
  name: "name",
  physicianHis: "physicianHis",
}

const columnsInstrumentMapping = ["manageCode", "name", "instrumentHis"]

const columnsInstrumentMappingCode = {
  manageCode: "manageCode",
  name: "name",
  instrumentHis: "instrumentHis",
}

const columnConnectorHisCode = ["hisCode", "hisName", "lisCode", "lisName"]

const columnConnectorHisCodeCode = {
  hisCode: "hisCode",
  hisName: "hisName",
  lisCode: "lisCode",
  lisName: "lisName",
}

const columnsConnectorInsuranceMapping = [
  "lisTestCode",
  "lisTestName",
  "hisTestCode",
  "hisTestName",
]

const columnsConnectorInsuranceMappingName = [
  "lisTestCode",
  "lisTestName",
  "insuranceCode",
  "insuranceName",
]

const columnsConnectorInsuranceMappingCode = {
  lisTestCode: "lisTestCode",
  lisTestName: "lisTestName",
  hisTestCode: "hisTestCode",
  hisTestName: "hisTestName",
}

const ResultColorConstants = {
  Green: "3",
  Blue: "1",
  Pink: "4",
  Red: "2",
  Black: "0",
}

const ADDITIONAL_FIELD = {
  NotPrint: "NotPrint",
  NotStatistical: "NotStatistical",
}
const MappingIdDatabase = {
  GroupProfile: 6,
}

const DOCKER_IP = "host.docker.internal"

const CONNECTION_TYPE = {
  C: "COM (C)",
  T: "TCPClient (T)",
  S: "TCPServer (S)",
  F: "ShareFolders (F)",
  D: "DisconnectTCPClient (D)",
  " M": "TCPServerMultipleClient (M)",
  N: "None (N)",
  U: "Undefined (U)",
}

const Instrument_Translator = {
  Replace: "Replace",
  Numeric: "Numeric",
  Expression: "Expression",
}

const Instrument_Translator_Type = {
  PosNeg: "PosNeg",
  Result: "Result",
}

const PREFIX_NUMBER = {
  FullYear: "FullYear",
  Year: "Year",
}

const PrintConfigType = {
  GENERAL: 1,
  SAMPLE: 2,
  TEST: 3,
}

const PrintConfigTypeName = {
  1: "General",
  2: "Sample",
  3: "Test",
}

const PrintConfigTypeNumber = {
  1: "Onhold",
  2: "Danger",
  3: "Open",
}

const PATIENT_VISIST_TAB = {
  Basic: "BASIC",
  fpt: "FPT",
  None: "NONE",
}

const DELIVERY_PROCESS = {
  DELIVERY_WITH_RECEIVE: "DELIVERY_WITH_RECEIVE",
  DELIVERY_WITHOUT_RECEIVE: "DELIVERY_WITHOUT_RECEIVE",
}

const METHOD_DELIVERY = {
  KN: "Khí nén",
  TC: "Thủ công",
}

const USER_ROLE = {
  BSXN: "2047",
}

const RULE_SIGMA_VALUES = {
  OFF: "OFF",
  W: "W",
  R: "R",
}
const QCLotTestLogAction = {
  Update: "Update",
  Create: "Create",
  Delete: "Delete",
  Updated: "Updated",
  Created: "Created",
  Deleted: "Deleted",
  UpdateSigma: "UpdateSigma",
}

const QCResultStatus = {
  Accept: "ACC",
  Reject: "REJ",
  Warning: "WAR",
}

const QCResultState = {
  Delete: "DEL",
  Valid: "VAL",
  REF: "REF",
  DREW: "DREW",
  NOT_DRAW: "NOTDRAW",
}

const GPB_CATEGORY = [
  "GPB",
  "MBH",
  "CELL",
  "FNA",
  "TBH",
  "STCL",
  "HMMD",
  "THIN",
  "PAPS",
]
const CV_FORMULA = {
  CVNK: "CVNK",
  CVNGK: "CVNGK",
  SD: "SD",
}

const MEAN_FORMULA = {
  MEAN: "MEAN",
  MEAN_PEER: "MEAN_PEER",
  MEAN_NSX: "MEAN_NSX",
}

const BIAS = {
  NSX: "NSX",
  MEAN_PEER: "MEAN PEER",
  MEAN_EQC: "MEAN EQC",
}

export {
  BIAS,
  CV_FORMULA,
  GPB_CATEGORY,
  MEAN_FORMULA,
  USER_ROLE,
  PrintConfigTypeName,
  PATIENT_VISIST_TAB,
  PrintConfigTypeNumber,
  PrintConfigType,
  textFieldTypes,
  statusTypes,
  chartTypes,
  parameterCode,
  resourceType,
  permissionType,
  ModuleIds,
  DefaultMenuDisplayInfo,
  PartyType,
  PartyTypeText,
  InternalList,
  ProfileId,
  TestProfileType,
  TestProfileStatus,
  TestProfileTypeText,
  DefaultValue,
  MappingStatus,
  ReportTypes,
  DeliveryState,
  DeliveryStateText,
  UpdateType,
  TestResultStatus,
  ColorMapper,
  TestRequest_Test_Type,
  InlineEditType,
  TestResult_Submit_Type,
  PROFILE_TYPE,
  TestRequest_State,
  SampleQuality,
  DeliveryType,
  SAMPLES_DELIVERY_MODAL_TYPE,
  SAMPLES_DELIVERY_TYPE,
  BADGE_TYPE_TEXT,
  ActiveText,
  ReportAction,
  ReportResource,
  ExportExtension,
  RESULT_STATE,
  RESULT_STATE_Text,
  MachineCodeDownload,
  PartyProfile,
  StatusNote,
  WorkOrderAction,
  ResultCommentType,
  ReportStatisticType,
  ReportStatisticLabelName,
  RequestPatientCondition,
  BadgeParameterType,
  BadgeReportCategoryType,
  columnsRule,
  AuditLogIdentifier,
  AuditLogAction,
  GroupTestRequestOption,
  columnsRuleChild,
  columnsRuleChildInstrument,
  columnsRuleCode,
  columnsRuleCodeText,
  columnsRuleAll,
  RuleManagementType,
  columnsRuleChildAlert,
  STATUS_REQUIRE,
  columnsRuleResultTimeCode,
  RuleTestType,
  columnsRuleAdditionalInfoCode,
  MS_Setting_Type,
  initDayField,
  columnsRuleTAT,
  columnsRuleTATChild,
  columnsRuleTATCode,
  columnsRuleTATCodeText,
  ResultStateBookmark,
  ResultStateBookmarkColor,
  DateAgeType,
  DateAgeText,
  GenderSelectType,
  GenderSelectText,
  BadgeProfileType,
  BadgeDeliveryType,
  columnsRuleInsuranceChild,
  columnsRuleInsuranceCode,
  columnsRuleInsuranceCodeText,
  columnsDepartmentMapping,
  columnsDepartmentMappingCode,
  columnsInstrumentMapping,
  columnsInstrumentMappingCode,
  columnConnectorHisCode,
  columnConnectorHisCodeCode,
  columnsConnectorInsuranceMapping,
  columnsConnectorInsuranceMappingCode,
  columnsConnectorInsuranceMappingName,
  columnsPhysicianMapping,
  columnsPhysicianMappingCode,
  ResultColorConstants,
  ADDITIONAL_FIELD,
  MappingIdDatabase,
  DOCKER_IP,
  CONNECTION_TYPE,
  Instrument_Translator,
  PREFIX_NUMBER,
  Instrument_Translator_Type,
  NornalRuleType,
  DELIVERY_PROCESS,
  METHOD_DELIVERY,
  RULE_SIGMA_VALUES,
  QCLotTestLogAction,
  WESTGARD_TYPE,
  CAUSAL_ACTION,
  QCResultStatus,
  QCResultState,
}
