import { spreadSearchQuery } from "helpers/utilities"
import { get, put, post, del } from "../../api_helper2"

const BASE_API_URL = `${process.env.REACT_APP_LABORATORY_ENDPOINT}/qc/westgard-sigma`

const getWestgardSigmaQCList = payload => {
    let q = spreadSearchQuery(payload)
    return get(`${BASE_API_URL}?${q}`)
}

const getwestgardSigmaQCById = (id) => {
    return get(`${BASE_API_URL}/${id}`)
}

const deleteWestgardSigmaQCByIds = westgardSigmaQCs => {
    let ids = ""
    westgardSigmaQCs.forEach(_westgardSigmaQC => (ids += `id=${_westgardSigmaQC.id}&`))

    return del(`${BASE_API_URL}?${ids}`)
}

const updateWestgardSigmaQCById = req => {
    const { id } = req
    return put(`${BASE_API_URL}/${id}`, req)
}

const createWestgardSigmaQC = req => {
    return post(`${BASE_API_URL}`, req)
}

const exportWestgardSigmaQCs = (payload) => {
    let q = spreadSearchQuery(payload)
    return post(`${BASE_API_URL}/ExportQCWestgardSigma?${q}`, null, {
        responseType: 'arraybuffer',
        headers:
        {
            'Content-Disposition': "attachment; filename=template.xlsx",
            'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        },
    })
}

const getWestgardSigmaQCByListCode = (codes) => {
    return post(`${BASE_API_URL}/GetwestgardSigmaQCByListCode`, { codes: codes })
}

const getQCWestgardSigmaByLotTestId = (data) => {
    return post(`${BASE_API_URL}/GetQCWestgardSigmaByLotTestId`,data)
}

export {
    getwestgardSigmaQCById, updateWestgardSigmaQCById, deleteWestgardSigmaQCByIds, createWestgardSigmaQC,
    getWestgardSigmaQCList, getWestgardSigmaQCByListCode, exportWestgardSigmaQCs, getQCWestgardSigmaByLotTestId
}
