import { CustomButton } from "components/Common"
import { CAUSAL_ACTION } from "constant"
import { exportCausalActionQCs } from "helpers/app-backend/IQC/QC_Causal_Action_backend_helper"
import { withTranslation } from "react-i18next"

const ImportExportButton = ({ model, t }) => {

  const ExportCausalAction = async () => {
    const fileData = await exportCausalActionQCs(model)
    const blob = new Blob([fileData])
    let nameFile = ''
    if (model.category == CAUSAL_ACTION.NN) {
      nameFile = t('Causal')
    } else if (model.category == CAUSAL_ACTION.HDKP) {
      nameFile = t('Corrective Action')
    }
    saveAs(blob, `${nameFile}.xlsx`)
  }

  return (
    <div className="tooltip-custom">
      <CustomButton
        isEdit
        color="secondary"
        outline
        onClick={() => {
          ExportCausalAction()
        }}
      >
        <i className={"fas fa-download"}></i>
      </CustomButton>
      <span className="tooltiptext-custom-button-icon-left">
        {t("Export All")}
      </span>
    </div>
  )
}

export default withTranslation(["causalActionQCPage", "common"])(ImportExportButton)
