import {
    TitleAndTable,
    WarningModal
} from "components/Common"
import { ModuleIds } from "constant"

import PropTypes from "prop-types"
import React, { useEffect, useState } from "react"
import { withTranslation } from "react-i18next"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import moment from "moment"
import { getLottestListQC, getLottestListQCSuccess, updateCvPeer, updateLottestCalculationQC, updateMeanPeer } from "store/actions"
import * as XLSX from "xlsx"
import ExcelJS from 'exceljs';
import ConfigLotQCTable from "./CalculationIndexTable"
import HeaderButtons from "./HeaderButtons"
import OptionApplyCalculationQCModal from "./Modal/OptionApplyCalculationQCModal"

const RESOURCE = ModuleIds.CalculationiQC

let lottestCalculates = []

const CalculationIndex = ({
    paging,
    onGetLotTestQCList,
    onUpdateLottestCalculationQC,
    onGetLottestListQCSuccess,
    loadingLottest,
    updatedTime,
    lottests,
    cvPeer,
    meanPeer,
    t,
    onMeanPeerUpdate,
    onCVPeerUpdate
}) => {
    const [rows, setRows] = useState([])
    const [warningModal, setWarningModal] = useState(false)
    const [optionApplyModal, setOptionApplyModal] = useState(false)
    const [config, setConfig] = useState({ isValid: true, cvDate: '' })
    const [lot, setLot] = useState({})
    const [model, setModel] = useState({
        search: ""
    })
    const [isCalculation, setIsCalculation] = useState(false)
    const [noPoint, setNoPoint] = useState({})

    useEffect(() => {
        onGetLottestListQCSuccess([])
        return () => {
            onMeanPeerUpdate([])
            onCVPeerUpdate([])
        }
    }, [])

    useEffect(() => {
        if (cvPeer.length > 0 || meanPeer.length > 0) {
            setIsCalculation(true)
        }
    }, [cvPeer, meanPeer])

    const onGetLotQCList = payload => {
        onGetLotTestQCList(payload)
        setIsCalculation(false)
        onMeanPeerUpdate([])
        onCVPeerUpdate([])
    }

    const resetState = () => {
        setRows([])
    }

    const onSelectCheckbox = (row, isSelected) => {
        if (isSelected) {
            setRows((prev) => [...prev, row])
        } else {
            setRows((prev) => prev.filter(item => item.id != row.id))
        }
    }
    const featchLotQC = () => {
        onGetLotTestQCList({
            ...model,
            page: 1,
        })
    }

    const onRefreshHandler = () => {
        resetState()
        featchLotQC()
    }

    const onSearch = searchText => {
        let val = {
            search: searchText || "",
        }
        setModel({ ...model, ...val })
        onGetLotQCList({ ...model, ...val })
    }

    const onSubmitFilter = values => {
        onGetLotQCList({ page: 1, ...model, ...values })
    }

    const onLottestExport = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet1');

        // Define columns
        const columns = [
            { header: 'Mã', key: 'code' },
            { header: 'Tên', key: 'name' },
            { header: 'Đơn vị', key: 'unit' },
            { header: 'Mức', key: 'level' },
            { header: 'Số điểm được tính', key: 'numPoint' },
            { header: 'Mean cũ', key: 'oldMean' },
            { header: 'SD cũ', key: 'oldSD' },
            { header: 'Mean', key: 'mean' },
            { header: 'SD', key: 'sd' },
            { header: '%CV cộng dồn', key: 'cv' },
            { header: 'Đánh giá', key: 'evaluation' }
        ];

        worksheet.columns = columns;

        // Add data rows
        lottests.forEach(item => {
            item.lottests.forEach(test => {
                const twoSD = parseFloat(2 * test?.sd);
                const twoSDPI = parseFloat(2 * test?.sdpi);
                const diffTwoSD = parseFloat(-2 * test?.sd);
                const diffTwoSDPI = parseFloat(-2 * test?.sdpi);
                const SD = parseFloat(test?.sd);
                const SDPI = parseFloat(test?.sdpi);

                let evaluation = '';
                const isAchieved = diffTwoSD > diffTwoSDPI && twoSD < twoSDPI && SD < SDPI;
                if (test?.sd && test?.sdpi) {
                    evaluation = isAchieved ? t('Achieved') : t('Unachieved');
                }

                worksheet.addRow({
                    code: test.testCode,
                    name: item.testName,
                    unit: test.unit || "",
                    level: test.levels,
                    numPoint: test.numPoint,
                    oldMean: test?.oldMean || '',
                    oldSD: test?.oldSD || '',
                    mean: test?.mean || '',
                    sd: test?.sd || '',
                    cv: test?.cv || '',
                    evaluation: evaluation
                });
            });
        });

        // Style all cells
        worksheet.eachRow((row, rowNumber) => {
            row.eachCell((cell) => {
                // Set font
                cell.font = {
                    name: 'Times New Roman',
                    size: 11,
                    color: { argb: 'FF000000' }
                };
                // Set alignment
                cell.alignment = {
                    vertical: 'middle',
                    horizontal: 'left'
                };
                // Special styling for header row
                if (rowNumber === 1) {
                    cell.font.bold = true;
                }
            });
        });

        // Auto-fit columns
        worksheet.columns.forEach(column => {
            let maxLength = 0;
            column.eachCell({ includeEmpty: true }, (cell) => {
                const columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength > 65 ? 65 : maxLength + 3; //witdh max 65
        });

        // Generate buffer and create blob for browser download
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        });

        // Create download link and trigger download
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Tinh_toan_chi_so.xlsx';
        link.click();
        window.URL.revokeObjectURL(url);
    }

    const submitConfig = (values) => {
        setConfig(values)
    }

    const onSubmitOptionApply = (value) => {
        setOptionApplyModal(prev => !prev)

        let lottestData = []
        let lottests = []

        if (rows.length > 0) {
            lottests = rows
        } else {
            lottests = lottestCalculates
        }

        if (value.appliedRangeDate.length > 0) {
            value.appliedStartDate = moment(value.appliedRangeDate[0], 'DD-MM-YYYY').format("YYYY-MM-DD 00:00:00")
            value.appliedEndDate = moment(value.appliedRangeDate[1], 'DD-MM-YYYY').format("YYYY-MM-DD 23:59:59")
        }

        lottests.forEach(item => {
            let lottests = []
            lottests = item.lottests && item.lottests.length > 0 ? item.lottests : [item]
            lottestData =
                [...lottestData,
                ...lottests
                    .filter(x => (x.mean != null && x.mean != 0 && x.mean != '') || (x.cv != null && x.cv != 0 && x.cv != ''))
                    .map(x => ({
                        id: x.id,
                        lotId: item.lotId,
                        testCode: item.testCode,
                        levels: x.levels,
                        insId: x.insId,
                        method: x.method,
                        mean: value.type == 'All' || value.type == 'Mean' ? x.mean : null,
                        sd: value.type == 'All' || value.type == 'SD' ? x.sd : null,
                        cv: value.type == 'All' || value.type == 'SD' ? x.cv : null
                    }))]
        })
        const data = {
            ...value,
            ...model,
            lottestCalculates: lottestData
        }

        onUpdateLottestCalculationQC(data, () => {
            setIsCalculation(false)
        })
    }

    const onLottestChange = (lottests) => {
        lottestCalculates = lottests
    }

    const onCalculate = () => {
        if (config.isCV) {
            config.formulaCodeCV = config.cvCalculation
        }

        const firstPointTime = lot.firstPointTime ? moment(lot.firstPointTime, 'YYYY-MM-DD').format("DD-MM-YYYY 00:00:00") : new Date()

        if (config.lotQcs) {
            config.startCV = null
            config.endCV = null
        } else {
            config.lotIds = null
            config.startCV = moment(config.cvDate ? config.cvDate[0] : firstPointTime, 'DD-MM-YYYY').format("YYYY-MM-DD 00:00:00")
            config.endCV = moment(config.cvDate ? config.cvDate[1] : new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD 23:59:59")
        }

        if (config.isMean) {
            config.formulaCodeMean = config.meanCalculation
        }
        config.startMean = moment(config.meanDate ? config.meanDate[0] : firstPointTime, 'DD-MM-YYYY').format("YYYY-MM-DD 00:00:00")
        config.endMean = moment(config.meanDate ? config.meanDate[1] : new Date(), 'DD-MM-YYYY').format("YYYY-MM-DD 23:59:59")

        config.isCalculation = true

        onGetLotTestQCList({ ...model, ...config }, (data) => {
            setIsCalculation(true)
            if (data.noPointCV.length > 0 || data.noPointMean.length > 0 || data.noPointBias.length > 0) {
                setNoPoint(data)
                setWarningModal(true)
            } else {
                setNoPoint({})
            }

        })
    }

    return (
        <React.Fragment>
            <TitleAndTable
                table={() => (
                    <ConfigLotQCTable
                        lottests={lottests}
                        lot={lot}
                        config={config}
                        onSelect={onSelectCheckbox}
                        onSearch={onSearch}
                        onRefresh={onRefreshHandler}
                        paging={paging}
                        onSubmitFilter={onSubmitFilter}
                        loading={loadingLottest}
                        updatedTime={updatedTime}
                        submitConfig={submitConfig}
                        onLottestExport={onLottestExport}
                        onLottestChange={onLottestChange}
                        onLotChange={(data) => {
                            setLot(data[0])
                        }}
                        onChangeModel={val => {
                            setModel({ ...model, ...val })
                        }}
                    />
                )}
                resource={RESOURCE}
                buttons={() => (
                    <HeaderButtons
                        lottests={lottests}
                        resource={RESOURCE}
                        onApply={() => setOptionApplyModal(true)}
                        onCalculate={onCalculate}
                        isCalculation={isCalculation}
                    />
                )}
                external
                title={t("Quality Management")}
                subtitle={t("Calculation Index List")}
            />

            <WarningModal
                modal={warningModal}
                onToggle={() => setWarningModal(prev => !prev)}
                message={
                    <>
                        <div>
                            {noPoint?.noPointCV?.length > 0 ? `Mã XN ${[...new Set(noPoint.noPointCV.map(item => item.testCode))].join(',')} chưa có điểm để tính toán CV ` : ''}
                        </div>
                        <div>
                            {noPoint?.noPointMean?.length > 0 ? `Mã XN ${[...new Set(noPoint.noPointMean.map(item => item.testCode))].join(',')} chưa có điểm để tính toán Mean ` : ''}
                        </div>
                    </>
                }
            />

            <OptionApplyCalculationQCModal
                t={t}
                modal={optionApplyModal}
                toggle={() => { setOptionApplyModal(prev => !prev) }}
                onValidSubmit={val => {
                    onSubmitOptionApply(val)
                }}
            />
        </React.Fragment>
    )
}

CalculationIndex.propTypes = {
    lottests: PropTypes.array,
    onGetLotTestQCList: PropTypes.func,
    onUpdateLottestCalculationQC: PropTypes.func,
    loadingLottest: PropTypes.bool,
    t: PropTypes.any,
}

const mapStateToProps = ({ calculationQC }) => ({
    lottests: calculationQC.lottests,
    loadingLottest: calculationQC.loadingLottest,
    cvPeer: calculationQC.cvPeer,
    meanPeer: calculationQC.meanPeer,
})

const mapDispatchToProps = dispatch => ({
    onGetLotTestQCList: (payload, callback) => dispatch(getLottestListQC(payload, callback)),
    onUpdateLottestCalculationQC: (payload, callback) => dispatch(updateLottestCalculationQC(payload, callback)),
    onGetLottestListQCSuccess: (payload) => dispatch(getLottestListQCSuccess(payload)),
    onMeanPeerUpdate: (data) => (dispatch(updateMeanPeer(data))),
    onCVPeerUpdate: (data) => (dispatch(updateCvPeer(data))),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withRouter(withTranslation(["calculationQCPage", "message", "common"])(CalculationIndex)))