
// Profile

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
// import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

//  // Inner Authentication
import LockScreen from "../pages/AuthenticationInner/LockScreen"

// Parameters
import ParameterList from "../pages/SystemSettings/Parameters"
import ParameterDetail from "../pages/SystemSettings/Parameters/Detail"

// Company
import CompanyList from "pages/UserManagement/Companies"
import CompanyDetail from "pages/UserManagement/Companies/Detail"

// Department
import DepartmentList from "pages/UserManagement/Departments"
import DepartmentDetail from "pages/UserManagement/Departments/Detail"

// Roles
import RoleList from "pages/UserManagement/Roles"
import RoleDetail from "pages/UserManagement/Roles/Detail"

// Users
import UserList from "pages/UserManagement/Users"
import UserDetail from "pages/UserManagement/Users/Detail"

// User Profiles
import UserProfilesList from "pages/UserManagement/UserProfiles"

/**PARTY MANAGEMENT */
// Profiles
import ProfileList from "../pages/PartyManagement/Profiles"
import ProfileDetail from "../pages/PartyManagement/Profiles/Detail"

// Individuals
import IndividualList from "../pages/PartyManagement/Individuals"
import IndividualDetail from "../pages/PartyManagement/Individuals/Detail"
// Organizations
import OrganizationList from "../pages/PartyManagement/Organizations"
import OrganizationDetail from "../pages/PartyManagement/Organizations/Detail"
// Physicians
import PhysicianList from "../pages/PartyManagement/Physicians"
import PhysicianDetail from "../pages/PartyManagement/Physicians/Detail"

// FastReport
import ReportDesigner from "../pages/FastReport/Designer"
import ReportManagement from "../pages/FastReport/Report"
import ReportDetail from "../pages/FastReport/Report/Detail"
import ReportStatistic from "../pages/FastReport/ReportStatistic"
import ReportStatisticIQC from "../pages/FastReport/ReportStatisticIQC"

/**LABORATORY*/
// Profiles
import DeliveryManagement from "../pages/Laboratory/DeliveryManagement"
import CorrectionRequestDetailManagement from "../pages/Laboratory/DeliveryManagement/CorrectionRequest/Detail"
import SampleDeliveryDetailManagement from "../pages/Laboratory/DeliveryManagement/SampleDelivery/Detail"
import TestProfileList from "../pages/Laboratory/Profiles"
import TestProfileDetail from "../pages/Laboratory/Profiles/Detail"; // Test
import TestList from "../pages/Laboratory/Tests"
import TestDetail from "../pages/Laboratory/Tests/Detail"; //Pages
import PagesStarter from "../pages/Utility/PageStater"

import CancelationRequestDetailManagement from "../pages/Laboratory/DeliveryManagement/CancelationRequest/Detail"

// Test Request
import TestRequestList from "../pages/Laboratory/TestRequest"
import TestRequestDetail from "../pages/Laboratory/TestRequest/Detail"

// Test Result
import TestResultDetail from "../pages/Laboratory/TestResult/Detail"

//patient group
import PatientGroup from "../pages/Laboratory/PatientGroup"
import TestSend from "../pages/Laboratory/TestSend"

/***Connector**/
//his connector
// import MachineList from "../pages/Connector/Instruments"
import InstrumentManagement from "../pages/Connector/InstrumentManagement"
import MachineDetail from "../pages/Connector/Instruments/Detail"
import InterfaceList from "../pages/Connector/Interfaces"
import InterfaceDetail from "../pages/Connector/Interfaces/Detail"
import TestConfigList from "../pages/Laboratory/TestConfig"

/***Connector**/
//his connector
// import HisConnectorList from "../pages/Connector/HisConnectors"
// import HisConnectorDetail from "../pages/Connector/HisConnectors/Detail"
import HisConnectorList from "../pages/Connector/Connectors"

//accession number
import AccessionNumberList from "../pages/SystemSettings/AccessionNumber"
import AccessionNumberDetail from "../pages/SystemSettings/AccessionNumber/Detail"

// Individuals
import PatientList from "../pages/PartyManagement/Patients"
import PatientDetail from "../pages/PartyManagement/Patients/Detail"

//result
//update result
import ProcessList from "pages/Analysis-Process/Processes"
import UpdateResultList from "../pages/Analysis-Process/UpdateResults"
import ValidationResultList from "../pages/Analysis-Process/ValidationResult"
import WorkOrderList from "../pages/Analysis-Process/WorkOrders"
import QCResultList from "../pages/Analysis-Process/QCResult"
// import ProcessList from "pages/Analysis-Process/Processes"

import ManualRequest from "pages/Laboratory/ManualRequest"
import WorkList from "pages/Laboratory/WorkList"

import Pages403 from "pages/Utility/pages-403"
import Pages404 from "pages/Utility/pages-404"

import PatientVisitNew from "../pages/Laboratory/PatientVisitNew"
import MasterSetupDashboard from "../pages/UserManagement/MS-Dashboard"

import Insurance from "pages/Connector/Insurance"
import DefaultDashboardContainer from "pages/Dashboard/Default-Dashboard/Default-Dashboard.container"
import GeneralDashboard from "pages/Dashboard/General-Dashboard"
import BarcodeManagement from "pages/Laboratory/BarcodeManagement"
import RuleManagement from "pages/Laboratory/RuleManagement"
import SampleTracking from "pages/Laboratory/SampleTracking"
import GeneralSetting from "pages/SystemSettings/GeneralSetting"
import TATSettings from "pages/SystemSettings/TATSettings"
import AnalysisProcess from "../pages/Analysis-Process/AnalysisProcess"
import LoginHistoryList from "../pages/UserManagement/LoginHistory"

import QuickAddDeliveryReceipt from "../pages/Laboratory/QuickAddDeliveryReceipt"

// Notifications
import AuditLog from "pages/SystemSettings/AuditLog"
import NotificationsList from "../pages/Laboratory/Notifications"
import PrintConfig from "pages/Laboratory/PrintConfig"

import PathologyCodeConfig from "pages/SystemSettings/PathologyCodeConfig"
import ManagementQC from "pages/LaboratoryIQC/ManagementQC"
import ExpressionIQC from "pages/LaboratoryIQC/ExpressionIQC"
import ChangeSIDQC from "pages/LaboratoryIQC/ChangeSIDQC"
import ResultIQC from "pages/LaboratoryIQC/ResultIQC"
import WestgardSigmaIQC from "pages/LaboratoryIQC/WestgardSigmaQC"
import MachineTestQC from "pages/LaboratoryIQC/MachineTestQC"
import WestgardRulesIQC from "pages/LaboratoryIQC/WestgardRulesQC"
import CalculationQC from "pages/LaboratoryIQC/CalculationQC"
import CausalActionQC from "pages/LaboratoryIQC/CausalActionQC"
import ChartiQC from "pages/LaboratoryIQC/ChartiQC"

const userRoutes = [
  {
    path: "/GeneralDashboard",
    exct: true,
    component: () => <GeneralDashboard />,
    moduleId: "_GeneralDashboard",
  },
  // profile
  // { path: "/Profile", component: UserProfile, moduleId: "_Profile" },

  // parameters
  {
    path: "/Parameter/:id/edit",
    exact: true,
    component: () => <ParameterDetail isEdit />,
    moduleId: "_Parameter",
  },
  {
    path: "/Parameter/:id/view",
    exact: true,
    component: () => <ParameterDetail />,
    moduleId: "_Parameter",
  },
  { path: "/Parameter", component: ParameterList, moduleId: "_Parameter" },
  {
    path: "/Accession/:id/edit",
    exact: true,
    component: () => <AccessionNumberDetail isEdit />,
    moduleId: "_Accession",
  },
  {
    path: "/Accession/:id/view",
    exact: true,
    component: () => <AccessionNumberDetail />,
    moduleId: "_Accession",
  },
  {
    path: "/Accession",
    component: AccessionNumberList,
    moduleId: "_Accession",
  },
  // company
  {
    path: "/Company/:id/edit",
    exact: true,
    component: () => <CompanyDetail isEdit />,
    moduleId: "_Company",
  },
  {
    path: "/Company/:id/view",
    exact: true,
    component: () => <CompanyDetail />,
    moduleId: "_Company",
  },
  { path: "/Company", component: CompanyList, moduleId: "_Company" },

  // Department
  {
    path: "/Department/:id/edit",
    exact: true,
    component: () => <DepartmentDetail isEdit />,
    moduleId: "_Department",
  },
  {
    path: "/Department/:id/view",
    exact: true,
    component: () => <DepartmentDetail />,
    moduleId: "_Department",
  },
  {
    path: "/Department",
    component: DepartmentList,
    moduleId: "_Department",
  },

  // Role
  {
    path: "/Role/:id/edit",
    exact: true,
    component: () => <RoleDetail isEdit />,
    moduleId: "_Role",
  },
  {
    path: "/Role/:id/view",
    exact: true,
    component: () => <RoleDetail />,
    moduleId: "_Role",
  },
  { path: "/Role", component: RoleList, moduleId: "_Role" },

  // User
  {
    path: "/User/:id/edit",
    exact: true,
    component: () => <UserDetail isEdit />,
    moduleId: "_User",
  },
  {
    path: "/User/:id/view",
    exact: true,
    component: () => <UserDetail />,
    moduleId: "_User",
  },
  { path: "/User", component: UserList, moduleId: "_User" },
  { path: "/UserProfiles", component: UserProfilesList },
  /**PARTY MANAGEMENT */
  // profiles
  {
    path: "/Profile/:id/edit",
    exact: true,
    component: () => <ProfileDetail isEdit />,
    moduleId: "_Profile",
  },
  {
    path: "/Profile/:id/view",
    exact: true,
    component: () => <ProfileDetail />,
    moduleId: "_Profile",
  },
  { path: "/Profile", component: ProfileList, moduleId: "_Profile" },

  // individuals
  {
    path: "/Individual/:id/edit",
    exact: true,
    component: () => <IndividualDetail isEdit />,
    moduleId: "_Individual",
  },
  {
    path: "/Individual/:id/view",
    exact: true,
    component: () => <IndividualDetail />,
    moduleId: "_Individual",
  },
  { path: "/Individual", component: IndividualList, moduleId: "_Individual" },

  // organizations
  {
    path: "/Organization/:id/edit",
    exact: true,
    component: () => <OrganizationDetail isEdit />,
    moduleId: "_Organization",
  },
  {
    path: "/Organization/:id/view",
    exact: true,
    component: () => <OrganizationDetail />,
    moduleId: "_Organization",
  },
  {
    path: "/Organization",
    component: OrganizationList,
    moduleId: "_Organization",
  },

  // physicians
  {
    path: "/Physician/:id/edit",
    exact: true,
    component: () => <PhysicianDetail isEdit />,
    moduleId: "_Physician",
  },
  {
    path: "/Physician/:id/view",
    exact: true,
    component: () => <PhysicianDetail />,
    moduleId: "_Physician",
  },
  {
    path: "/Physician",
    component: PhysicianList,
    moduleId: "_Physician",
  },
  //Utility
  {
    path: "/Designer",
    component: ReportDesigner,
    moduleId: "_Designer",
  },

  {
    path: "/Reports/:id",
    exact: true,
    component: ReportDesigner,
    moduleId: "_Statistic",
  },
  {
    path: "/ReportStatistic",
    exact: true,
    component: ReportStatistic,
    moduleId: "_ReportStatistic",
  },

  {
    path: "/ReportStatisticIQC",
    exact: true,
    component: ReportStatisticIQC,
    moduleId: "_ReportStatisticIQC",
  },

  //report management
  {
    path: "/Report/:id/edit",
    exact: true,
    component: () => <ReportDetail isEdit />,
    moduleId: "_Report",
  },
  {
    path: "/Report/:id/view",
    exact: true,
    component: () => <ReportDetail />,
    moduleId: "_Report",
  },
  { path: "/Report", component: ReportManagement, moduleId: "_Report" },
  /**LABORATORY*/
  // profiles
  {
    path: "/TestProfile/:id/edit",
    exact: true,
    component: () => <TestProfileDetail isEdit />,
    moduleId: "_Test",
  },
  {
    path: "/TestProfile/:id/view",
    exact: true,
    component: () => <TestProfileDetail />,
    moduleId: "_Test",
  },
  {
    path: "/TestProfile",
    component: TestProfileList,
    moduleId: "_Test",
  },
  {
    path: "/Test/:id/edit",
    exact: true,
    component: () => <TestDetail isEdit />,
    moduleId: "_Test",
  },
  {
    path: "/Test/:id/view",
    exact: true,
    component: () => <TestDetail />,
    moduleId: "_Test",
  },
  {
    path: "/Test",
    component: TestList,
    moduleId: "_Test",
  },
  { path: "/TestConfig", component: TestConfigList, moduleId: "_TestConfig" },
  {
    path: "/DeliveryManagement/:id/edit",
    exact: true,
    component: () => <SampleDeliveryDetailManagement isEdit />,
    moduleId: "_DeliveryManagement",
  },
  {
    path: "/DeliveryManagement/:id/view",
    exact: true,
    component: () => <SampleDeliveryDetailManagement />,
    moduleId: "_DeliveryManagement",
  },
  {
    path: "/DeliveryManagement",
    component: DeliveryManagement,
    moduleId: "_DeliveryManagement",
  },

  {
    path: "/DeliveryManagementCorrection/:id/edit",
    exact: true,
    component: () => <CorrectionRequestDetailManagement isEdit />,
    moduleId: "_DeliveryManagement",
  },
  {
    path: "/DeliveryManagementCorrection/:id/view",
    exact: true,
    component: () => <CorrectionRequestDetailManagement />,
    moduleId: "_DeliveryManagement",
  },
  // {
  //   path: "/CorrectionRequest",
  //   component: CorrectionRequestList,
  //   moduleId: "_CorrectionRequest",
  // },

  {
    path: "/DeliveryManagementCancelation/:id/edit",
    exact: true,
    component: () => <CancelationRequestDetailManagement isEdit />,
    moduleId: "_DeliveryManagement",
  },
  {
    path: "/DeliveryManagementCancelation/:id/view",
    exact: true,
    component: () => <CancelationRequestDetailManagement />,
    moduleId: "_DeliveryManagement",
  },
  // {
  //   path: "/CancelationRequest",
  //   component: CancelationRequestList,
  //   moduleId: "_CancelationRequest",
  // },
  {
    path: "/ValidResult",
    component: ValidationResultList,
    moduleId: "_AnalyticalProcess",
  },
  {
    path: "/QCResult",
    component: QCResultList,
    moduleId: "_AnalyticalProcess",
  },
  {
    path: "/AnalyticalProcess",
    component: AnalysisProcess,
    moduleId: "_AnalyticalProcess",
  },
  {
    path: "/UpdateResult",
    component: UpdateResultList,
    moduleId: "_AnalyticalProcess",
  },
  { path: "/WorkOrder", component: WorkOrderList, moduleId: "_AnalyticalProcess" },
  {
    path: "/SampleLocation",
    component: ProcessList,
    moduleId: "_SampleLocation",
  },
  { path: "/WorkList", component: WorkList, moduleId: "_WorkList" },
  {
    path: "/ManualRequest",
    component: ManualRequest,
    moduleId: "_ManualRequest",
  },

  // Test request start
  {
    path: "/TestRequest/:id/edit",
    exact: true,
    component: () => <TestRequestDetail isEdit />,
    moduleId: "_TestRequest",
  },
  {
    path: "/TestRequest/:id/view",
    exact: true,
    component: () => <TestRequestDetail />,
    moduleId: "_TestRequest",
  },
  {
    path: "/TestRequest",
    component: TestRequestList,
    moduleId: "_TestRequest",
  },
  // Test request end
  {
    path: "/loginhistory",
    component: LoginHistoryList,
    moduleId: "_LoginHistory",
  },
  {
    path: "/Notifications",
    component: NotificationsList,
  },
  // Test result start
  {
    path: "/TestResult/:id",
    exact: true,
    component: () => <TestResultDetail />,
    moduleId: "_TestResult",
  },
  //TestSend
  {
    path: "/TestSend",
    exact: true,
    component: () => <TestSend />,
    moduleId: "_TestSend",
  },
  {
    path: "/PatientGroup",
    exact: true,
    component: () => <PatientGroup />,
    moduleId: "_PatientGroup",
  },//PatientGroup
  // {
  //   path: "/TestResult/:id/edit",
  //   exact: true,
  //   component: () => <TestResultDetailOld isEdit />,
  //   moduleId: "_TestResult",
  // },
  // {
  //   path: "/TestResult/:id/view",
  //   exact: true,
  //   component: () => <TestResultDetailOld />,
  //   moduleId: "_TestResult",
  // },
  {
    path: "/TestResult",
    // component: TestResultList,
    component: () => <TestResultDetail />,
    moduleId: "_TestResult",
  },

  {
    path: "/PatientVisit",
    component: PatientVisitNew,
    moduleId: "_PatientVisit",
  },

  {
    path: "/SampleTracking",
    component: SampleTracking,
    moduleId: "_SampleTracking",
  },
  {
    path: "/RuleManagement",
    component: RuleManagement,
    moduleId: "_RuleManagement",
  },

  {
    path: "/BarcodeManagement",
    component: BarcodeManagement,
    moduleId: "_BarcodeManagement",
  },

  // Test result end

  //Utility
  /**Connector**/
  //his connector
  // {
  //   path: "/Connector/:id/edit",
  //   exact: true,
  //   component: () => <HisConnectorDetail isEdit />,
  //   moduleId: "_Connector",
  // },
  // {
  //   path: "/Connector/:id/view",
  //   exact: true,
  //   component: () => <HisConnectorDetail />,
  //   moduleId: "_Connector",
  // },
  { path: "/Connector", component: HisConnectorList, moduleId: "_Connector" },
  { path: "/Page-starter", component: PagesStarter },

  /**Connector**/
  //interface
  {
    path: "/Interface/:id/edit",
    exact: true,
    component: () => <InterfaceDetail isEdit />,
    moduleId: "_Interface",
  },
  {
    path: "/Interface/:id/view",
    exact: true,
    component: () => <InterfaceDetail />,
    moduleId: "_Interface",
  },
  { path: "/Interface", component: InterfaceList, moduleId: "_Interface" },

  //instrument
  {
    path: "/Instrument/:id/edit",
    exact: true,
    component: () => <MachineDetail isEdit />,
    moduleId: "_Instrument",
  },
  {
    path: "/Instrument/:id/view",
    exact: true,
    component: () => <MachineDetail />,
    moduleId: "_Instrument",
  },
  { path: "/Instrument", component: InstrumentManagement, moduleId: "_Instrument" },
  // patient
  {
    path: "/Patient/:id/edit",
    exact: true,
    component: () => <PatientDetail isEdit />,
    moduleId: "_Patient",
  },
  {
    path: "/Patient/:id/view",
    exact: true,
    component: () => <PatientDetail />,
    moduleId: "_Patient",
  },
  { path: "/Patient", component: PatientList, moduleId: "_Patient" },
  {
    path: "/TATSetting",
    component: TATSettings,
    moduleId: "_TATSetting",
  },
  {
    path: "/Insurance",
    component: Insurance,
    moduleId: "_Insurance",
  },
  {
    path: "/MSDashboard",
    component: MasterSetupDashboard,
    // moduleId: "_Department",
  },
  {
    path: "/GeneralSetting",
    exact: true,
    component: () => <GeneralSetting isEdit />,
    moduleId: "_GeneralSetting",
  },
  {
    path: "/DefaultDashboard",
    component: DefaultDashboardContainer,
    // moduleId: "_Department",
  },
  {
    path: "/DeliveryReceive",
    component: QuickAddDeliveryReceipt,
    moduleId: "_DeliveryManagement",
  },
  {
    path: "/PrintConfig",
    component: PrintConfig,
    moduleId: "_PrintConfig",
  },
  {
    path: "/PathologyCode",
    component: PathologyCodeConfig,
    moduleId: "_PathologyCode",
  },
  {
    path: "/Logs",
    component: AuditLog,
    moduleId: "_Logs",
  },
  {
    path: "/ManagementQC",
    component: ManagementQC,
    moduleId: "_ManagementQC",
  },
  {
    path: "/ExpressionsiQC",
    component: ExpressionIQC,
    moduleId: "_ExpressionsiQC",
  },
  {
    path: "/ChangeSIDQC",
    component: ChangeSIDQC,
    moduleId: "_ChangeSIDQC",
  },
  {
    path: "/WestgardSigmaiQC",
    component: WestgardSigmaIQC,
    moduleId: "_WestgardSigmaiQC",
  },
  {
    path: "/MachineTestiQC",
    component: MachineTestQC,
    moduleId: "_MachineTestiQC",
  },
  {
    path: "/WestgardiQC",
    component: WestgardRulesIQC,
    moduleId: "_WestgardiQC",
  },
  {
    path: "/CalculationiQC",
    component: CalculationQC,
    moduleId: "_CalculationiQC",
  },
  {
    path: "/CausalActioniQC",
    component: CausalActionQC,
    moduleId: "_CausalActioniQC",
  },
  {
    path: "/ResultiQC",
    component: ResultIQC,
    moduleId: "_ResultiQC",
  },
  {
    path: "/ChartiQC",
    component: ChartiQC,
    moduleId: "_ChartiQC",
  },
  // this route should be at the end of all other routes
  {
    path: "/403",
    exact: true,
    component: Pages403,
  },
  {
    path: "/404",
    exact: true,
    component: Pages404,
  },
  { path: "/", exact: true, component: DefaultDashboardContainer },
  {
    path: "*",
    exact: true,
    component: () => { },
  },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  // { path: "/register", component: Register },

  // Authentication Inner
  // { path: "/pages-forgot-pwd", component: ForgetPwd1 },
  { path: "/lock-screen", component: LockScreen },
]

export { authRoutes, userRoutes }

