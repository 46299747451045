import PropTypes from "prop-types"
//i18n
import { CustomNav, CustomNavLink, PageContent } from "components/Common"
import { ModuleIds } from "constant"
import { useState, useEffect } from "react"
import { withTranslation } from "react-i18next"
import { Card, CardBody, TabContent, TabPane } from "reactstrap"
import TabCharts from "./TabCharts"
import Header from "./headerFilter"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom/cjs/react-router-dom"

const RESOURCE = ModuleIds.ChartiQC

const BodyMid = ({
  t,
  generalData,
  dateArr,
  dataInfo,
  allLotLevel,
  reportIndex,
  isPosNeg,
  selectedLots,
  flg,
  onChangeFilterChart,
  onChangeShowBothCharts,
  onChangeLevel,
}) => {
  const titleTag = t(`Chart iQC`)
  const [currentTab, setCurrentTab] = useState("1")
  const [showBothCharts, setShowBothCharts] = useState(false)
  const [levels, setLevels] = useState("default")
  const [showUnvalidatedPoint, setShowUnvalidatedPoint] = useState(false)
  const [timeOption, setTimeOption] = useState("ByTime")
  const [levelsChartOption, setLevelsChartOption] = useState("multi")
  const [filterChart, setFilterChart] = useState("validated")

  useEffect(() => {
    onChangeFilterChart(filterChart || "validated")
  }, [filterChart])

  useEffect(() => {
    onChangeShowBothCharts(showBothCharts)
  }, [showBothCharts])

  useEffect(() => {
    onChangeLevel(levels)
  }, [levels])

  const WarningIcon = () => (
    <div
      className="warning-icon"
      style={{
        color: "orange",
        fontSize: "20px",
        display: "inline-block",
        marginRight: "0.3rem",
        marginLeft: "1rem",
      }}
    >
      &#9679;
    </div>
  )

  const AcceptIcon = () => (
    <div
      className="accept-icon"
      style={{
        color: "blue",
        fontSize: "20px",
        display: "inline-block",
        marginRight: "0.3rem",
        marginLeft: "1rem",
      }}
    >
      &#9679;
    </div>
  )

  const RejectIcon = () => (
    <div
      className="accept-icon"
      style={{
        color: "red",
        fontSize: "20px",
        display: "inline-block",
        marginRight: "0.3rem",
        marginLeft: "1rem",
      }}
    >
      &#9679;
    </div>
  )

  return (
    <div className="mt-2">
      <Header
        t={t}
        setShowBothCharts={setShowBothCharts}
        setLevelsChartOption={setLevelsChartOption}
        onChangeLevel={val => setLevels(val)}
        allLotLevel={allLotLevel}
        generalData={generalData}
        showBothCharts={showBothCharts}
        showUnvalidatedPoint={showUnvalidatedPoint}
        setShowUnvalidatedPoint={setShowUnvalidatedPoint}
        setTimeOption={setTimeOption}
        reportIndex={reportIndex}
      />
      <Card>
        <CardBody className="p-0">
          <CustomNav
            onToggle={e => {
              setCurrentTab(e)
            }}
            defaultTab={"1"}
            tabs
            className="nav-tabs-custom p-0"
            tabContents={customActiveTab => (
              <TabContent
                activeTab={customActiveTab}
                className="text-muted p-0"
              >
                <TabPane tabId="1">
                  {currentTab == "1" && (
                    <TabCharts
                      onChangeFilterChart={val => setFilterChart(val)}
                      showBothCharts={showBothCharts}
                      levelsChartOption={levelsChartOption}
                      levels={levels}
                      generalData={generalData}
                      dataInfo={dataInfo}
                      showUnvalidatedPoint={showUnvalidatedPoint}
                      timeOption={timeOption}
                      flg={flg}
                      dateArr={dateArr}
                      selectedLots={selectedLots}
                      isPosNeg={isPosNeg}
                      reportIndex={reportIndex}
                    />
                  )}
                </TabPane>
                {/* <TabPane tabId="2">
                  {currentTab == "2" && <TabHistory />}
                </TabPane> */}
              </TabContent>
            )}
          >
            <CustomNavLink tabId="1">
              <span>{t("Chart")}</span>
            </CustomNavLink>
            {/* <CustomNavLink tabId="2">
              <span>{t("History")}</span>
            </CustomNavLink> */}
            {currentTab == "1" && (
              <div style={{ display: "flex", marginLeft: "auto" }}>
                <span>
                  <AcceptIcon /> {t("Accept")}
                </span>
                <span>
                  {!isPosNeg && (
                    <span>
                      <WarningIcon /> {t("Warning")}
                    </span>
                  )}
                </span>
                <span>
                  <RejectIcon /> {t("Reject")}
                </span>
              </div>
            )}
          </CustomNav>
        </CardBody>
      </Card>
    </div>
  )
}

BodyMid.propTypes = {
  t: PropTypes.any,
  datapoints: PropTypes.any,
}

const mapStateToProps = ({ qcDatapoints }) => ({
  datapoints: qcDatapoints.datapoints,
})
export default connect(
  mapStateToProps,
  null
)(withRouter(withTranslation(["chartiQCPage", "message", "common"])(BodyMid)))
